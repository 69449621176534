<template>
  <div>
    <b-card no-body header-tag="header" footer-tag="footer" border-variant="white" class="mb-1">
      <!-- 
        <b-card-header header-bg-variant="white" class="p-1">
          <strong>Sonuçlar</strong>
        </b-card-header>
       -->
      <div v-for="(algorithm_rule, algorithm_rule_ind) in p_dmpResults.list">
        <b-row>
          <b-col sm="12" lg="2">
            <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ algorithm_rule.AlgorithmId }}
          </b-col>
          <b-col sm="12" lg="7">
            {{ algorithm_rule.AlgorithmMsg }}
          </b-col>
          <b-col sm="12" lg="3">
            {{ DateFormat2(algorithm_rule.AlgorithmDate) }}
          </b-col>
        </b-row>
        <b-row v-if="f_isEligibleForSentenceAdding(algorithm_rule)">
          <b-col sm="12" lg="2">
            <img src="@/icon/2930471.png" class="img-rounded img-responsive" style="width: 2em;" />
          </b-col>
          <b-col sm="12" lg="7">
            <div v-if="algorithm_rule.OperationMsg">
              {{ algorithm_rule.OperationMsg }}
            </div>
            <div v-else-if="algorithm_rule.OperationHtml" v-html="algorithm_rule.OperationHtml"></div>
          </b-col>
          <b-col sm="12" lg="3">
            <b-button size="sm" variant="success" @click="f_addToWdmrSentenceList(algorithm_rule)">yazıya ekle</b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'DecisionMakingResult',
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    p_dmpResults: {
      type: Object,
      required: true
    },
  },
  components: {},
  created: function () {},
  mounted: function () {},
  data () {
    return {};
  },
  methods: {
    f_addToWdmrSentenceList: function (alg) {
      let div_el = document.getElementById('wdmr_sentence_template');
      if (div_el) {
        if (alg.OperationMsg) {
          div_el.innerHTML += '<div>' + alg.OperationMsg + '<div>';
        } else if (alg.OperationHtml) {
          div_el.innerHTML += '<div>' + alg.OperationHtml + '<div>';
        }
      }
    },
    f_isEligibleForSentenceAdding: function (alg) {
      let div_el = document.getElementById('wdmr_sentence_template');
      if (div_el) {
        if (alg.OperationId === 'wdmr_addsentence' && (alg.OperationMsg || alg.OperationHtml)) {
          return true;
        }
      }
      return false;
    },
    DateFormat2: function (date) {
      if (date) {
        let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY HH:mm");
        return x;
      } else {
        return "";
      }
    },
  }
};

</script>

<style type="text/css">


</style>

