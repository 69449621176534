<template>
  <div>
    <!-- <div style="display: none;"> -->
    <div id="context-menu-popup" class="class-context-menu-popup" style="position: fixed; z-index: 1000; display: none; background-color: #454c4c; box-shadow: 1px -1px 3px 0px black; overflow-y: auto; overflow-x: hidden; height: 200px; min-width: 200px; color: white;">
      <div class="understand-search-param-row" v-for="(found, found_ind) in d_smartSearchModel.found_list" @click="f_addFoundTextToCursorPoint(found_ind)">
        <div :style="f_calculateUnderstandRowStyle(found_ind)">
          {{ found.all_text }}
        </div>
      </div>
    </div>
    <b-card no-body>
      <b-card-header class="p-1" v-if="p_sentenceTemplateSettings.settings_mode">
        <b-row>
          <b-col cols="2">
            <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Wdmr Cümle Şablon Yöneticisi
            <span v-if="d_sentenceTemplate.search_index_list.length > 0 || d_sentenceTemplate.filter_group !== 'all'">{{ d_sentenceTemplate.search_index_list.length + ' / ' + d_sentenceTemplate.all_list.length }}</span>
            <span v-else>{{ d_sentenceTemplate.all_list.length }}</span>
          </b-col>
          <b-col cols="2">
            <b-form-input v-model="d_sentenceTemplate.search_text" placeholder="Filtrelemek için yazınız."></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-select v-model="d_sentenceTemplate.filter_group">
              <option value="all">tümü</option>
              <option value="include_same_parameter_multi">aynı parametreyi birden fazla içerenler</option>
            </b-form-select>
          </b-col>
          <b-col cols="3">
            <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;">
              <template v-slot:button-content>
                <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" /> işlemler
              </template>
              <b-dropdown-item @click="f_addNewSentenceTemplate()">
                <img src="@/icon/2504347.png" style="width: 2.5em;" /> yeni kalıp ekle
              </b-dropdown-item>
              <b-dropdown-item @click="f_saveSentenceTemplate()">
                <img src="@/icon/3082142.png" style="width: 2.5em;" /> kalıpları güncelle
              </b-dropdown-item>
              <b-dropdown-item @click="f_sentenceTemplateClearThePointAtTheEndOfThePhrases()">
                <img src="@/icon/2960590.png" style="width: 2.5em;" /> şablon cümlelerinin sonunda nokta işareti olanlardan nokta işaretini kaldır
              </b-dropdown-item>
              <b-dropdown-item @click="f_sentenceTemplateUniqControl()">
                <img src="@/icon/2960590.png" style="width: 2.5em;" /> tekillik kontrolü yap
              </b-dropdown-item>
              <b-dropdown-item @click="f_sentenceTemplateClearSameParameterIncluding()">
                <img src="@/icon/2960590.png" style="width: 2.5em;" /> aynı parametreyi birden fazla içeren kalıpları temizle
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col cols="3">
            <b-pagination v-if="d_sentenceTemplate.search_index_list.length > 0 || d_sentenceTemplate.filter_group !== 'all'" size="sm" style="margin-bottom: 0px;" aria-controls="d_sentenceTemplate_pagination" v-model="d_sentenceTemplate.pagination.current" :total-rows="d_sentenceTemplate.search_index_list.length" :per-page="d_sentenceTemplate.pagination.perpage"></b-pagination>
            <b-pagination v-else size="sm" style="margin-bottom: 0px;" aria-controls="d_sentenceTemplate_pagination" v-model="d_sentenceTemplate.pagination.current" :total-rows="d_sentenceTemplate.all_list.length" :per-page="d_sentenceTemplate.pagination.perpage"></b-pagination>
          </b-col>
        </b-row>
      </b-card-header>
      <b-row>
        <b-col cols="12">
          <template v-if="p_sentenceTemplateSettings.settings_mode">
            <div v-if="d_sentenceTemplate.search_index_list.length > 0 || d_sentenceTemplate.filter_group !== 'all'">
              <template v-for="(sen_temp_data_ind, sen_temp_data_ind_order) in d_sentenceTemplate.search_index_list">
                <b-row v-if="f_filterSentenceTemplate(sen_temp_data_ind, sen_temp_data_ind_order)" style="margin: 0px; border-bottom: solid 1px #94c3b4; padding: 3px;">
                  <b-col cols="1" style="padding-right: 1px;">
                    <b-dropdown variant="white" size="sm">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" /> <span>{{ sen_temp_data_ind + 1 }} ) </span>
                      </template>
                      <b-dropdown-item @click="f_openAddEditSentenceTemplateModal(sen_temp_data_ind)">
                        <img src="@/icon/2504347.png" style="width: 2.5em;" /> Şablonu düzenle
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_addNewSentenceWithCopy(sen_temp_data_ind)">
                        <img src="@/icon/3082142.png" style="width: 2.5em;" /> Şablonu kopyala
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_deleteSentenceTemplateRow(sen_temp_data_ind)">
                        <img src="@/icon/2960590.png" style="width: 2.5em;" /> Şablonu sil
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col cols="11" style="padding-left: 1px;">
                    <div style="min-height: 30px;">
                      <template v-for="(sen_temp, sen_temp_ind) in d_sentenceTemplate.all_list[sen_temp_data_ind].list">
                        <b-card no-body :style="f_calculateSentenceTemplatePhraseStyle(sen_temp)">
                          <b-row @click="f_openAddEditSentenceTemplateModal(sen_temp_data_ind, sen_temp_ind)">
                            <b-col cols="12">
                              <!-- <img src="@/icon/1421585.png" style="width: 2.5em; float: left;" /> -->
                              <!-- <b-button size="md" variant="white" :title="'Change Mode. Active: ' + sen_temp.type + (sen_temp.phrase === 'anatomy' ? ', anatomy' : '')" style="margin-right: 5px; padding: 0px; float: left;" @click="f_changeSentenceTemplatePhraseMode(sen_temp_data_ind, sen_temp_ind)">
                                            <img src="@/icon/1421585.png" style="width: 2.5em;" />
                                          </b-button> -->
                              <template v-if="sen_temp.type === 'param'">
                                <div v-if="sen_temp.phrase === 'anatomy'" style="float: left;">
                                  <img src="@/icon/W3D_humanbody.png" style="width: 1.5em;" />
                                </div>
                                <div style="float: left;">
                                  <!-- {{ sen_temp.phrase }} -->
                                  <template v-if="sen_temp.phrase">
                                    <template v-if="sen_temp.param_data && sen_temp.param_data.label">
                                      {{ sen_temp.param_data.label }}
                                    </template>
                                    <template v-else>
                                      {{ sen_temp.phrase }}
                                    </template>
                                  </template>
                                  <template v-else>
                                    Click for parameter selection
                                  </template>
                                  <!-- <b-form-input v-model="sen_temp.phrase"></b-form-input> -->
                                </div>
                              </template>
                              <template v-else-if="sen_temp.type === 'not_param'">
                                <div style="float: left;">
                                  <template v-if="sen_temp.phrase">
                                    {{ sen_temp.phrase }}
                                  </template>
                                  <template v-else>
                                    Click for parameter selection
                                  </template>
                                  <!-- <b-form-input v-model="sen_temp.phrase" placeholder="Please write your phrase."></b-form-input> -->
                                </div>
                              </template>
                            </b-col>
                          </b-row>
                        </b-card>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </div>
            <div v-else>
              <template v-for="(sen_temp_data, sen_temp_data_ind) in d_sentenceTemplate.all_list">
                <b-row v-if="f_filterSentenceTemplate(sen_temp_data_ind, sen_temp_data_ind)" style="margin: 0px; border-bottom: solid 1px #94c3b4; padding: 3px;">
                  <b-col cols="1" style="padding-right: 1px;">
                    <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" /> <span>{{ sen_temp_data_ind + 1 }} ) </span>
                      </template>
                      <b-dropdown-item @click="f_openAddEditSentenceTemplateModal(sen_temp_data_ind)">
                        <img src="@/icon/2504347.png" style="width: 2.5em;" /> Şablonu düzenle
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_addNewSentenceWithCopy(sen_temp_data_ind)">
                        <img src="@/icon/3082142.png" style="width: 2.5em;" /> Şablonu kopyala
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_deleteSentenceTemplateRow(sen_temp_data_ind)">
                        <img src="@/icon/2960590.png" style="width: 2.5em;" /> Şablonu sil
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col cols="11" style="padding-left: 1px;">
                    <div style="min-height: 30px;">
                      <template v-for="(sen_temp, sen_temp_ind) in sen_temp_data.list">
                        <b-card no-body :style="f_calculateSentenceTemplatePhraseStyle(sen_temp)">
                          <b-row @click="f_openAddEditSentenceTemplateModal(sen_temp_data_ind, sen_temp_ind)">
                            <b-col cols="12">
                              <!-- <img src="@/icon/1421585.png" style="width: 2.5em; float: left;" /> -->
                              <!-- <b-button size="md" variant="white" :title="'Change Mode. Active: ' + sen_temp.type + (sen_temp.phrase === 'anatomy' ? ', anatomy' : '')" style="margin-right: 5px; padding: 0px; float: left;" @click="f_changeSentenceTemplatePhraseMode(sen_temp_data_ind, sen_temp_ind)">
                                            <img src="@/icon/1421585.png" style="width: 2.5em;" />
                                          </b-button> -->
                              <template v-if="sen_temp.type === 'param'">
                                <div v-if="sen_temp.phrase === 'anatomy'" style="float: left;">
                                  <img src="@/icon/W3D_humanbody.png" style="width: 1.5em;" />
                                </div>
                                <div style="float: left;">
                                  <!-- {{ sen_temp.phrase }} -->
                                  <template v-if="sen_temp.phrase">
                                    <template v-if="sen_temp.param_data && sen_temp.param_data.label">
                                      {{ sen_temp.param_data.label }}
                                    </template>
                                    <template v-else>
                                      {{ sen_temp.phrase }}
                                    </template>
                                  </template>
                                  <template v-else>
                                    Click for parameter selection
                                  </template>
                                  <!-- <b-form-input v-model="sen_temp.phrase"></b-form-input> -->
                                </div>
                              </template>
                              <template v-else-if="sen_temp.type === 'not_param'">
                                <div style="float: left;">
                                  <template v-if="sen_temp.phrase">
                                    {{ sen_temp.phrase }}
                                  </template>
                                  <template v-else>
                                    Click for parameter selection
                                  </template>
                                  <!-- <b-form-input v-model="sen_temp.phrase" placeholder="Please write your phrase."></b-form-input> -->
                                </div>
                              </template>
                            </b-col>
                          </b-row>
                        </b-card>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </div>
          </template>
          <template v-else>
            <!-- understand -->
            <template v-if="p_sentenceTemplateSettings.html_view">
              <b-card no-body>
                <b-card-header class="p-1">
                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Rapor Text Html
                </b-card-header>
                <b-row>
                  <b-col cols="12">
                    {{ data.sentence_template }}
                  </b-col>
                </b-row>
              </b-card>
            </template>
            <template v-else>
              <b-card no-body>
                <b-card-header class="p-1">
                  <b-row>
                    <b-col cols="5">
                      <template v-if="p_gender === 'male'">
                        <img src="@/icon/116775.png" class="img-rounded img-responsive" style="width: 2em;" />
                      </template>
                      <template v-else-if="p_gender === 'female'">
                        <img src="@/icon/116774.png" class="img-rounded img-responsive" style="width: 2em;" />
                      </template>
                      <template v-else>
                        <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" />
                      </template>
                      Rapor Alanı
                      <template v-if="d_smartSearchModel.list.length === 0">
                        <small>Akıllı Model Tanımlı Değil</small>
                      </template>
                      <b-button size="md" variant="white" title="Akıllı model listesi" style="margin-right: 5px; padding: 0px;" @click="f_openSmartSearchModelListModal()">
                        <img src="@/icon/2884572.png" style="width: 2.5em;" />
                        <template v-if="d_smartSearchModel.selected_index !== ''">
                          {{ d_smartSearchModel.list[d_smartSearchModel.selected_index].name }}
                        </template>
                      </b-button>
                    </b-col>
                    <b-col cols="3">
                      <!-- {{ d_activeKeyDownText }} -->
                      <strong title="Malesef raporu parametreye dönüştürülme yüzdeniz çok düşük (< 50%)" v-if="d_analyzeSentenceList.sentence_accuracy < 50" style="color: red;">% {{ d_analyzeSentenceList.sentence_accuracy }}</strong>
                      <strong title="Raporu parametreye dönüştürebilme gücünüz orta düzeyde olduğu görüldü. (%50 - %75)" v-if="d_analyzeSentenceList.sentence_accuracy >= 50 && d_analyzeSentenceList.sentence_accuracy < 75" style="color: skyblue;">% {{ d_analyzeSentenceList.sentence_accuracy }}</strong>
                      <strong title="Raporun parametreye dönüştürülme gücü gayet güzel görünüyor. (%75 - %100)" v-if="d_analyzeSentenceList.sentence_accuracy >= 75 && d_analyzeSentenceList.sentence_accuracy < 100" style="color: lightgreen;">% {{ d_analyzeSentenceList.sentence_accuracy }}</strong>
                      <strong title="Muhteşemsiniz !!! Rapor % 100 olarak parametreye dönüştürülebildi. (%100)" v-if="d_analyzeSentenceList.sentence_accuracy === 100" style="color: forestgreen;">% {{ d_analyzeSentenceList.sentence_accuracy }}</strong>
                      <!-- 
                        <b-button v-if="d_analyzeSentenceList.analyzing" size="md" variant="white" title="Analiz devam ediyor." style="margin-right: 5px; padding: 0px;">
                          <img src="@/icon/sandwatch.gif" style="width: 2.5em;" /> <span style="color: red;">analiz devam ediyor...</span>
                        </b-button>
                       -->
                    </b-col>
                    <b-col cols="3" style="text-align: right;">
                      <b-button size="md" variant="white" title="Yazıyı büyüt" style="margin-right: 5px; padding: 0px;" @click="f_changeEditorFontSize('up')">
                        <img src="@/icon/2949531.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button size="md" variant="white" title="Yazıyı küçült" style="margin-right: 5px; padding: 0px;" @click="f_changeEditorFontSize('down')">
                        <img src="@/icon/2949536.png" style="width: 2.5em;" />
                      </b-button>
                    </b-col>
                    <b-col cols="1" style="text-align: right;"></b-col>
                  </b-row>
                </b-card-header>
                <template v-if="p_sentenceTemplateSettings.report_view_mode === 'text'">
                  <b-row>
                    <b-col cols="1" style="padding-right: 0px; padding-top: 3px; padding-bottom: 0px;"></b-col>
                    <b-col cols="10" style="padding-left: 3px; padding-top: 0px; padding-bottom: 0px;">
                      <div id="toolBar1" style="border: solid 1px #20a2ce;">
                        <img class="intLink" title="Clean" @click="f_clearHtmlTextDiv()" src="data:image/gif;base64,R0lGODlhFgAWAIQbAD04KTRLYzFRjlldZl9vj1dusY14WYODhpWIbbSVFY6O7IOXw5qbms+wUbCztca0ccS4kdDQjdTLtMrL1O3YitHa7OPcsd/f4PfvrvDv8Pv5xv///////////////////yH5BAEKAB8ALAAAAAAWABYAAAV84CeOZGmeaKqubMteyzK547QoBcFWTm/jgsHq4rhMLoxFIehQQSAWR+Z4IAyaJ0kEgtFoLIzLwRE4oCQWrxoTOTAIhMCZ0tVgMBQKZHAYyFEWEV14eQ8IflhnEHmFDQkAiSkQCI2PDC4QBg+OAJc0ewadNCOgo6anqKkoIQA7" />
                        <img class="intLink" title="Print" @click="printDoc('wdmr_sentence_template');" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9oEBxcZFmGboiwAAAAIdEVYdENvbW1lbnQA9syWvwAAAuFJREFUOMvtlUtsjFEUx//n3nn0YdpBh1abRpt4LFqtqkc3jRKkNEIsiIRIBBEhJJpKlIVo4m1RRMKKjQiRMJRUqUdKPT71qpIpiRKPaqdF55tv5vvusZjQTjOlseUkd3Xu/3dPzusC/22wtu2wRn+jG5So/OCDh8ycMJDflehMlkJkVK7KUYN+ufzA/RttH76zaVocDptRxzQtNi3mRWuPc+6cKtlXZ/sddP2uu9uXlmYXZ6Qm8v4Tz8lhF1H+zDQXt7S8oLMXtbF4e8QaFHjj3kbP2MzkktHpiTjp9VH6iHiA+whtAsX5brpwueMGdONdf/2A4M7ukDs1JW662+XkqTkeUoqjKtOjm2h53YFL15pSJ04Zc94wdtibr26fXlC2mzRvBccEbz2kiRFD414tKMlEZbVGT33+qCoHgha81SWYsew0r1uzfNylmtpx80pngQQ91LwVk2JGvGnfvZG6YcYRAT16GFtW5kKKfo1EQLtfh5Q2etT0BIWF+aitq4fDbk+ImYo1OxvGF03waFJQvBCkvDffRyEtxQiFFYgAZTHS0zwAGD7fG5TNnYNTp8/FzvGwJOfmgG7GOx0SAKKgQgDMgKBI0NJGMEImpGDk5+WACEwEd0ywblhGUZ4Hw5OdUekRBLT7DTgdEgxACsIznx8zpmWh7k4rkpJcuHDxCul6MDsmmBXDlWCH2+XozSgBnzsNCEE4euYV4pwCpsWYPW0UHDYBKSWu1NYjENDReqtKjwn2+zvtTc1vMSTB/mvev/WEYSlASsLimcOhOBJxw+N3aP/SjefNL5GePZmpu4kG7OPr1+tOfPyUu3BecWYKcwQcDFmwFKAUo90fhKDInBCAmvqnyMgqUEagQwCoHBDc1rjv9pIlD8IbVkz6qYViIBQGTJPx4k0XpIgEZoRN1Da0cij4VfR0ta3WvBXH/rjdCufv6R2zPgPH/e4pxSBCpeatqPrjNiso203/5s/zA171Mv8+w1LOAAAAAElFTkSuQmCC">
                        <img class="intLink" title="Undo" @click="formatDoc('wdmr_sentence_template', 'undo');" src="data:image/gif;base64,R0lGODlhFgAWAOMKADljwliE33mOrpGjuYKl8aezxqPD+7/I19DV3NHa7P///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARR8MlJq7046807TkaYeJJBnES4EeUJvIGapWYAC0CsocQ7SDlWJkAkCA6ToMYWIARGQF3mRQVIEjkkSVLIbSfEwhdRIH4fh/DZMICe3/C4nBQBADs=" />
                        <img class="intLink" title="Redo" @click="formatDoc('wdmr_sentence_template', 'redo');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAB1ChDljwl9vj1iE34Kl8aPD+7/I1////yH5BAEKAAcALAAAAAAWABYAAANKeLrc/jDKSesyphi7SiEgsVXZEATDICqBVJjpqWZt9NaEDNbQK1wCQsxlYnxMAImhyDoFAElJasRRvAZVRqqQXUy7Cgx4TC6bswkAOw==" />
                        <img class="intLink" title="Remove formatting" @click="formatDoc('wdmr_sentence_template', 'removeFormat')" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB9oECQMCKPI8CIIAAAAIdEVYdENvbW1lbnQA9syWvwAAAuhJREFUOMtjYBgFxAB501ZWBvVaL2nHnlmk6mXCJbF69zU+Hz/9fB5O1lx+bg45qhl8/fYr5it3XrP/YWTUvvvk3VeqGXz70TvbJy8+Wv39+2/Hz19/mGwjZzuTYjALuoBv9jImaXHeyD3H7kU8fPj2ICML8z92dlbtMzdeiG3fco7J08foH1kurkm3E9iw54YvKwuTuom+LPt/BgbWf3//sf37/1/c02cCG1lB8f//f95DZx74MTMzshhoSm6szrQ/a6Ir/Z2RkfEjBxuLYFpDiDi6Af///2ckaHBp7+7wmavP5n76+P2ClrLIYl8H9W36auJCbCxM4szMTJac7Kza////R3H1w2cfWAgafPbqs5g7D95++/P1B4+ECK8tAwMDw/1H7159+/7r7ZcvPz4fOHbzEwMDwx8GBgaGnNatfHZx8zqrJ+4VJBh5CQEGOySEua/v3n7hXmqI8WUGBgYGL3vVG7fuPK3i5GD9/fja7ZsMDAzMG/Ze52mZeSj4yu1XEq/ff7W5dvfVAS1lsXc4Db7z8C3r8p7Qjf///2dnZGxlqJuyr3rPqQd/Hhyu7oSpYWScylDQsd3kzvnH738wMDzj5GBN1VIWW4c3KDon7VOvm7S3paB9u5qsU5/x5KUnlY+eexQbkLNsErK61+++VnAJcfkyMTIwffj0QwZbJDKjcETs1Y8evyd48toz8y/ffzv//vPP4veffxpX77z6l5JewHPu8MqTDAwMDLzyrjb/mZm0JcT5Lj+89+Ybm6zz95oMh7s4XbygN3Sluq4Mj5K8iKMgP4f0////fv77//8nLy+7MCcXmyYDAwODS9jM9tcvPypd35pne3ljdjvj26+H2dhYpuENikgfvQeXNmSl3tqepxXsqhXPyc666s+fv1fMdKR3TK72zpix8nTc7bdfhfkEeVbC9KhbK/9iYWHiErbu6MWbY/7//8/4//9/pgOnH6jGVazvFDRtq2VgiBIZrUTIBgCk+ivHvuEKwAAAAABJRU5ErkJggg==">
                        <img class="intLink" title="Bold" @click="formatDoc('wdmr_sentence_template', 'bold');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAInhI+pa+H9mJy0LhdgtrxzDG5WGFVk6aXqyk6Y9kXvKKNuLbb6zgMFADs=" />
                        <img class="intLink" title="Italic" @click="formatDoc('wdmr_sentence_template', 'italic');" src="data:image/gif;base64,R0lGODlhFgAWAKEDAAAAAF9vj5WIbf///yH5BAEAAAMALAAAAAAWABYAAAIjnI+py+0Po5x0gXvruEKHrF2BB1YiCWgbMFIYpsbyTNd2UwAAOw==" />
                        <img class="intLink" title="Underline" @click="formatDoc('wdmr_sentence_template', 'underline');" src="data:image/gif;base64,R0lGODlhFgAWAKECAAAAAF9vj////////yH5BAEAAAIALAAAAAAWABYAAAIrlI+py+0Po5zUgAsEzvEeL4Ea15EiJJ5PSqJmuwKBEKgxVuXWtun+DwxCCgA7" />
                        <img class="intLink" title="Left align" @click="formatDoc('wdmr_sentence_template', 'justifyleft');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JMGELkGYxo+qzl4nKyXAAAOw==" />
                        <img class="intLink" title="Center align" @click="formatDoc('wdmr_sentence_template', 'justifycenter');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIfhI+py+0Po5y02ouz3jL4D4JOGI7kaZ5Bqn4sycVbAQA7" />
                        <img class="intLink" title="Right align" @click="formatDoc('wdmr_sentence_template', 'justifyright');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JQGDLkGYxouqzl43JyVgAAOw==" />
                        <img class="intLink" title="Numbered list" @click="formatDoc('wdmr_sentence_template', 'insertorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAADljwliE35GjuaezxtHa7P///////yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKSespwjoRFvggCBUBoTFBeq6QIAysQnRHaEOzyaZ07Lu9lUBnC0UGQU1K52s6n5oEADs=" />
                        <img class="intLink" title="Dotted list" @click="formatDoc('wdmr_sentence_template', 'insertunorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAAB1ChF9vj1iE33mOrqezxv///////yH5BAEAAAcALAAAAAAWABYAAAMyeLrc/jDKSesppNhGRlBAKIZRERBbqm6YtnbfMY7lud64UwiuKnigGQliQuWOyKQykgAAOw==" />
                        <img class="intLink" title="Quote" @click="formatDoc('wdmr_sentence_template', 'formatblock','blockquote');" src="data:image/gif;base64,R0lGODlhFgAWAIQXAC1NqjFRjkBgmT9nqUJnsk9xrFJ7u2R9qmKBt1iGzHmOrm6Sz4OXw3Odz4Cl2ZSnw6KxyqO306K63bG70bTB0rDI3bvI4P///////////////////////////////////yH5BAEKAB8ALAAAAAAWABYAAAVP4CeOZGmeaKqubEs2CekkErvEI1zZuOgYFlakECEZFi0GgTGKEBATFmJAVXweVOoKEQgABB9IQDCmrLpjETrQQlhHjINrTq/b7/i8fp8PAQA7" />
                        <img class="intLink" title="Delete indentation" @click="formatDoc('wdmr_sentence_template', 'outdent');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAAAAADljwliE35GjuaezxtDV3NHa7P///yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKCQG9F2i7u8agQgyK1z2EIBil+TWqEMxhMczsYVJ3e4ahk+sFnAgtxSQDqWw6n5cEADs=" />
                        <img class="intLink" title="Add indentation" @click="formatDoc('wdmr_sentence_template', 'indent');" src="data:image/gif;base64,R0lGODlhFgAWAOMIAAAAADljwl9vj1iE35GjuaezxtDV3NHa7P///////////////////////////////yH5BAEAAAgALAAAAAAWABYAAAQ7EMlJq704650B/x8gemMpgugwHJNZXodKsO5oqUOgo5KhBwWESyMQsCRDHu9VOyk5TM9zSpFSr9gsJwIAOw==" />
                        <!-- <img class="intLink" title="Hyperlink" @click="var sLnk=prompt('Write the URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){formatDoc('wdmr_sentence_template', 'createlink',sLnk)}" src="data:image/gif;base64,R0lGODlhFgAWAOMKAB1ChDRLY19vj3mOrpGjuaezxrCztb/I19Ha7Pv8/f///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARY8MlJq7046827/2BYIQVhHg9pEgVGIklyDEUBy/RlE4FQF4dCj2AQXAiJQDCWQCAEBwIioEMQBgSAFhDAGghGi9XgHAhMNoSZgJkJei33UESv2+/4vD4TAQA7" /> -->
                        <img class="intLink" title="Cut" @click="formatDoc('wdmr_sentence_template', 'cut');" src="data:image/gif;base64,R0lGODlhFgAWAIQSAB1ChBFNsRJTySJYwjljwkxwl19vj1dusYODhl6MnHmOrpqbmpGjuaezxrCztcDCxL/I18rL1P///////////////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAVu4CeOZGmeaKqubDs6TNnEbGNApNG0kbGMi5trwcA9GArXh+FAfBAw5UexUDAQESkRsfhJPwaH4YsEGAAJGisRGAQY7UCC9ZAXBB+74LGCRxIEHwAHdWooDgGJcwpxDisQBQRjIgkDCVlfmZqbmiEAOw==" />
                        <img class="intLink" title="Copy" @click="formatDoc('wdmr_sentence_template', 'copy');" src="data:image/gif;base64,R0lGODlhFgAWAIQcAB1ChBFNsTRLYyJYwjljwl9vj1iE31iGzF6MnHWX9HOdz5GjuYCl2YKl8ZOt4qezxqK63aK/9KPD+7DI3b/I17LM/MrL1MLY9NHa7OPs++bx/Pv8/f///////////////yH5BAEAAB8ALAAAAAAWABYAAAWG4CeOZGmeaKqubOum1SQ/kPVOW749BeVSus2CgrCxHptLBbOQxCSNCCaF1GUqwQbBd0JGJAyGJJiobE+LnCaDcXAaEoxhQACgNw0FQx9kP+wmaRgYFBQNeAoGihCAJQsCkJAKOhgXEw8BLQYciooHf5o7EA+kC40qBKkAAAGrpy+wsbKzIiEAOw==" />
                        <img class="intLink" title="Paste" @click="formatDoc('wdmr_sentence_template', 'paste');" src="data:image/gif;base64,R0lGODlhFgAWAIQUAD04KTRLY2tXQF9vj414WZWIbXmOrpqbmpGjudClFaezxsa0cb/I1+3YitHa7PrkIPHvbuPs+/fvrvv8/f///////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAWN4CeOZGmeaKqubGsusPvBSyFJjVDs6nJLB0khR4AkBCmfsCGBQAoCwjF5gwquVykSFbwZE+AwIBV0GhFog2EwIDchjwRiQo9E2Fx4XD5R+B0DDAEnBXBhBhN2DgwDAQFjJYVhCQYRfgoIDGiQJAWTCQMRiwwMfgicnVcAAAMOaK+bLAOrtLUyt7i5uiUhADs=" />
                        <img class="intLink" title="HTML yeniden düzenle" @click="f_htmlToOnlyDivMode('wdmr_sentence_template');" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAEw0lEQVR4Xu2b3X3bNhTFD2S/150g6ASO5AHiTJB0gEjWBEknqDNBnQls0wu0E0QewKQyQegJorxbQn+gSBqSKIq0QupKOHwkL8DL8+cFLr4UeIlSQInyhs6AQIT9BARCIMIUEOYOI4RAhCkgzB1GCIEIU0CYO4wQAhGmgDB3GCEEIkwBYe4wQghEmALC3GGEEIgwBYS5wwghEGEKCHOHEUIgwhQQ5g4jhECEKSDMHVERou+i9wBOG9ZoEn/ofmn4HS+uXhaQIPwB4OTFX1O5oBrG/e5NZfMWDaUBMe19u0woHgOx6OVB8RyIPCg+AvkGIAbwLm8ep6obD7vj9prL9W/yDch/cb9nMzno4OEToP5JpZlgqt5KgOIXEGM+x4OzywTIXXQOY746/6oIKASy2HrsHAqBrDbnFsof8bA72UWfQiBFqhuMMUv6lNahEMi6MNgRFAIpa5d2AIVANnUUBuN40OtuMvtVzwmkkpLmJu6fDSuZbmnkL5Dr6ATHeF1ZvyeM2+jkvQVSGUTLhn4BAfKpk5Z1rvw634AANnPqqH8rK7RqeB9/6I62KF9a1D8g2yrpzIdtW1VReQKpqyqB1FWsYXsCaVjgutX7BKSuNk3ZaztG6cwuodTHlXcQSFOyl9erryONI/OdQHajf+FbdRCubkva5wjRQXQBmIEgjctdMeZbPDj7lBkdHpDbB9sO/703QID7uN879wXIY7r95o0DqOiefXy/BqIG8Mp5Zu3s1tOi/cD2WZH98j33Vd4AeYz7PSsE0mbsGgY/40Ev2ceb30ukKd9NqIPQ7p86hTFfsuZF34U3MHCaxnkdSbZ0ZOw0x6lbrw5Cuy/LBZtB8QZI/qGpSHZTddE9QKm3ZXNFufiO3YLwDqgE9u3DVZK+TtXv2fS5DkILyY1Uf4HMIyLJXtb9jVasS0zVT3fjmg7CNzBKQ5mrpJlaAqevo9c4MpfZRri8H0j7sbjfy6eJCGRV/BGMGW/IaMqbjw2RRCAFvbB+zrIWxC3qsAtSTAL51ekpgdRXtNHp97aAJEfhnjCynXbSlxzjJEsMMh/Yh8wznGxgWNT8bEoxKzdZ6XsGgBkD6hxK/VkKZJ55ZRsc3HHJJp+AvZ46aRfI84yAmxIXZFnLDYkOQruka8+L+AckTU8ju7adbUBbM7PqjlNsShstCvk8gMzHGpmBCyQTu+RQjlPeGyCAURfo4BHG2D95Pl+U35t9BFRykGbhKiqTGcy3eQ7Rwat0bJLMBKTXCEp9xix5lp22HWGq/lo+lKNvo3dOeY+A1E84dlGCQHahesk7DxyIXXU7TmZc9+N6wmRpuuawFqj2g8J6Lw9ugWqfgeTZ4Eqi8XxwtInva3SkXtdhfRfJWV005iJd4FrK/HwCUrSpoC7Vpu33eaReV5vCNrtuJU3bE0jTCtesn0BqCta0OYE0rXDN+gmkpmBNm3sF5DacQOG3pjXdqn6vgNiVv9ms+snYrZR9YeFZ56rJ07iiBoYvlOigihGIMJwEQiDCFBDmDiOEQIQpIMwdRgiBCFNAmDuMEAIRpoAwdxghBCJMAWHuMEIIRJgCwtxhhBCIMAWEucMIIRBhCghzhxFCIMIUEOYOI4RAhCkgzB1GiDAg/wNX/HOSQnj0oAAAAABJRU5ErkJggg==" />
                        <select v-model="d_textOptions.formatblock" v-on:change="formatDocSelect('wdmr_sentence_template', 'wdmr_sentence_template', 'formatblock')" style="width: 75px;">
                          <option value="">format</option>
                          <option value="h1">Title 1 &lt;h1&gt;</option>
                          <option value="h2">Title 2 &lt;h2&gt;</option>
                          <option value="h3">Title 3 &lt;h3&gt;</option>
                          <option value="h4">Title 4 &lt;h4&gt;</option>
                          <option value="h5">Title 5 &lt;h5&gt;</option>
                          <option value="h6">Subtitle &lt;h6&gt;</option>
                          <!-- <option value="p">Paragraph &lt;p&gt;</option> -->
                          <!-- <option value="pre">Preformatted &lt;pre&gt;</option> -->
                        </select>
                        <select v-model="d_textOptions.fontname" v-on:change="formatDocSelect('wdmr_sentence_template', 'fontname')" style="width: 75px;">
                          <option value="">font</option>
                          <option value="Font">Font</option>
                          <option value="Arial">Arial</option>
                          <option value="Arial Black">Arial Black</option>
                          <option value="Courier New">Courier New</option>
                          <option value="Times New Roman">Times New Roman</option>
                          <option value="Tahoma">Tahoma</option>
                        </select>
                        <select v-model="d_textOptions.fontsize" v-on:change="formatDocSelect('wdmr_sentence_template', 'fontsize')" style="width: 75px;">
                          <option value="">- boyut -</option>
                          <option value="1">çok küçük</option>
                          <option value="2">biraz küçük</option>
                          <option value="3">Normal</option>
                          <option value="4">orta büyük</option>
                          <option value="5">büyük</option>
                          <option value="6">çok büyük</option>
                          <option value="7">maksimum büyüklükte</option>
                        </select>
                        <select v-model="d_textOptions.forecolor" v-on:change="formatDocSelect('wdmr_sentence_template', 'forecolor')" style="width: 75px;">
                          <option value="">- color -</option>
                          <option value="red">kırmızı</option>
                          <option value="blue">mavi</option>
                          <option value="green">yeşil</option>
                          <option value="black">siyah</option>
                        </select>
                        <select v-model="d_textOptions.backcolor" v-on:change="formatDocSelect('wdmr_sentence_template', 'backcolor')" style="width: 75px;">
                          <option value="">arka plan</option>
                          <option value="red">kırmızı</option>
                          <option value="blue">mavi</option>
                          <option value="green">yeşil</option>
                          <option value="black">siyah</option>
                        </select>
                      </div>
                    </b-col>
                    <b-col cols="1" style="padding-right: 0px; padding-top: 3px; padding-bottom: 0px;"></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1" style="padding-right: 0px; padding-top: 3px; padding-bottom: 0px; font-size: 14px;">
                      <template v-for="(analyze_sen, analyze_sen_ind) in d_analyzeSentenceList.list">
                        <b-row style="margin: 0px;">
                          <b-col cols="6" style="padding: 0px;"></b-col>
                          <b-col cols="6" style="padding: 0px; text-align: right; cursor: pointer;" @click="f_openRowOperationsModal(analyze_sen_ind)">
                            <template v-if="analyze_sen.full_match_list && analyze_sen.full_match_list.length > 0 && analyze_sen.full_match_list[0].full_match">
                              <template v-if="analyze_sen.full_match_list.length === 1">
                                <span :style="f_calculateRowNumberStyle('color: green;')"> {{ analyze_sen_ind + 1 }} </span>
                              </template>
                              <template v-if="analyze_sen.full_match_list.length > 1">
                                <span :style="f_calculateRowNumberStyle('color: green;')"> {{ analyze_sen_ind + 1 }} </span>
                              </template>
                            </template>
                            <template v-else>
                              <span :style="analyze_sen.text !== '' ? f_calculateRowNumberStyle('color: red;') : f_calculateRowNumberStyle('color: black;')"> {{ analyze_sen_ind + 1 }} </span>
                            </template>
                          </b-col>
                        </b-row>
                        <!-- 
                          <div style="float: left; width: 50%;"></div>
                          <div style="float: left; width: 100%; text-align: right;">
                            <template v-if="analyze_sen.full_match_list && analyze_sen.full_match_list.length > 0 && analyze_sen.full_match_list[0].full_match">
                              <template v-if="analyze_sen.full_match_list.length === 1">
                                <span :style="f_calculateRowNumberStyle('color: green;')"> {{ analyze_sen_ind + 1 }} </span>
                              </template>
                              <template v-if="analyze_sen.full_match_list.length > 1">
                                <span :style="f_calculateRowNumberStyle('color: blue;')"> {{ analyze_sen_ind + 1 }} </span>
                              </template>
                            </template>
                            <template v-else>
                              <span :style="f_calculateRowNumberStyle('color: red;')"> {{ analyze_sen_ind + 1 }} </span>
                            </template>
                          </div>
                          <br style="height: 0px;">
                          -->
                      </template>
                    </b-col>
                    <b-col cols="10" style="padding-left: 3px; padding-top: 0px; padding-bottom: 0px;">
                      <!-- <web-editor :d_htmlEditorSettings="d_htmlEditorSettings" :f_sentenceTemplateKeyDown="f_sentenceTemplateKeyDown" :f_sentenceTemplateKeyUp="f_sentenceTemplateKeyUp"></web-editor> -->
                      <div style="width: 100%;">
                        <div autocomplete="off" tabindex="-1" @keydown="f_sentenceTemplateKeyDown" @keyup="f_sentenceTemplateKeyUp" id="wdmr_sentence_template" contenteditable="true" :style="f_calculateHtmlEditorStyle()">
                          <div>...</div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="1" style="padding-right: 0px; padding-top: 3px; padding-bottom: 0px;"></b-col>
                  </b-row>
                </template>
                <template v-else-if="p_sentenceTemplateSettings.report_view_mode === 'template'">
                  <div style="min-height: 250px;">
                    <template v-for="(analyze_sen, analyze_sen_ind) in d_analyzeSentenceList.list">
                      <template v-if="analyze_sen.full_match_list && analyze_sen.full_match_list.length > 0">
                        <b-row style="margin: 0px;">
                          <b-col cols="12">
                            <b-row>
                              <b-col cols="12">
                                <template v-if="analyze_sen.full_match_list.length === 1">
                                  <b-card no-body :style="'padding: 1px; margin: 2px; min-height: 8px; cursor: pointer; min-width: 30px; float: left; background-color: #7ff675; font-size: ' + d_htmlEditorSettings.fontSize.toString() + 'px;'" @click="f_openRowOperationsModal(analyze_sen_ind)">
                                    <span style="float: left;">{{ analyze_sen_ind + 1 }}</span>
                                  </b-card>
                                </template>
                                <template v-if="analyze_sen.full_match_list.length > 1">
                                  <b-card no-body :style="'padding: 1px; margin: 2px; min-height: 8px; cursor: pointer; min-width: 30px; float: left; background-color: #ffef76f5; font-size: ' + d_htmlEditorSettings.fontSize.toString() + 'px;'" @click="f_openRowOperationsModal(analyze_sen_ind)">
                                    <span style="float: left;">{{ analyze_sen_ind + 1 }}</span>
                                  </b-card>
                                </template>
                                <template v-for="(phrase, phrase_ind) in analyze_sen.full_match_list[0].list">
                                  <b-card no-body :style="f_calculateSentenceTemplatePhraseStyle(phrase)" @click="f_phraseSchemaOperation(phrase, analyze_sen_ind, 0, phrase_ind)">
                                    <b-row>
                                      <b-col cols="12">
                                        <template v-if="phrase.phrase === 'anatomy'">
                                          <div>
                                            <img src="@/icon/W3D_humanbody.png" style="width: 1.5em;" /> {{ phrase.prepare_phrase }}
                                          </div>
                                        </template>
                                        <template v-else-if="phrase.type === 'param'">
                                          <div>
                                            {{ phrase.prepare_phrase }}
                                          </div>
                                        </template>
                                        <template v-else-if="phrase.type === 'not_param'">
                                          <div>{{ phrase.phrase }}</div>
                                        </template>
                                      </b-col>
                                    </b-row>
                                  </b-card>
                                </template>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-else>
                        <b-row style="margin: 0px;">
                          <b-col cols="12">
                            <b-card no-body :style="'padding: 1px; margin: 2px; min-height: 8px; cursor: pointer; min-width: 30px; float: left; background-color: #f3c0d1; font-size: ' + d_htmlEditorSettings.fontSize.toString() + 'px;'" @click="f_openRowOperationsModal(analyze_sen_ind)">
                              {{ analyze_sen_ind + 1 }}
                            </b-card>
                            <b-card v-for="(txt, txt_index) in analyze_sen.text.split(' ')" no-body :style="'padding: 1px; margin: 2px; min-height: 8px; cursor: pointer; min-width: 30px; float: left; background-color: #f3c0d1; font-size: ' + d_htmlEditorSettings.fontSize.toString() + 'px;'" @click="f_openPhraseTextEditModal(analyze_sen_ind, analyze_sen.text, txt, txt_index)">
                              {{ txt }}
                            </b-card>
                          </b-col>
                        </b-row>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="p_sentenceTemplateSettings.report_view_mode === ''">
                  <div style="min-height: 250px; text-align: center; vertical-align: middle; color: green; font-size: 33px;">
                    Wdmr Rapor Oluşturma Alanı ...
                  </div>
                </template>
              </b-card>
            </template>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!p_sentenceTemplateSettings.settings_mode" class="mb-1" no-body header-tag="header" footer-tag="footer" style="margin-bottom: 5px;">
      <b-card-header class="p-1">
        <!-- <strong>{{ $t('wdm16.13228') }}</strong> -->
        <img src="@/icon/1705440.png" class="img-rounded img-responsive" style="width: 2em;" /> WDMR
      </b-card-header>
      <template v-if="d_showWisdomDataShowTrueFalse">
        <wisdom-data-show :change="'0'" :view_mode="wisdom_data_show_view_mode" :data="data" :data_type="data_type" :option_data="option_data[data_type]"></wisdom-data-show>
      </template>
    </b-card>
    <b-modal v-if="d_addEditSentenceTemplate.show" v-model="d_addEditSentenceTemplate.show" size="xl" title="Cümle şablonu ekleme & güncelleme" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row style="min-height: 500px;">
        <b-col cols="5">
          <b-row v-for="(sen_temp, sen_temp_ind) in d_addEditSentenceTemplate.template.list" style="margin-top: 5px;">
            <b-col cols=12>
              <b-card no-body style="height: 100%; float: left;">
                <b-dropdown variant="white" size="sm" right style="margin-right: 5px; margin-left: 5px;">
                  <template v-slot:button-content>
                    <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                  </template>
                  <b-dropdown-item @click="f_deletePhraseOfSentenceTemplate(sen_temp_ind)">
                    <img src="@/icon/2717434.png" style="width: 2.5em;" /> sil
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_addNewPhraseST(sen_temp_ind, 'up')">
                    <img src="@/icon/2717434.png" style="width: 2.5em;" /> üzerine yeni ifade ekle
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_addNewPhraseST(sen_temp_ind, 'down')">
                    <img src="@/icon/2717434.png" style="width: 2.5em;" /> altına yeni ifade ekle
                  </b-dropdown-item>
                </b-dropdown>
              </b-card>
              <b-card no-body v-if="d_addEditSentenceTemplate.selected === sen_temp_ind" style="width: 30px; height: 100%; cursor: pointer; background-color: #2ef72e; float: left; padding: 0px;"></b-card>
              <b-card no-body @click="f_selectPhraseOfSentenceTemplate(sen_temp_ind)" v-else style="width: 30px; height: 100%; cursor: pointer; background-color: #e1e6e3; float: left; padding: 0px;"></b-card>
              <b-card no-body :style="f_calculateSentenceTemplatePhraseStyle(sen_temp)">
                <b-row>
                  <b-col cols="12">
                    <b-button size="md" variant="white" :title="'Change Mode. Active: ' + sen_temp.type + (sen_temp.phrase === 'anatomy' ? ', anatomy' : '')" style="margin-right: 5px; padding: 0px; float: left;" @click="f_changePhraseOfSentenceTemplate(sen_temp_ind)">
                      <img src="@/icon/1421585.png" style="width: 2.5em;" />
                    </b-button>
                    <div v-if="sen_temp.type === 'param'">
                      <div v-if="sen_temp.phrase === 'anatomy'" style="float: left;">
                        <img src="@/icon/W3D_humanbody.png" style="width: 1.5em;" />
                      </div>
                      <div style="float: left;" :title="sen_temp.phrase">
                        <!-- {{ sen_temp.phrase }} -->
                        <span v-if="sen_temp.param_data && sen_temp.param_data.label">
                          {{ sen_temp.param_data.label }}
                        </span>
                        <span v-else-if="sen_temp.phrase">
                          {{ sen_temp.phrase }}
                        </span>
                        <span v-else>
                          Click for parameter selection
                        </span>
                        <!-- <b-form-input v-model="sen_temp.phrase"></b-form-input> -->
                      </div>
                    </div>
                    <div v-else-if="sen_temp.type === 'not_param'" style="float: left;">
                      <span v-if="sen_temp.phrase"> {{ sen_temp.phrase }} </span>
                      <span v-else>Please write your phrase.</span>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px;">
            <b-col cols="12">
              <b-button variant="success" @click="f_addNewPhraseST('', 'down')">yeni ifade ekle</b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="7">
          <b-card v-if="d_addEditSentenceTemplate.selected !== ''" no-body header-tag="header" footer-tag="footer" border-variant="secondary" class="mb-1">
            <b-card-header header-bg-variant="white" class="p-1">
              <strong>{{ d_addEditSentenceTemplate.template.list[d_addEditSentenceTemplate.selected].type }}</strong>
            </b-card-header>
            <b-row v-if="d_addEditSentenceTemplate.template.list[d_addEditSentenceTemplate.selected].type === 'not_param'" style="margin: 2px;">
              <b-col cols="12">
                <b-form-input v-model="d_addEditSentenceTemplate.template.list[d_addEditSentenceTemplate.selected].phrase" placeholder="Please write your phrase."></b-form-input>
              </b-col>
            </b-row>
            <b-row v-else-if="d_addEditSentenceTemplate.template.list[d_addEditSentenceTemplate.selected].type === 'param' && d_addEditSentenceTemplate.template.list[d_addEditSentenceTemplate.selected].phrase !== 'anatomy'" style="margin: 2px;">
              <b-col cols="12">
                <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
                  <wdm-parameters :data_type="data_type" :search_in_first_parameter_list="d_addEditSentenceTemplate.search_in_first_parameter_list" :option_data="option_data" :selected_index_data="d_addEditSentenceTemplate.selected_index_data" :show_parameter_features="d_addEditSentenceTemplate.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_addEditSentenceTemplate.param_change" :selection="d_addEditSentenceTemplate.selection"></wdm-parameters>
                </div>
              </b-col>
            </b-row>
            <b-row v-else-if="d_addEditSentenceTemplate.template.list[d_addEditSentenceTemplate.selected].type === 'param' && d_addEditSentenceTemplate.template.list[d_addEditSentenceTemplate.selected].phrase === 'anatomy'" style="margin: 2px;">
              <b-col cols="12">
                <img src="@/icon/W3D_humanbody.png" style="width: 5em;" /> Anatomy
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row style="min-height: 50px; margin-top: 20px;">
        <b-col cols="12">
          <b-button variant="success" class="pull-right" @click="f_updateSentenceTemplateList()">güncelle</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_schemaAnatomy.show" v-model="d_schemaAnatomy.show" size="lg" :title="$t('wdm16.13247')" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <b-row style="min-height: 300px;">
        <b-col cols="12">
          <anatomy :layer_id="p_layerId" :selected_anatomy_region="d_schemaAnatomy.selected_anatomy" :selected_anatomy_parent="d_schemaAnatomy.selected_parent" key="schema_anatomy"></anatomy>
        </b-col>
      </b-row>
      <b-row style="min-height: 50px; margin-top: 20px;">
        <b-col cols="12">
          <b-button variant="success" class="pull-right" @click="f_selectAnatomyForSchema()">anatomiyi seç</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!--  -->
    <modal v-if="d_schemaAddEditModal.show" @close="d_schemaAddEditModal.show = false" :width="'750'">
      <h3 slot="header">
        Rapor Şablonu Oluşturma & Düzenleme Ekranı
      </h3>
      <div slot="body">
        <b-card no-body>
          <b-card-header class="p-1">
            Şablon Adı
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <b-form-input type="text" v-model="d_schemaAddEditModal.schema_name"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body>
          <b-card-header class="p-1">
            Şablon Etiketi
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col cols="6">
              <b-form-select v-model="d_schemaAddEditModal.tag_selection_type">
                <option value="new_tag">yeni etiket</option>
                <option value="select_tag">etiket seç</option>
              </b-form-select>
            </b-col>
            <b-col cols="6">
              <template v-if="d_schemaAddEditModal.tag_selection_type === 'select_tag'">
                <b-form-select v-model="d_schemaAddEditModal.schema_tag">
                  <option v-for="(tag, tag_index) in d_schemaAddEditModal.tag_list" :value="tag.tag">{{ tag.tag }}</option>
                </b-form-select>
              </template>
              <template v-else-if="d_schemaAddEditModal.tag_selection_type === 'new_tag'">
                <b-form-input type="text" v-model="d_schemaAddEditModal.schema_tag" placeholder="Yeni etiket için yazınız..."></b-form-input>
              </template>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body>
          <b-card-header class="p-1">
            Şablon
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <div v-html="d_schemaAddEditModal.schema_text"></div>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div slot="footer">
        <b-button @click="d_schemaAddEditModal.show = false" variant="danger" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}</b-button>
        <b-button @click="f_saveSenTemplateSchema()" variant="success" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> şemayı kaydet </b-button>
      </div>
    </modal>
    <!--  -->
    <b-modal v-if="d_schemaListModal.show" v-model="d_schemaListModal.show" size="xl" title="Şablon listesi" centered scrollable hide-footer header-bg-variant="primary">
      <b-card no-body>
        <b-row>
          <b-col cols="5">
            <b-form-input v-model="d_schemaListModal.search" placeholder="Filtrelemek için yazınız."></b-form-input>
          </b-col>
          <b-col cols="5">
            <b-button size="sm" :disabled="d_schemaListModal.selected_index === ''" variant="primary" style="margin-right: 5px; width: 100%;" @click="f_addThisSchema()">
              <img src="@/icon/1886795.png" class="img-rounded img-responsive" style="width: 2em;" /> rapora ekle
            </b-button>
          </b-col>
          <b-col cols="2" style="text-align: right;">
            <b-dropdown v-if="d_user.permissions.indexOf('wdmr_settings_update') !== -1" variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;">
              <template v-slot:button-content>
                <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
              </template>
              <b-dropdown-item v-if="d_schemaListModal.selected_index !== ''" @click="f_addSchemaToSentenceAnalyzeModal()">
                <img src="@/icon/931299.png" class="img-rounded img-responsive" style="width: 2em;" /> şablonu cümle analiz ekranına aktar
              </b-dropdown-item>
              <b-dropdown-item v-if="d_schemaListModal.selected_index !== ''" @click="f_updateSchemaFromReportTest()">
                <img src="@/icon/931299.png" class="img-rounded img-responsive" style="width: 2em;" /> rapordan şablona getir
              </b-dropdown-item>
              <b-dropdown-item v-if="d_schemaListModal.selected_index !== ''" @click="f_updateSchema()">
                <img src="@/icon/3094236.png" class="img-rounded img-responsive" style="width: 2em;" /> şablonu güncelle
              </b-dropdown-item>
              <b-dropdown-item v-if="d_schemaListModal.selected_index !== '' && d_schemaListModal.mode === 'report'" @click="f_schemaListMode('settings')">
                <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="width: 2em;" /> ayarlar moduna geç
              </b-dropdown-item>
              <b-dropdown-item v-if="d_schemaListModal.selected_index !== '' && d_schemaListModal.mode === 'settings'" @click="f_schemaListMode('report')">
                <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="width: 2em;" /> rapor moduna geç
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5">
            <div style="overflow-x: hidden; overflow-y: auto; height: 500px; border: solid 1px #007bff; font-size: 12px;">
              <b-card-header class="p-1">
                <b-row>
                  <b-col cols="12"><strong>Şablon Adı</strong></b-col>
                </b-row>
              </b-card-header>
              <b-row v-for="(schema, schema_ind) in d_schemaListModal.list" id="user-search-list-row" :style="d_schemaListModal.selected_index === schema_ind ? 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3; background-color: #03a9f4;' : 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3;'" v-if="!d_schemaListModal.search || (d_schemaListModal.search && (schema.schema_name.toLocaleLowerCase().indexOf(d_schemaListModal.search.toLocaleLowerCase()) !== -1 || schema.tag_id.indexOf(d_schemaListModal.search) !== -1))" @click="f_selectThisSchema(schema_ind)">
                <b-col sm="12" lg="2" style="padding-right: 2px;">
                  {{ schema.tag_id + '.' + schema.schema_id }}
                  <!-- {{ schema.tag_id }} -->
                  <!-- {{ schema.tag.toLocaleUpperCase() }}  -->
                </b-col>
                <b-col sm="12" lg="10" style="padding-left: 2px;">
                  <!-- {{ schema.schema_id }} /  -->{{ schema.schema_name.toLocaleUpperCase() }}
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="7">
            <b-row style="margin: 0px; margin-top: 1px; border: solid 1px #007bff; min-height: 500px;">
              <b-col cols="12" style="padding: 0px;">
                <template v-if="d_schemaListModal.mode === 'report'">
                  <b-row style="margin: 0px;">
                    <b-col cols="12" style="padding-left: 3px; padding-right: 3px; padding-top: 0px; padding-bottom: 0px;">
                      <div id="toolBar1" style="border: solid 1px #20a2ce;">
                        <img class="intLink" title="Clean" @click="f_clearHtmlTextDivSchema()" src="data:image/gif;base64,R0lGODlhFgAWAIQbAD04KTRLYzFRjlldZl9vj1dusY14WYODhpWIbbSVFY6O7IOXw5qbms+wUbCztca0ccS4kdDQjdTLtMrL1O3YitHa7OPcsd/f4PfvrvDv8Pv5xv///////////////////yH5BAEKAB8ALAAAAAAWABYAAAV84CeOZGmeaKqubMteyzK547QoBcFWTm/jgsHq4rhMLoxFIehQQSAWR+Z4IAyaJ0kEgtFoLIzLwRE4oCQWrxoTOTAIhMCZ0tVgMBQKZHAYyFEWEV14eQ8IflhnEHmFDQkAiSkQCI2PDC4QBg+OAJc0ewadNCOgo6anqKkoIQA7" />
                        <img class="intLink" title="Print" @click="printDoc('selected_schema_sentence_template');" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9oEBxcZFmGboiwAAAAIdEVYdENvbW1lbnQA9syWvwAAAuFJREFUOMvtlUtsjFEUx//n3nn0YdpBh1abRpt4LFqtqkc3jRKkNEIsiIRIBBEhJJpKlIVo4m1RRMKKjQiRMJRUqUdKPT71qpIpiRKPaqdF55tv5vvusZjQTjOlseUkd3Xu/3dPzusC/22wtu2wRn+jG5So/OCDh8ycMJDflehMlkJkVK7KUYN+ufzA/RttH76zaVocDptRxzQtNi3mRWuPc+6cKtlXZ/sddP2uu9uXlmYXZ6Qm8v4Tz8lhF1H+zDQXt7S8oLMXtbF4e8QaFHjj3kbP2MzkktHpiTjp9VH6iHiA+whtAsX5brpwueMGdONdf/2A4M7ukDs1JW662+XkqTkeUoqjKtOjm2h53YFL15pSJ04Zc94wdtibr26fXlC2mzRvBccEbz2kiRFD414tKMlEZbVGT33+qCoHgha81SWYsew0r1uzfNylmtpx80pngQQ91LwVk2JGvGnfvZG6YcYRAT16GFtW5kKKfo1EQLtfh5Q2etT0BIWF+aitq4fDbk+ImYo1OxvGF03waFJQvBCkvDffRyEtxQiFFYgAZTHS0zwAGD7fG5TNnYNTp8/FzvGwJOfmgG7GOx0SAKKgQgDMgKBI0NJGMEImpGDk5+WACEwEd0ywblhGUZ4Hw5OdUekRBLT7DTgdEgxACsIznx8zpmWh7k4rkpJcuHDxCul6MDsmmBXDlWCH2+XozSgBnzsNCEE4euYV4pwCpsWYPW0UHDYBKSWu1NYjENDReqtKjwn2+zvtTc1vMSTB/mvev/WEYSlASsLimcOhOBJxw+N3aP/SjefNL5GePZmpu4kG7OPr1+tOfPyUu3BecWYKcwQcDFmwFKAUo90fhKDInBCAmvqnyMgqUEagQwCoHBDc1rjv9pIlD8IbVkz6qYViIBQGTJPx4k0XpIgEZoRN1Da0cij4VfR0ta3WvBXH/rjdCufv6R2zPgPH/e4pxSBCpeatqPrjNiso203/5s/zA171Mv8+w1LOAAAAAElFTkSuQmCC">
                        <img class="intLink" title="Undo" @click="formatDoc('selected_schema_sentence_template', 'undo');" src="data:image/gif;base64,R0lGODlhFgAWAOMKADljwliE33mOrpGjuYKl8aezxqPD+7/I19DV3NHa7P///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARR8MlJq7046807TkaYeJJBnES4EeUJvIGapWYAC0CsocQ7SDlWJkAkCA6ToMYWIARGQF3mRQVIEjkkSVLIbSfEwhdRIH4fh/DZMICe3/C4nBQBADs=" />
                        <img class="intLink" title="Redo" @click="formatDoc('selected_schema_sentence_template', 'redo');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAB1ChDljwl9vj1iE34Kl8aPD+7/I1////yH5BAEKAAcALAAAAAAWABYAAANKeLrc/jDKSesyphi7SiEgsVXZEATDICqBVJjpqWZt9NaEDNbQK1wCQsxlYnxMAImhyDoFAElJasRRvAZVRqqQXUy7Cgx4TC6bswkAOw==" />
                        <img class="intLink" title="Remove formatting" @click="formatDoc('selected_schema_sentence_template', 'removeFormat')" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB9oECQMCKPI8CIIAAAAIdEVYdENvbW1lbnQA9syWvwAAAuhJREFUOMtjYBgFxAB501ZWBvVaL2nHnlmk6mXCJbF69zU+Hz/9fB5O1lx+bg45qhl8/fYr5it3XrP/YWTUvvvk3VeqGXz70TvbJy8+Wv39+2/Hz19/mGwjZzuTYjALuoBv9jImaXHeyD3H7kU8fPj2ICML8z92dlbtMzdeiG3fco7J08foH1kurkm3E9iw54YvKwuTuom+LPt/BgbWf3//sf37/1/c02cCG1lB8f//f95DZx74MTMzshhoSm6szrQ/a6Ir/Z2RkfEjBxuLYFpDiDi6Af///2ckaHBp7+7wmavP5n76+P2ClrLIYl8H9W36auJCbCxM4szMTJac7Kza////R3H1w2cfWAgafPbqs5g7D95++/P1B4+ECK8tAwMDw/1H7159+/7r7ZcvPz4fOHbzEwMDwx8GBgaGnNatfHZx8zqrJ+4VJBh5CQEGOySEua/v3n7hXmqI8WUGBgYGL3vVG7fuPK3i5GD9/fja7ZsMDAzMG/Ze52mZeSj4yu1XEq/ff7W5dvfVAS1lsXc4Db7z8C3r8p7Qjf///2dnZGxlqJuyr3rPqQd/Hhyu7oSpYWScylDQsd3kzvnH738wMDzj5GBN1VIWW4c3KDon7VOvm7S3paB9u5qsU5/x5KUnlY+eexQbkLNsErK61+++VnAJcfkyMTIwffj0QwZbJDKjcETs1Y8evyd48toz8y/ffzv//vPP4veffxpX77z6l5JewHPu8MqTDAwMDLzyrjb/mZm0JcT5Lj+89+Ybm6zz95oMh7s4XbygN3Sluq4Mj5K8iKMgP4f0////fv77//8nLy+7MCcXmyYDAwODS9jM9tcvPypd35pne3ljdjvj26+H2dhYpuENikgfvQeXNmSl3tqepxXsqhXPyc666s+fv1fMdKR3TK72zpix8nTc7bdfhfkEeVbC9KhbK/9iYWHiErbu6MWbY/7//8/4//9/pgOnH6jGVazvFDRtq2VgiBIZrUTIBgCk+ivHvuEKwAAAAABJRU5ErkJggg==">
                        <img class="intLink" title="Bold" @click="formatDoc('selected_schema_sentence_template', 'bold');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAInhI+pa+H9mJy0LhdgtrxzDG5WGFVk6aXqyk6Y9kXvKKNuLbb6zgMFADs=" />
                        <img class="intLink" title="Italic" @click="formatDoc('selected_schema_sentence_template', 'italic');" src="data:image/gif;base64,R0lGODlhFgAWAKEDAAAAAF9vj5WIbf///yH5BAEAAAMALAAAAAAWABYAAAIjnI+py+0Po5x0gXvruEKHrF2BB1YiCWgbMFIYpsbyTNd2UwAAOw==" />
                        <img class="intLink" title="Underline" @click="formatDoc('selected_schema_sentence_template', 'underline');" src="data:image/gif;base64,R0lGODlhFgAWAKECAAAAAF9vj////////yH5BAEAAAIALAAAAAAWABYAAAIrlI+py+0Po5zUgAsEzvEeL4Ea15EiJJ5PSqJmuwKBEKgxVuXWtun+DwxCCgA7" />
                        <img class="intLink" title="Left align" @click="formatDoc('selected_schema_sentence_template', 'justifyleft');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JMGELkGYxo+qzl4nKyXAAAOw==" />
                        <img class="intLink" title="Center align" @click="formatDoc('selected_schema_sentence_template', 'justifycenter');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIfhI+py+0Po5y02ouz3jL4D4JOGI7kaZ5Bqn4sycVbAQA7" />
                        <img class="intLink" title="Right align" @click="formatDoc('selected_schema_sentence_template', 'justifyright');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JQGDLkGYxouqzl43JyVgAAOw==" />
                        <img class="intLink" title="Numbered list" @click="formatDoc('selected_schema_sentence_template', 'insertorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAADljwliE35GjuaezxtHa7P///////yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKSespwjoRFvggCBUBoTFBeq6QIAysQnRHaEOzyaZ07Lu9lUBnC0UGQU1K52s6n5oEADs=" />
                        <img class="intLink" title="Dotted list" @click="formatDoc('selected_schema_sentence_template', 'insertunorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAAB1ChF9vj1iE33mOrqezxv///////yH5BAEAAAcALAAAAAAWABYAAAMyeLrc/jDKSesppNhGRlBAKIZRERBbqm6YtnbfMY7lud64UwiuKnigGQliQuWOyKQykgAAOw==" />
                        <img class="intLink" title="Quote" @click="formatDoc('selected_schema_sentence_template', 'formatblock','blockquote');" src="data:image/gif;base64,R0lGODlhFgAWAIQXAC1NqjFRjkBgmT9nqUJnsk9xrFJ7u2R9qmKBt1iGzHmOrm6Sz4OXw3Odz4Cl2ZSnw6KxyqO306K63bG70bTB0rDI3bvI4P///////////////////////////////////yH5BAEKAB8ALAAAAAAWABYAAAVP4CeOZGmeaKqubEs2CekkErvEI1zZuOgYFlakECEZFi0GgTGKEBATFmJAVXweVOoKEQgABB9IQDCmrLpjETrQQlhHjINrTq/b7/i8fp8PAQA7" />
                        <img class="intLink" title="Delete indentation" @click="formatDoc('selected_schema_sentence_template', 'outdent');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAAAAADljwliE35GjuaezxtDV3NHa7P///yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKCQG9F2i7u8agQgyK1z2EIBil+TWqEMxhMczsYVJ3e4ahk+sFnAgtxSQDqWw6n5cEADs=" />
                        <img class="intLink" title="Add indentation" @click="formatDoc('selected_schema_sentence_template', 'indent');" src="data:image/gif;base64,R0lGODlhFgAWAOMIAAAAADljwl9vj1iE35GjuaezxtDV3NHa7P///////////////////////////////yH5BAEAAAgALAAAAAAWABYAAAQ7EMlJq704650B/x8gemMpgugwHJNZXodKsO5oqUOgo5KhBwWESyMQsCRDHu9VOyk5TM9zSpFSr9gsJwIAOw==" />
                        <!-- <img class="intLink" title="Hyperlink" @click="var sLnk=prompt('Write the URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){formatDoc('selected_schema_sentence_template', 'createlink',sLnk)}" src="data:image/gif;base64,R0lGODlhFgAWAOMKAB1ChDRLY19vj3mOrpGjuaezxrCztb/I19Ha7Pv8/f///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARY8MlJq7046827/2BYIQVhHg9pEgVGIklyDEUBy/RlE4FQF4dCj2AQXAiJQDCWQCAEBwIioEMQBgSAFhDAGghGi9XgHAhMNoSZgJkJei33UESv2+/4vD4TAQA7" /> -->
                        <img class="intLink" title="Cut" @click="formatDoc('selected_schema_sentence_template', 'cut');" src="data:image/gif;base64,R0lGODlhFgAWAIQSAB1ChBFNsRJTySJYwjljwkxwl19vj1dusYODhl6MnHmOrpqbmpGjuaezxrCztcDCxL/I18rL1P///////////////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAVu4CeOZGmeaKqubDs6TNnEbGNApNG0kbGMi5trwcA9GArXh+FAfBAw5UexUDAQESkRsfhJPwaH4YsEGAAJGisRGAQY7UCC9ZAXBB+74LGCRxIEHwAHdWooDgGJcwpxDisQBQRjIgkDCVlfmZqbmiEAOw==" />
                        <img class="intLink" title="Copy" @click="formatDoc('selected_schema_sentence_template', 'copy');" src="data:image/gif;base64,R0lGODlhFgAWAIQcAB1ChBFNsTRLYyJYwjljwl9vj1iE31iGzF6MnHWX9HOdz5GjuYCl2YKl8ZOt4qezxqK63aK/9KPD+7DI3b/I17LM/MrL1MLY9NHa7OPs++bx/Pv8/f///////////////yH5BAEAAB8ALAAAAAAWABYAAAWG4CeOZGmeaKqubOum1SQ/kPVOW749BeVSus2CgrCxHptLBbOQxCSNCCaF1GUqwQbBd0JGJAyGJJiobE+LnCaDcXAaEoxhQACgNw0FQx9kP+wmaRgYFBQNeAoGihCAJQsCkJAKOhgXEw8BLQYciooHf5o7EA+kC40qBKkAAAGrpy+wsbKzIiEAOw==" />
                        <img class="intLink" title="Paste" @click="formatDoc('selected_schema_sentence_template', 'paste');" src="data:image/gif;base64,R0lGODlhFgAWAIQUAD04KTRLY2tXQF9vj414WZWIbXmOrpqbmpGjudClFaezxsa0cb/I1+3YitHa7PrkIPHvbuPs+/fvrvv8/f///////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAWN4CeOZGmeaKqubGsusPvBSyFJjVDs6nJLB0khR4AkBCmfsCGBQAoCwjF5gwquVykSFbwZE+AwIBV0GhFog2EwIDchjwRiQo9E2Fx4XD5R+B0DDAEnBXBhBhN2DgwDAQFjJYVhCQYRfgoIDGiQJAWTCQMRiwwMfgicnVcAAAMOaK+bLAOrtLUyt7i5uiUhADs=" />
                        <img class="intLink" title="HTML yeniden düzenle" @click="f_htmlToOnlyDivMode('selected_schema_sentence_template');" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAEw0lEQVR4Xu2b3X3bNhTFD2S/150g6ASO5AHiTJB0gEjWBEknqDNBnQls0wu0E0QewKQyQegJorxbQn+gSBqSKIq0QupKOHwkL8DL8+cFLr4UeIlSQInyhs6AQIT9BARCIMIUEOYOI4RAhCkgzB1GCIEIU0CYO4wQAhGmgDB3GCEEIkwBYe4wQghEmALC3GGEEIgwBYS5wwghEGEKCHOHEUIgwhQQ5g4jhECEKSDMHVERou+i9wBOG9ZoEn/ofmn4HS+uXhaQIPwB4OTFX1O5oBrG/e5NZfMWDaUBMe19u0woHgOx6OVB8RyIPCg+AvkGIAbwLm8ep6obD7vj9prL9W/yDch/cb9nMzno4OEToP5JpZlgqt5KgOIXEGM+x4OzywTIXXQOY746/6oIKASy2HrsHAqBrDbnFsof8bA72UWfQiBFqhuMMUv6lNahEMi6MNgRFAIpa5d2AIVANnUUBuN40OtuMvtVzwmkkpLmJu6fDSuZbmnkL5Dr6ATHeF1ZvyeM2+jkvQVSGUTLhn4BAfKpk5Z1rvw634AANnPqqH8rK7RqeB9/6I62KF9a1D8g2yrpzIdtW1VReQKpqyqB1FWsYXsCaVjgutX7BKSuNk3ZaztG6cwuodTHlXcQSFOyl9erryONI/OdQHajf+FbdRCubkva5wjRQXQBmIEgjctdMeZbPDj7lBkdHpDbB9sO/703QID7uN879wXIY7r95o0DqOiefXy/BqIG8Mp5Zu3s1tOi/cD2WZH98j33Vd4AeYz7PSsE0mbsGgY/40Ev2ceb30ukKd9NqIPQ7p86hTFfsuZF34U3MHCaxnkdSbZ0ZOw0x6lbrw5Cuy/LBZtB8QZI/qGpSHZTddE9QKm3ZXNFufiO3YLwDqgE9u3DVZK+TtXv2fS5DkILyY1Uf4HMIyLJXtb9jVasS0zVT3fjmg7CNzBKQ5mrpJlaAqevo9c4MpfZRri8H0j7sbjfy6eJCGRV/BGMGW/IaMqbjw2RRCAFvbB+zrIWxC3qsAtSTAL51ekpgdRXtNHp97aAJEfhnjCynXbSlxzjJEsMMh/Yh8wznGxgWNT8bEoxKzdZ6XsGgBkD6hxK/VkKZJ55ZRsc3HHJJp+AvZ46aRfI84yAmxIXZFnLDYkOQruka8+L+AckTU8ju7adbUBbM7PqjlNsShstCvk8gMzHGpmBCyQTu+RQjlPeGyCAURfo4BHG2D95Pl+U35t9BFRykGbhKiqTGcy3eQ7Rwat0bJLMBKTXCEp9xix5lp22HWGq/lo+lKNvo3dOeY+A1E84dlGCQHahesk7DxyIXXU7TmZc9+N6wmRpuuawFqj2g8J6Lw9ugWqfgeTZ4Eqi8XxwtInva3SkXtdhfRfJWV005iJd4FrK/HwCUrSpoC7Vpu33eaReV5vCNrtuJU3bE0jTCtesn0BqCta0OYE0rXDN+gmkpmBNm3sF5DacQOG3pjXdqn6vgNiVv9ms+snYrZR9YeFZ56rJ07iiBoYvlOigihGIMJwEQiDCFBDmDiOEQIQpIMwdRgiBCFNAmDuMEAIRpoAwdxghBCJMAWHuMEIIRJgCwtxhhBCIMAWEucMIIRBhCghzhxFCIMIUEOYOI4RAhCkgzB1GiDAg/wNX/HOSQnj0oAAAAABJRU5ErkJggg==" />
                        <select v-model="d_textOptions.formatblock" v-on:change="formatDocSelect('selected_schema_sentence_template', 'formatblock')" style="width: 75px;">
                          <option value="">format</option>
                          <option value="h1">Title 1 &lt;h1&gt;</option>
                          <option value="h2">Title 2 &lt;h2&gt;</option>
                          <option value="h3">Title 3 &lt;h3&gt;</option>
                          <option value="h4">Title 4 &lt;h4&gt;</option>
                          <option value="h5">Title 5 &lt;h5&gt;</option>
                          <option value="h6">Subtitle &lt;h6&gt;</option>
                          <!-- <option value="p">Paragraph &lt;p&gt;</option> -->
                          <!-- <option value="pre">Preformatted &lt;pre&gt;</option> -->
                        </select>
                        <select v-model="d_textOptions.fontname" v-on:change="formatDocSelect('selected_schema_sentence_template', 'fontname')" style="width: 75px;">
                          <option value="">font</option>
                          <option value="Font">Font</option>
                          <option value="Arial">Arial</option>
                          <option value="Arial Black">Arial Black</option>
                          <option value="Courier New">Courier New</option>
                          <option value="Times New Roman">Times New Roman</option>
                          <option value="Tahoma">Tahoma</option>
                        </select>
                        <select v-model="d_textOptions.fontsize" v-on:change="formatDocSelect('selected_schema_sentence_template', 'fontsize')" style="width: 75px;">
                          <option value="">- boyut -</option>
                          <option value="1">çok küçük</option>
                          <option value="2">biraz küçük</option>
                          <option value="3">Normal</option>
                          <option value="4">orta büyük</option>
                          <option value="5">büyük</option>
                          <option value="6">çok büyük</option>
                          <option value="7">maksimum büyüklükte</option>
                        </select>
                        <select v-model="d_textOptions.forecolor" v-on:change="formatDocSelect('selected_schema_sentence_template', 'forecolor')" style="width: 75px;">
                          <option value="">- color -</option>
                          <option value="red">kırmızı</option>
                          <option value="blue">mavi</option>
                          <option value="green">yeşil</option>
                          <option value="black">siyah</option>
                        </select>
                        <select v-model="d_textOptions.backcolor" v-on:change="formatDocSelect('selected_schema_sentence_template', 'backcolor')" style="width: 75px;">
                          <option value="">arka plan</option>
                          <option value="red">kırmızı</option>
                          <option value="blue">mavi</option>
                          <option value="green">yeşil</option>
                          <option value="black">siyah</option>
                        </select>
                      </div>
                    </b-col>
                  </b-row>
                  <div style="background-color: white; height: 500px; overflow-x: hidden; overflow-y: auto; color: black; cursor:text; padding:1em; font-size: 12px;">
                    <div autocomplete="off" tabindex="-1" id="selected_schema_sentence_template" contenteditable="true">
                    </div>
                  </div>
                </template>
                <template v-if="d_schemaListModal.mode === 'settings'">
                  <b-card-header class="p-1">
                    <b-row>
                      <b-col cols="4"><strong>Seçilen Model</strong></b-col>
                      <b-col cols="4"><strong>İşlem</strong></b-col>
                      <b-col cols="4"><strong>Atanmış Model</strong></b-col>
                    </b-row>
                  </b-card-header>
                  <b-row style="margin: 0px;">
                    <b-col cols="4">
                      <template v-if="d_smartSearchModel.list.length === 0">
                        <small>Akıllı Model Tanımlı Değil</small>
                      </template>
                      <b-button size="md" variant="white" title="Akıllı model listesi" style="margin-right: 5px; padding: 0px;" @click="f_openSmartSearchModelListModal()">
                        <img src="@/icon/2884572.png" style="width: 2.5em;" />
                        <template v-if="d_smartSearchModel.selected_index === ''">
                          Model seçiniz
                        </template>
                        <template v-if="d_smartSearchModel.selected_index !== ''">
                          {{ d_smartSearchModel.list[d_smartSearchModel.selected_index].name }}
                        </template>
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button size="sm" :disabled="d_smartSearchModel.selected_index === ''" variant="secondary" style="margin-right: 5px; width: 100%;" @click="f_assignSmartSearchModelToThisSchema()">
                        <img src="@/icon/1886795.png" class="img-rounded img-responsive" style="width: 1.5em;" /> modeli ata
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-dropdown variant="success" size="sm" style="margin-right: 5px; margin-left: 5px; width: 80%;" v-if="d_schemaListModal.selected_index !== '' && d_schemaListModal.selected_schema && d_schemaListModal.selected_schema.data && d_schemaListModal.selected_schema.data.general.smart_search_model_id && d_schemaListModal.selected_schema.data.general.smart_search_model_id.val && d_schemaListModal.selected_schema.data.general.smart_search_model_id.val">
                        <template v-slot:button-content>
                          <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" /> {{ f_showSmartSearchModelNameOfSchema() }}
                        </template>
                        <b-dropdown-item @click="f_deleteSmartSearchModelIdFromSchema()">
                          <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="width: 2em;" /> kaldır
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- 
          <b-row style="margin: 0px;">
            <b-col cols="10">
              <b-form-input v-model="d_schemaListModal.search_text" placeholder="Filtrelemek için yazınız. Yazılmadığında tüm şablonları getirir."></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-button variant="success" @click="f_schemaList()">sorgula</b-button>
            </b-col>
          </b-row>
         -->
      </b-card>
    </b-modal>
    <b-modal v-if="d_schemaClientIdMatch.show" v-model="d_schemaClientIdMatch.show" size="xl" title="Client Id Şablon Eşleştirme Ekranı" centered scrollable hide-footer header-bg-variant="primary">
      <b-card no-body>
        <b-row>
          <b-col cols="4">
            <b-row style="margin: 0px; min-height: 40px;">
              <b-col cols="6" style="padding: 1px;">
                <b-form-input v-model="d_schemaClientIdMatch.search_client_schema_id" placeholder="Filtrelemek için yazınız."></b-form-input>
              </b-col>
              <b-col cols="6" style="padding: 1px;">
                <template v-if="d_schemaClientIdMatch.doc">
                  <b-form-select v-model="d_schemaClientIdMatch.client_schema_id_search_type">
                    <option value="all">tümü</option>
                    <option value="assigned">atanan</option>
                    <option value="not_assigned_yet">atanmayan</option>
                  </b-form-select>
                </template>
              </b-col>
            </b-row>
            <b-row style="margin: 0px; min-height: 40px;">
              <b-col cols="3" style="padding: 1px;">
                <template v-if="d_schemaClientIdMatch.doc">
                  <strong style="color: darkblue;" v-if="d_schemaClientIdMatch.search_client_schema_id || d_schemaClientIdMatch.client_schema_id_search_type !== 'all'"> {{ d_schemaClientIdMatch.search_index_client_id_list.length }} / {{ d_schemaClientIdMatch.doc.data.general.client_id.list.length }} </strong>
                  <strong style="color: darkblue;" v-else> {{ d_schemaClientIdMatch.doc.data.general.client_id.list.length }} / {{ d_schemaClientIdMatch.doc.data.general.client_id.list.length }} </strong>
                </template>
              </b-col>
              <b-col cols="9" style="padding: 1px; text-align: right;">
                <template v-if="d_schemaClientIdMatch.doc">
                  <b-pagination v-if="d_schemaClientIdMatch.search_client_schema_id || d_schemaClientIdMatch.client_schema_id_search_type !== 'all'" size="sm" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_schemaClientIdMatch.pagination_client_schema.current" :total-rows="d_schemaClientIdMatch.search_index_client_id_list.length" :per-page="d_schemaClientIdMatch.pagination_client_schema.perpage"></b-pagination>
                  <b-pagination v-else size="sm" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_schemaClientIdMatch.pagination.current" :total-rows="d_schemaClientIdMatch.doc.data.general.client_id.list.length" :per-page="d_schemaClientIdMatch.pagination.perpage"></b-pagination>
                </template>
              </b-col>
            </b-row>
            <div style="overflow-x: hidden; overflow-y: auto; height: 500px; border: solid 1px #007bff; font-size: 12px;">
              <template v-if="d_schemaClientIdMatch.doc">
                <b-card-header class="p-1">
                  <b-row style="margin: 0px;">
                    <b-col cols="9"><strong>Client Şablon</strong></b-col>
                    <b-col cols="3"><strong>Wdmr</strong></b-col>
                  </b-row>
                </b-card-header>
                <template v-if="d_schemaClientIdMatch.search_client_schema_id  || d_schemaClientIdMatch.client_schema_id_search_type !== 'all'">
                  <b-row v-for="(client_schema_ind, client_schema_index_order) in d_schemaClientIdMatch.search_index_client_id_list" :style="d_schemaClientIdMatch.selected_index === client_schema_ind ? 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3; background-color: #03a9f4;' : 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3;'" @click="f_selectThisClientSchema(client_schema_ind)" v-if="f_filterClientSchema(client_schema_ind, client_schema_index_order)">
                    <b-col sm="12" lg="2" style="padding-right: 2px;">
                      {{ d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].schema_id.val }}
                    </b-col>
                    <b-col sm="12" lg="8" style="padding-left: 2px;">
                      {{ d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].schema_name.val }}
                    </b-col>
                    <b-col sm="12" lg="2" style="padding-left: 2px;">
                      <span v-if="d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].wdmr_schema_id">
                        {{ d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].wdmr_schema_id.val }}
                      </span>
                    </b-col>
                  </b-row>
                </template>
                <template v-else>
                  <b-row v-for="(client_schema, client_schema_ind) in d_schemaClientIdMatch.doc.data.general.client_id.list" :style="d_schemaClientIdMatch.selected_index === client_schema_ind ? 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3; background-color: #03a9f4;' : 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3;'" @click="f_selectThisClientSchema(client_schema_ind)" v-if="f_filterClientSchema(client_schema_ind, client_schema_ind)">
                    <b-col sm="12" lg="2" style="padding-right: 2px;">
                      {{ d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].schema_id.val }}
                    </b-col>
                    <b-col sm="12" lg="8" style="padding-left: 2px;">
                      {{ d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].schema_name.val }}
                    </b-col>
                    <b-col sm="12" lg="2" style="padding-left: 2px;">
                      <span v-if="d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].wdmr_schema_id">
                        {{ d_schemaClientIdMatch.doc.data.general.client_id.list[client_schema_ind].wdmr_schema_id.val }}
                      </span>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </div>
          </b-col>
          <b-col cols="4">
            <b-row style="margin: 0px; min-height: 40px;"></b-row>
            <b-row style="margin: 0px; min-height: 40px;">
              <b-col cols="6" style="padding: 1px;">
                <b-form-input v-model="d_schemaListModal.search" placeholder="Filtrelemek için yazınız."></b-form-input>
              </b-col>
              <b-col cols="6" style="padding: 1px;">
                <b-button size="sm" :disabled="d_schemaListModal.selected_index === '' || d_schemaClientIdMatch.selected_index === ''" variant="white" style="margin-right: 5px; width: 100%;" @click="f_assignSchemaIdToClientSchemaId()">
                  <img src="@/icon/1886795.png" class="img-rounded img-responsive" style="width: 1.5em;" /> client ata
                </b-button>
              </b-col>
            </b-row>
            <div style="overflow-x: hidden; overflow-y: auto; height: 500px; border: solid 1px #007bff; font-size: 12px;">
              <b-card-header class="p-1">
                <b-row>
                  <b-col cols="12"><strong>Wdmr Şablon</strong></b-col>
                </b-row>
              </b-card-header>
              <b-row v-for="(schema, schema_ind) in d_schemaListModal.list" id="user-search-list-row" :style="d_schemaListModal.selected_index === schema_ind ? 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3; background-color: #03a9f4;' : 'margin: 0px; cursor: pointer; border-bottom: solid 1px #99c5c3;'" v-if="!d_schemaListModal.search || (d_schemaListModal.search && (schema.schema_name.toLocaleLowerCase().indexOf(d_schemaListModal.search.toLocaleLowerCase()) !== -1 || schema.tag_id.indexOf(d_schemaListModal.search) !== -1))" @click="f_selectThisSchema(schema_ind)">
                <b-col sm="12" lg="2" style="padding-right: 2px;">
                  {{ schema.tag_id + '.' + schema.schema_id }}
                  <!-- {{ schema.tag_id }} -->
                  <!-- {{ schema.tag.toLocaleUpperCase() }}  -->
                </b-col>
                <b-col sm="12" lg="10" style="padding-left: 2px;">
                  <!-- {{ schema.schema_id }} /  -->{{ schema.schema_name.toLocaleUpperCase() }}
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="4">
            <b-row style="margin: 0px; min-height: 40px;">
            </b-row>
            <b-row style="margin: 0px; min-height: 40px;">
              <b-col cols="10"> </b-col>
              <b-col cols="2" style="text-align: right;">
                <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;">
                  <template v-slot:button-content>
                    <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                  </template>
                  <b-dropdown-item @click="f_assignSchemaIdToClientSchemaId()">
                    <img src="@/icon/688807.png" class="img-rounded img-responsive" style="width: 2em;" /> Wdmr şablon id atama işlemini gerçekleştir.
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_addDataFromExcel()">
                    <img src="@/icon/688807.png" class="img-rounded img-responsive" style="width: 2em;" /> Client Şablon Id listesini excelden ekle
                  </b-dropdown-item>
                  <b-dropdown-item v-if="d_schemaListModal.selected_index !== '' && d_schemaListModal.mode === 'report'" @click="f_schemaListMode('settings')">
                    <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="width: 2em;" /> ayarlar moduna geç
                  </b-dropdown-item>
                  <b-dropdown-item v-if="d_schemaListModal.selected_index !== '' && d_schemaListModal.mode === 'settings'" @click="f_schemaListMode('report')">
                    <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="width: 2em;" /> rapor moduna geç
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row style="margin: 0px; margin-top: 1px; border: solid 1px #007bff; min-height: 500px;">
              <b-col cols="12" style="padding: 0px;">
                <b-card-header class="p-1">
                  <b-row>
                    <b-col cols="12"><strong>Rapor</strong></b-col>
                  </b-row>
                </b-card-header>
                <template v-if="d_schemaListModal.mode === 'report'">
                  <div id="selected_schema_sentence_template" style="background-color: white; height: 450px; overflow-x: hidden; overflow-y: auto; color: black; cursor:text; padding:1em; font-size: 12px;"></div>
                </template>
                <template v-if="d_schemaListModal.mode === 'settings'">
                  <b-card-header class="p-1">
                    <b-row>
                      <b-col cols="4"><strong>Seçilen Model</strong></b-col>
                      <b-col cols="4"><strong>İşlem</strong></b-col>
                      <b-col cols="4"><strong>Atanmış Model</strong></b-col>
                    </b-row>
                  </b-card-header>
                  <b-row style="margin: 0px;">
                    <b-col cols="4">
                      <template v-if="d_smartSearchModel.list.length === 0">
                        <small>Akıllı Model Tanımlı Değil</small>
                      </template>
                      <b-button size="md" variant="white" title="Akıllı model listesi" style="margin-right: 5px; padding: 0px;" @click="f_openSmartSearchModelListModal()">
                        <img src="@/icon/2884572.png" style="width: 2.5em;" />
                        <template v-if="d_smartSearchModel.selected_index === ''">
                          <div>Model seçiniz</div>
                        </template>
                        <template v-if="d_smartSearchModel.selected_index !== ''">
                          {{ d_smartSearchModel.list[d_smartSearchModel.selected_index].name }}
                        </template>
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button size="sm" :disabled="d_smartSearchModel.selected_index === ''" variant="white" style="margin-right: 5px; width: 100%;" @click="f_assignSmartSearchModelToThisSchema()">
                        <img src="@/icon/1886795.png" class="img-rounded img-responsive" style="width: 1.5em;" /> modeli ata
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      {{ f_showSmartSearchModelNameOfSchema() }}
                      <b-dropdown variant="white" size="sm" style="width: 100%; margin: 0px;" v-if="d_schemaListModal.selected_index !== '' && d_schemaListModal.selected_schema && d_schemaListModal.selected_schema.data && d_schemaListModal.selected_schema.data.general.smart_search_model_id && d_schemaListModal.selected_schema.data.general.smart_search_model_id.val && d_schemaListModal.selected_schema.data.general.smart_search_model_id.val">
                        <template v-slot:button-content>
                          <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                        </template>
                        <b-dropdown-item @click="f_deleteSmartSearchModelIdFromSchema()">
                          <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="width: 2em;" /> kaldır
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <!--  -->
    <b-modal v-if="d_schemaParam.show" v-model="d_schemaParam.show" size="xl" title="Parametre Güncelleme Ekranı" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row v-if="['select', 'checkbox', 'radiogroup', 'object_list'].indexOf(d_schemaParam.param_data.type) !== -1" style="margin-bottom: 50px;">
        <b-col>
          <b-card no-body bg-variant="info">
            <b-card-header class="p-1">
              Parametreye Yeni Seçenekler Ekleme Ekranı
              <b-button @click="f_prepareNewParamOptionList()" class="pull-right" variant="white" size="sm" style="margin-right: 3px;">
                <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2em;" /> Seçenek Listesini Hazırla
              </b-button>
              <b-button @click="f_layerAddParameterOptionList()" class="pull-right" variant="white" size="sm" style="margin-right: 3px;">
                <i class="fa fa-save"></i> Yeni Seçenekleri Ekle
              </b-button>
            </b-card-header>
            <b-row>
              <b-col cols="12">
                <b-textarea v-model="d_schemaParam.new_option_text" rows="3" style="background: transparent;" placeholder="Lütfen araya virgüller koyarak yeni seçeneklerinizi ekleyiniz."></b-textarea>
              </b-col>
            </b-row>
            <b-row v-for="(op, op_index) in d_schemaParam.new_option_list" style="margin: 0px; border-bottom: solid 1px lightgreen;">
              <b-col cols="6">
                {{ op.label }}
              </b-col>
              <b-col cols="6">
                <strong v-if="op.already_included === 'yes'" style="color: red;">
                  zaten seçeneklerde mevcut
                </strong>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row style="min-height: 150px;">
        <b-col cols="6">
          <strong> {{ d_schemaParam.param_data ? d_schemaParam.param_data.name.label : '' }} </strong>
          <small style="color: blue;"> ( {{ d_schemaParam.index_list.join('.') }} )</small>
        </b-col>
        <b-col cols="6">
          <b-form-input v-if="d_schemaParam.param_data.type === 'integer'" type="number" v-model="d_schemaParam.param_value"></b-form-input>
          <b-form-input v-if="d_schemaParam.param_data.type === 'float'" type="number" v-model="d_schemaParam.param_value"></b-form-input>
          <b-form-checkbox-group v-if="d_schemaParam.param_data.type === 'checkbox'" v-model="d_schemaParam.param_value">
            <b-form-checkbox v-for="(op, op_ind) in d_schemaParam.param_data.options" :value="op.label"> {{ op.label }} </b-form-checkbox>
          </b-form-checkbox-group>
          <v-select v-if="d_schemaParam.param_data.type === 'select'" v-model="d_schemaParam.param_value" :options="d_schemaParam.param_data.options" style="width:100%;"></v-select>
          <v-select v-if="d_schemaParam.param_data.type === 'radiogroup'" v-model="d_schemaParam.param_value" :options="d_schemaParam.param_data.options" style="width:100%;"></v-select>
          <v-select v-if="d_schemaParam.param_data.type === 'object_list'" v-model="d_schemaParam.param_value" :options="d_schemaParam.param_data.options" style="width:100%;"></v-select>
          <b-form-input v-if="d_schemaParam.param_data.type === 'text'" type="text" v-model="d_schemaParam.param_value"></b-form-input>
          <b-form-input v-if="d_schemaParam.param_data.type === 'textarea'" type="text" v-model="d_schemaParam.param_value"></b-form-input>
          <b-form-input v-if="d_schemaParam.param_data.type === 'date'" type="date" v-model="d_schemaParam.param_value"></b-form-input>
          <b-form-input v-if="d_schemaParam.param_data.type === 'datetime'" type="date" v-model="d_schemaParam.param_value"></b-form-input>
        </b-col>
      </b-row>
      <b-row style="min-height: 50px; margin-top: 20px;">
        <b-col cols="12">
          <b-button @click="d_schemaParam.show = false" variant="danger" size="sm" style="margin-right: 3px;">
            <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}
          </b-button>
          <b-button variant="success" class="pull-right" @click="f_updateSchemaParameter()">güncelle</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!--  -->
    <b-modal v-if="d_smartSearchModel.show" v-model="d_smartSearchModel.show" size="xl" title="Akıllı Model Listesi" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-card no-body>
        <b-card-header class="p-1" v-if="d_smartSearchModel.edit_mode">
          <strong>Yeni Model Adı</strong>
        </b-card-header>
        <b-row v-if="d_smartSearchModel.edit_mode">
          <b-col cols="4">
            Yeni Model Adı
          </b-col>
          <b-col cols="4">
            <b-form-input v-model="d_smartSearchModel.new_model_name" placeholder="Yeni model eklemek için yazınız."></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-button @click="f_addNewSmartSearchModel()" variant="secondary" size="sm" style="margin-right: 3px;">
              <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2em;" /> modeli ekle
            </b-button>
          </b-col>
        </b-row>
        <b-card-header class="p-1">
          <b-row>
            <b-col cols="4">
              <strong>Modeller</strong>
            </b-col>
            <b-col cols="4">
              <b-form-input id="smart_search_model_filter" v-if="!d_smartSearchModel.edit_mode" v-model="d_smartSearchModel.search_text" placeholder="Filtrelemek için yazınız."></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-button class="pull-right" :title="'Düzenleme Modu'" size="md" :variant="d_smartSearchModel.edit_mode ? 'warning' : 'secondary'" style="margin-right: 5px; padding: 0px;" @click="d_smartSearchModel.edit_mode ? d_smartSearchModel.edit_mode = false : d_smartSearchModel.edit_mode = true">
                <img src="@/icon/3094236.png" style="width: 2.5em;" />
              </b-button>
              <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;">
                <template v-slot:button-content>
                  <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                </template>
                <b-dropdown-item @click="f_wdmSentenceSmartSearchModelGetAsLabeled('all')">
                  <img src="@/icon/2504347.png" style="width: 2.5em;" /> Cümle Kalıplarını Etiketlenmiş Olarak Al
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card-header>
        <div :style="d_smartSearchModel.edit_mode ? '' : 'overflow-x: hidden; overflow-y: auto; height: 400px;'">
          <b-row v-for="(smart_search, smart_search_index) in d_smartSearchModel.list">
            <b-col cols="12" v-if="f_filterSmartModel(smart_search)">
              <b-card v-if="!d_smartSearchModel.edit_mode" no-body :style="smart_search_index === d_smartSearchModel.selected_index ? 'background-color: #a9e2a9' : ''">
                <b-row style="margin: 0px;" @click="f_selectSmartSearchModel(smart_search_index)">
                  <b-col cols="12">
                    <span style="color: dodgerblue; margin-right: 5px;">
                      [id: {{ smart_search.id }}]
                    </span> {{ smart_search.name }}
                    <span v-if="smart_search_index === d_smartSearchModel.selected_index" style="color: forestgreen; margin-right: 5px;">
                      ifade adedi: {{ d_smartSearchModel.details.length }}
                    </span>
                  </b-col>
                </b-row>
              </b-card>
              <b-card v-if="d_smartSearchModel.edit_mode && smart_search_index === d_smartSearchModel.selected_index" no-body style="background-color: #a9e2a9;">
                <b-row style="margin: 0px;">
                  <b-col cols="6">
                    <template v-if="d_smartSearchModel.edit_mode">
                      <b-form-input v-model="smart_search.name"></b-form-input>
                    </template>
                    <template v-else>
                      <span style="color: dodgerblue; margin-right: 5px;">
                        [id: {{ smart_search.id }}]
                      </span> {{ smart_search.name }}
                      <span v-if="smart_search_index === d_smartSearchModel.selected_index" style="color: forestgreen; margin-right: 5px;">
                        ifade adedi: {{ d_smartSearchModel.details.length }}
                      </span>
                    </template>
                  </b-col>
                  <b-col cols="6">
                    <template v-if="d_smartSearchModel.edit_mode">
                      <b-button @click="f_editSmartSearchModel()" variant="white" size="sm" style="margin-right: 3px;" title="model adını güncelle">
                        <img src="@/icon/2087726.png" class="img-rounded img-responsive" style="width: 2em;" />
                      </b-button>
                      <b-button @click="f_copySmartSearchModelDetails()" variant="white" size="sm" style="margin-right: 3px;" title="modeli kopyala">
                        <img src="@/icon/3082142.png" class="img-rounded img-responsive" style="width: 2em;" />
                      </b-button>
                      <template v-if="d_copySmartSearchModelDetails">
                        <b-button @click="f_addCopiedSmartSearchModelDetails('reset')" variant="white" size="sm" style="margin-right: 3px;" title="kopyalanan modeli bu modele resetleyerek ekle">
                          <img src="@/icon/1277671.png" class="img-rounded img-responsive" style="width: 2em;" />
                        </b-button>
                        <b-button @click="f_addCopiedSmartSearchModelDetails('only_new')" variant="white" size="sm" style="margin-right: 3px;" title="kopyalanan modelden bu modelde olmayanları ekle">
                          <img src="@/icon/2766716.png" class="img-rounded img-responsive" style="width: 2em;" />
                        </b-button>
                      </template>
                      <b-button @click="f_wdmSentenceSmartSearchModelGetAsLabeled('this', smart_search.id)" variant="white" size="sm" style="margin-right: 3px;" title="Cümle Kalıplarını Etiketlenmiş Olarak Al">
                        <img src="@/icon/2504347.png" class="img-rounded img-responsive" style="width: 2em;" />
                      </b-button>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-if="d_smartSearchModel.selected_index !== '' && d_smartSearchModel.edit_mode">
          <b-card-header class="p-1">
            <strong>Model ifade listesi</strong>
          </b-card-header>
          <b-row>
            <b-col cols="6">
              <b-form-input v-model="d_smartSearchModel.search_text_phrase" placeholder="Filtrelemek için yazınız."></b-form-input>
            </b-col>
            <b-col cols="6">
              <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;" class="pull-right">
                <template v-slot:button-content>
                  <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                </template>
                <b-dropdown-item @click="f_deleteFromSmartSearchModel('st')">kalıplı cümleleri sil</b-dropdown-item>
                <b-dropdown-item @click="f_deleteFromSmartSearchModel('wp')">veri modeli seçeneklerini sil</b-dropdown-item>
                <b-dropdown-item @click="f_deleteFromSmartSearchModel('a')">anatomileri sil</b-dropdown-item>
                <b-dropdown-item @click="f_deleteFromSmartSearchModel('g')">genel ifadeleri sil</b-dropdown-item>
                <b-dropdown-item @click="f_deleteFromSmartSearchModel('all')">tümünü sil</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
            <b-row v-for="(op, op_index) in d_smartSearchModel.details" v-if="!d_smartSearchModel.search_text_phrase || (d_smartSearchModel.search_text_phrase && op.p.toLocaleLowerCase().indexOf(d_smartSearchModel.search_text_phrase.toLocaleLowerCase()) !== -1)" style="margin: 0px; border-bottom: solid 1px lightgreen;">
              <b-col cols="6">
                {{ op.p }}
              </b-col>
              <b-col cols="4">
                <b-form-select v-model="op.t">
                  <option value="g">genel</option>
                  <option value="a">anatomi</option>
                  <option value="st">kalıplı cümle</option>
                  <option value="wp">veri modeli seçeneği</option>
                </b-form-select>
              </b-col>
              <b-col cols="2">
                <b-button @click="d_smartSearchModel.details.splice(op_index, 1); $forceUpdate();" variant="white" size="sm" style="margin-right: 3px;" title="satırı sil">
                  <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="width: 2em;" />
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-card-header class="p-1">
            <strong>İfade listesi hazırlamak için virgüllerle ayrılmış şekilde ifadelerinizi yazınız.</strong>
          </b-card-header>
          <b-row>
            <b-col cols="12">
              <b-textarea v-model="d_smartSearchModel.text" rows="3" placeholder="İfade listesini hazırla"></b-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <strong>ifade tipi</strong>
            </b-col>
            <b-col cols="4">
              <b-form-select v-model="d_smartSearchModel.phrase_type">
                <option value="g">genel</option>
                <option value="a">anatomi</option>
                <option value="st">kalıplı cümle</option>
                <option value="wp">veri modeli seçeneği</option>
              </b-form-select>
            </b-col>
            <b-col cols="6">
              <b-button @click="f_prepareSmartSearchPhraseList()" variant="secondary" size="sm" style="margin-right: 3px;">
                <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2em;" /> ifade Listesini Hazırla
              </b-button>
            </b-col>
          </b-row>
          <b-card-header class="p-1">
            <b-row>
              <b-col cols="9">
                <strong>Oluşturulan ifade listesi</strong>
              </b-col>
              <b-col cols="3">
                <b-button @click="d_smartSearchModel.new_phrase_list = []; $forceUpdate();" variant="white" size="sm" style="margin-right: 3px;" title="tümünü temizle">
                  <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="width: 2em;" />
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-row v-for="(op, op_index) in d_smartSearchModel.new_phrase_list" style="margin: 0px; border-bottom: solid 1px lightgreen;">
            <b-col cols="4">
              {{ op.p }}
            </b-col>
            <b-col cols="4">
              <b-form-select v-model="op.t">
                <option value="g">genel</option>
                <option value="a">anatomi</option>
                <option value="st">kalıplı cümle</option>
                <option value="wp">veri modeli seçeneği</option>
              </b-form-select>
            </b-col>
            <b-col cols="2">
              <strong v-if="op.already_included === 'yes'" style="color: red;"> zaten seçeneklerde mevcut </strong>
            </b-col>
            <b-col cols="2">
              <b-button @click="d_smartSearchModel.new_phrase_list.splice(op_index, 1); $forceUpdate();" variant="white" size="sm" style="margin-right: 3px;" title="satırı sil">
                <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="width: 2em;" />
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-row style="min-height: 50px; margin-top: 20px;">
          <b-col cols="12" style="text-align: right;">
            <b-button @click="d_smartSearchModel.show = false" variant="danger" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}</b-button>
            <b-button v-if="d_smartSearchModel.edit_mode" @click="f_saveSmartSearchModelDetails()" variant="success" size="sm" style="margin-right: 3px;"> <i class="fa fa-plus-circle"></i> modeli güncelle</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal v-if="d_anatomyModal.show" v-model="d_anatomyModal.show" size="lg" title="Akıllı Model Listesi" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-card no-body>
        <b-card-header class="p-1">
          Anatomi Ekleme Ekranı
        </b-card-header>
        <b-row>
          <b-col cols="2">
            Anatomi Listesi:
          </b-col>
          <b-col cols="10">
            <anatomy :layer_id="p_layerId" :selected_anatomy_region="d_schemaAnatomy.selected_anatomy" :selected_anatomy_parent="d_schemaAnatomy.selected_parent" key="schema_anatomy"></anatomy>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-textarea v-model="d_anatomyModal.text" rows="10"></b-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
          </b-col>
          <b-col cols="4" style="text-align: right;">
            <b-button @click="f_prepareAnatomyList()" variant="secondary" size="sm" style="margin-right: 3px;">
              <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2em;" /> Anatomi Listesini Hazızla
            </b-button>
          </b-col>
        </b-row>
        <b-row v-for="(ana, ana_index) in d_anatomyModal.list">
          <b-col cols="12" :style="ana_index === d_anatomyModal.selected_index ? 'background-color: #a9e2a9' : ''">
            {{ ana.label }}
          </b-col>
        </b-row>
        <b-row style="min-height: 50px; margin-top: 20px;">
          <b-col cols="12" style="text-align: right;">
            <b-button @click="d_anatomyModal.show = false" variant="danger" size="sm" style="margin-right: 3px;">
              <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}
            </b-button>
            <b-button @click="f_layerAnatomyAddAnatomyList()" variant="success" size="sm" style="margin-right: 3px;">
              <i class="fa fa-save"></i> Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal v-if="d_wdmParameterOption.show" v-model="d_wdmParameterOption.show" size="xl" title="Parametrik Veri Modeli - Parametreye Seçenek Ekleme" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-card no-body>
        <b-card-header class="p-1">
          Parametreye Seçenek Ekleme Ekranı
        </b-card-header>
        <b-row>
          <b-col cols="7"></b-col>
          <b-col cols="5">
            <b-form-input v-model="d_wdmParameterOption.search_text"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="7">
            <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
              <wdm-parameters :data_type="data_type" :search_in_first_parameter_list="d_wdmParameterOption.search_in_first_parameter_list" :option_data="option_data" :selected_index_data="d_wdmParameterOption.selected_index_data" :show_parameter_features="d_wdmParameterOption.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameterOption.param_change" :selection="d_wdmParameterOption.selection"></wdm-parameters>
            </div>
          </b-col>
          <b-col cols="5">
            <div v-if="d_wdmParameterOption.param_data && ['select', 'checkbox', 'object_list', 'radiogroup'].indexOf(d_wdmParameterOption.param_data.type) !== -1">
              <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
                <b-row v-for="(op, op_index) in d_wdmParameterOption.param_data.options" v-if="!d_wdmParameterOption.search_text || (d_wdmParameterOption.search_text && op.label.toLocaleLowerCase().indexOf(d_wdmParameterOption.search_text.toLocaleLowerCase()) !== -1)">
                  <b-col cols="12">
                    {{ op.label }}
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <div v-if="d_wdmParameterOption.param_data && ['select', 'checkbox', 'object_list', 'radiogroup'].indexOf(d_wdmParameterOption.param_data.type) !== -1">
          <b-row>
            <b-col cols="12">
              <b-textarea v-model="d_wdmParameterOption.text" rows="3"></b-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button @click="f_prepareWdmParameterOptionList()" variant="secondary" size="sm" style="margin-right: 3px;">
                <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2em;" /> Seçenek Listesini Hazırla
              </b-button>
            </b-col>
          </b-row>
          <b-row v-for="(op, op_index) in d_wdmParameterOption.list" style="margin: 0px; border-bottom: solid 1px lightgreen;">
            <b-col cols="6">
              {{ op.label }}
            </b-col>
            <b-col cols="6">
              <strong v-if="op.already_included === 'yes'" style="color: red;"> zaten seçeneklerde mevcut </strong>
            </b-col>
          </b-row>
        </div>
        <b-row style="min-height: 50px; margin-top: 20px;">
          <b-col cols="12" style="text-align: right;">
            <b-button @click="d_wdmParameterOption.show = false" variant="danger" size="sm" style="margin-right: 3px;">
              <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}
            </b-button>
            <b-button v-if="d_wdmParameterOption.list.length > 0" @click="f_addWdmParameterOptionList()" variant="success" size="sm" style="margin-right: 3px;">
              <i class="fa fa-save"></i> Yeni Seçenekleri Ekle
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal v-if="d_editorTextRow.show" v-model="d_editorTextRow.show" size="sm" title="Editor Satır İşlemleri" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12"> Satır {{ d_editorTextRow.analyze_sen_ind + 1 }} </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_deleteSentenceRow(d_editorTextRow.analyze_sen_ind)" style="width: 100%;">
                  <img src="@/icon/2960590.png" style="width: 2em;" /> Satırı Sil
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_editorTextPhraseOperation.show" v-model="d_editorTextPhraseOperation.show" size="sm" title="İfade yazı işlemi" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-form-input v-model="d_editorTextPhraseOperation.txt"></b-form-input>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_updatePhraseText()" style="width: 100%;">
                  <img src="@/icon/2147852.png" style="width: 2em;" /> güncelle
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_fillWhileWriting.show" v-model="d_fillWhileWriting.show" size="sm" title="İfade yazı işlemi" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row>
        <b-col cols="12">
          <div v-if="d_understandSentenceTemplate.show" id="understand_sentence_template">
            <b-row>
              <b-col sm="12" lg="2">
                <img src="@/icon/2854751.png" style="width: 2.5em;" /> Cümle Şablonu Bul
              </b-col>
              <b-col sm="12" lg="8">
                <b-form-input id="understand_search_text" v-model="d_understandSentenceTemplate.text" :placeholder="d_understandST_result.search_param_type === 'only_not_param' ? 'Yazarken yazdığınız ifadeyle başlayan parametrik olmayan kelimelerden listeler' : 'Yazarken onaylayarak kalıpları otomatik olarak doldurmanızı sağlar.'"></b-form-input>
                <!-- <b-textarea v-model="d_understandSentenceTemplate.text" style="width: 100%;" placeholder="Cümle şablonu aramak için yazınız."></b-textarea> -->
                <b-row id="understand-search-param-popup" v-if="d_understandST_result.recommended_show && d_understandST_result.recommended_text_list.length > 0">
                  <b-col cols="12">
                    <div class="understand-search-param-row" v-for="(rec, rec_ind) in d_understandST_result.recommended_text_list" @click="f_acceptThisPhraseOption(rec)"> {{ rec }} </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="12" lg="2">
                <b-button size="lg" style="margin-right: 5px; padding: 0px;" variant="white" @click="f_deleteSearchSTUnderstandingText()" title="temizle">
                  <img src="@/icon/2358444.png" style="width: 2.5em;" />
                </b-button>
                <b-button v-if="d_understandST_result.list.length > 0" size="lg" style="margin-right: 5px; padding: 0px;" variant="white" @click="f_changeUnderstandStyle()" title="Arama Cümleleri Görünümü">
                  <div v-if="d_understandST_result.show_type === 'overflow'">
                    <img src="@/icon/1910880.png" style="width: 2.5em;" />
                  </div>
                  <div v-if="d_understandST_result.show_type === 'fulldiv'">
                    <img src="@/icon/824448.png" style="width: 2.5em;" />
                  </div>
                </b-button>
                <b-button size="lg" style="margin-right: 5px; padding: 0px;" variant="white" @click="f_changeSearchMode()" title="Arama Modunu Değiştir">
                  <div v-if="d_understandST_result.search_param_type === 'only_not_param'">
                    <img src="@/icon/1324908.png" style="width: 2.5em;" />
                  </div>
                  <div v-if="d_understandST_result.search_param_type === 'param_and_not_param'">
                    <img src="@/icon/2475229.png" style="width: 2.5em;" />
                  </div>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div v-if="d_understandST_result.list.length > 0">
                  <div :style="f_calculateUnderstandListStyle()">
                    <div v-for="(sen_temp_data, sen_temp_data_ind) in d_understandST_result.list">
                      <b-row style="margin: 0px; border-bottom: solid 1px #94c3b4; padding: 3px;">
                        <b-col cols="11" style="padding-left: 3px;">
                          <div style="min-height: 30px;">
                            <div v-for="(sen_temp, sen_temp_ind) in sen_temp_data.list">
                              <b-card no-body :style="f_calculateSentenceTemplatePhraseStyle(sen_temp)" @click="f_openSchemaParameterModalForUnderstand(sen_temp_data_ind, sen_temp_ind)">
                                <b-row>
                                  <b-col cols="12">
                                    <div v-if="sen_temp.accepted">
                                      <i class="fa fa-check-circle" style="float: left; color: green;"></i>
                                    </div>
                                    <div v-if="sen_temp.phrase === 'anatomy'" style="float: left;">
                                      <img src="@/icon/W3D_humanbody.png" style="width: 1.5em;" />
                                    </div>
                                    <div style="float: left;">
                                      <div v-if="sen_temp.prepare_phrase">
                                        {{ sen_temp.prepare_phrase }}
                                      </div>
                                      <div v-else-if="sen_temp.phrase ==='anatomy'">
                                        anatomy
                                      </div>
                                      <div v-else-if="sen_temp.param_data && sen_temp.param_data.label">
                                        {{ sen_temp.param_data.label }}
                                      </div>
                                      <div v-else-if="sen_temp.type === 'not_param'">
                                        {{ sen_temp.phrase }}
                                      </div>
                                    </div>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="1" style="padding-left: 3px;">
                          <b-button size="md" class="pull-right" style="margin-right: 5px; padding: 0px;" variant="white" @click="f_updateSentenceTemplateWithUnderstoodSentence(sen_temp_data_ind)" title="Cümleyi rapora ekle">
                            <img src="@/icon/688807.png" style="width: 2.5em;" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <modal v-if="d_sentenceAnalyze.show" @close="d_sentenceAnalyze.show = false" :width="'1250'" style="font-size: 10px;">
      <h3 slot="header"> Cümle Analiz Ekranı </h3>
      <div slot="body">
        <b-row>
          <b-col v-if="d_sentenceAnalyze.show_mode === 'work_list_and_label'">
            <b-card no-body>
              <b-row>
                <b-col cols="2">
                  Toplam {{ d_sentenceWorkList.total_count }} adet cümle
                </b-col>
                <b-col cols="1">
                  <div v-if="d_sentenceWorkList.analyzing_status === 'active'">
                    <img src="@/icon/sandwatch.gif" style="width: 2em;" />
                  </div>
                </b-col>
                <b-col cols="3">
                  <b-form-input style="font-size: 10px;" v-model="d_sentenceWorkList.next_list_id"></b-form-input>
                </b-col>
                <b-col cols="1">
                  {{ d_sentenceWorkList.total_list_id }}
                </b-col>
                <b-col cols="3">
                  <b-button variant="white" @click="f_nextSentenceWorkList('lower', false)">
                    <img src="@/icon/1277677.png" class="img-rounded img-responsive" style="width: 2em;" />
                  </b-button>
                  <b-button variant="white" @click="f_nextSentenceWorkList('upper', false)">
                    <img src="@/icon/1277676.png" class="img-rounded img-responsive" style="width: 2em;" />
                  </b-button>
                </b-col>
                <b-col cols="2">
                  <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;" class="pull-right">
                    <template v-slot:button-content>
                      <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_analyzeWorkListSentence()">
                      <img src="@/icon/2475229.png" class="img-rounded img-responsive" style="width: 2em;" /> tümünü analiz et
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
                <b-row v-for="(work_sentence, work_sentence_index) in d_sentenceWorkList.list" style="margin: 0px; border-bottom: solid 1px #dcd5d5;">
                  <b-col cols="2" :style="d_sentenceWorkList.selected_index === work_sentence_index ? 'background-color: #00bcd4;' : ''">
                    <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" /> <strong>{{ work_sentence_index + 1 }}</strong>
                      </template>
                      <b-dropdown-item @click="f_moveToSentenceWorkModal(work_sentence_index)">
                        <img src="@/icon/680848.png" style="width: 2.5em;" /> cümle çalışma alanına aktar
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_analyzeWorkListSentence(work_sentence_index)">
                        <img src="@/icon/2475229.png" style="width: 2.5em;" /> cümleyi analiz et
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col cols="10" @click="d_sentenceWorkList.selected_index = work_sentence_index; $forceUpdate();">
                    <div v-if="work_sentence.analyze_result" :style="work_sentence.analyze_result.full_match_list && work_sentence.analyze_result.full_match_list.length > 0 ? 'background-color: #c8eac8;' : 'background-color: #ffe6e6;'">
                      {{ work_sentence.label }}
                    </div>
                    <div v-else>
                      {{ work_sentence.label }}
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
          <b-col :cols="d_sentenceAnalyze.show_mode === 'work_list_and_label' ? 6 : 12">
            <b-row style="margin: 0px;">
              <b-col cols="12">
                <b-card no-body>
                  <b-card-header class="p-1">
                    <b-row style="margin: 0px;">
                      <b-col cols="6">
                        <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Satır Ekleme Alanı
                      </b-col>
                      <b-col cols="6" style="text-align: right;">
                        <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;" class="pull-right">
                          <template v-slot:button-content>
                            <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                          </template>
                          <b-dropdown-item @click="f_prepareWords()">
                            <img src="@/icon/2606588.png" class="img-rounded img-responsive" style="width: 2em;" /> kelime listesini hazırla
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_prepareWords('labeled')">
                            <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="width: 2em;" /> etiketlenmiş satırlardan kelime listesini hazırla
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_changeLabelingScreenShowMode('work_list_and_label')">
                            <img src="@/icon/1543450.png" class="img-rounded img-responsive" style="width: 2em;" /> çalışma listesi ve etiketleme görünümü
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_changeLabelingScreenShowMode('labeling')">
                            <img src="@/icon/1733173.png" class="img-rounded img-responsive" style="width: 2em;" /> yalnız etiketleme görünümü
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_addNewAnatomy()">
                            <img src="@/icon/W3D_humanbody.png" class="img-rounded img-responsive" style="width: 2em;" /> anatomi listesi
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_addNewWdmParameterOption()">
                            <img src="@/icon/1886761.png" class="img-rounded img-responsive" style="width: 2em;" /> veri modeli (wdm) parametre seçenek ayarları
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-row style="margin: 0px;">
                    <b-col cols="12">
                      <div contenteditable="true" id="sentence_analyze_test" style="overflow-x: hidden; overflow-y: auto; color: black; cursor:text; border: solid 1px #20a2ce; padding: 3px; width: 100%; height: 75px;"></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row style="margin: 0px;">
              <b-col cols="12">
                <b-tabs>
                  <b-tab @click="d_selectedTabSentenceAnalyze = 'labeling_screen'">
                    <template slot="title">
                      <img src="@/icon/665778.png" style="width: 1.5em;">
                      <span :style="'color: green'">Etiketleme Ekranı</span>
                    </template>
                    <template v-if="d_selectedTabSentenceAnalyze === 'labeling_screen'">
                      <b-row style="margin: 0px;">
                        <b-col cols="12">
                          <b-card no-body>
                            <b-card-header class="p-1">
                              <b-row>
                                <b-col cols="11">
                                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Etiketleme Ekranı
                                  <small v-if="d_smartSearchModel.list.length === 0">Akıllı Model Tanımlı Değil</small>
                                  <b-button size="md" variant="white" title="Akıllı model listesi" style="margin-right: 5px; padding: 0px;" @click="f_openSmartSearchModelListModal()">
                                    <img src="@/icon/2884572.png" style="width: 2.5em;" />
                                  </b-button>
                                  <span v-if="d_smartSearchModel.selected_index !== ''">
                                    {{ d_smartSearchModel.list[d_smartSearchModel.selected_index].name }}
                                  </span>
                                </b-col>
                                <b-col cols="1">
                                  <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;" class="pull-right">
                                    <template v-slot:button-content>
                                      <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                                    </template>
                                    <b-dropdown-item @click="f_sentenceAnalyzePrepare(['anatomy', 'wdm_param', 'template_sentence', 'template'])">anatomi, wdm parametrelerine ait seçenekleri kaydet, kalıp yoksa üret, seçili modele kalıplı cümleyi ekle</b-dropdown-item>
                                    <!--                   <b-dropdown-item @click="f_sentenceAnalyzePrepare(['anatomy'])">anatomileri kaydet</b-dropdown-item>
                    <b-dropdown-item @click="f_sentenceAnalyzePrepare(['wdm_param'])">seçili wdm parametrelerine ait seçenekleri kaydet</b-dropdown-item>
                    <b-dropdown-item @click="f_sentenceAnalyzePrepare(['anatomy', 'wdm_param'])">anatomi ve wdm parametrelerine ait seçenekleri kaydet</b-dropdown-item>
                    <b-dropdown-item @click="f_sentenceAnalyzePrepare(['template'])">kalıp oluştur</b-dropdown-item>
   -->
                                  </b-dropdown>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <b-row style="margin: 0px;">
                              <b-col cols="10">
                                <div v-for="(word_type, word_type_ind) in d_notParamList" :style="f_calculateWordTypeStyle(word_type.value)" @click="f_selectWordType(word_type.value)">
                                  {{ word_type.label }}
                                </div>
                              </b-col>
                              <b-col cols="2">
                                <div style="min-height: 30px; background-color: aliceblue;" @drop='f_onDropSentenceWork($event)' @dragover.prevent @dragenter.prevent>
                                  <b-card draggable @dragstart="f_startDragForSentenceWork($event, 'bracket', 'add_word')" no-body :style="f_calculateWordStyle({'type': 'bracket'})">
                                    ayraç
                                  </b-card>
                                </div>
                              </b-col>
                            </b-row>
                            <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
                              <div v-for="(sentence_data, sentence_data_ind) in d_sentenceAnalyze.sentence_list">
                                <b-row :style="sentence_data_ind % 2 === 0 ? 'margin: 5px; border-bottom: solid 1px #FBD6D5; background-color: #e8ede8;' : 'margin: 5px; border-bottom: solid 1px #FBD6D5; background-color: white;'">
                                  <b-col cols="12">
                                    <b-row>
                                      <b-col cols="1" style="padding-right: 1px;">
                                        <b-dropdown variant="white" size="sm" class="pull-right">
                                          <template v-slot:button-content>
                                            <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                                          </template>
                                          <b-dropdown-item @click="f_deleteSentenceFromAnalyzeList(sentence_data_ind)">sil</b-dropdown-item>
                                        </b-dropdown>
                                      </b-col>
                                      <b-col cols="11">
                                        <div v-for="(word_data, word_data_ind) in sentence_data.word_list">
                                          <b-card no-body :style="f_calculateWordStyle(word_data)" @drop="f_onDropSentenceAdd($event, 'add_word', sentence_data_ind, word_data_ind)" @dragover.prevent @dragenter.prevent draggable @dragstart="f_startDragForSentenceWord($event, 'word', sentence_data_ind, word_data_ind)">
                                            <b-row @click="f_selectWord(sentence_data_ind, word_data_ind)">
                                              <b-col cols="12">
                                                {{ word_data.word }}
                                              </b-col>
                                            </b-row>
                                          </b-card>
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <div v-if="sentence_data.wdm_param_phrase_list" v-for="(wdm_param_phrase, wdm_param_phrase_ind) in sentence_data.wdm_param_phrase_list">
                                      <b-row style="margin: 1px;">
                                        <b-col cols="1"></b-col>
                                        <b-col cols="8" style="background: #c0fff1;">
                                          <b-card no-body :style="f_calculateWordStyle({'type': 'wdm_param'})" @click="f_openWdmParameterSelectionModal(sentence_data_ind, wdm_param_phrase_ind)">
                                            <b-row>
                                              <b-col cols="12">
                                                {{ wdm_param_phrase.label }}
                                              </b-col>
                                            </b-row>
                                          </b-card>
                                        </b-col>
                                        <b-col cols="3" style="background: #c0fff1;">
                                          <div v-if="wdm_param_phrase.param_data">
                                            <b-button size="md" variant="white" title="Parametre seçimi yapınız." style="margin-right: 5px; padding: 0px;" @click="f_openWdmParameterSelectionModal(sentence_data_ind, wdm_param_phrase_ind)">
                                              <img src="@/icon/2504347.png" class="img-rounded img-responsive" style="width: 1.5em;" /> {{ wdm_param_phrase.param_data.name.label }}
                                            </b-button>
                                          </div>
                                          <div v-else>
                                            <b-button size="md" variant="white" title="Parametre seçimi yapınız." style="margin-right: 5px; padding: 0px;" @click="f_openWdmParameterSelectionModal(sentence_data_ind, wdm_param_phrase_ind)">
                                              <img src="@/icon/2504347.png" class="img-rounded img-responsive" style="width: 1.5em;" /> lütfen seçim yapınız
                                            </b-button>
                                          </div>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                    </template>
                  </b-tab>
                  <b-tab @click="d_selectedTabSentenceAnalyze = 'prepared_model_sentences'">
                    <template slot="title">
                      <img src="@/icon/665778.png" style="width: 1.5em;">
                      <span :style="'color: green'">Hazır Model Cümleleri</span>
                    </template>
                    <template v-if="d_selectedTabSentenceAnalyze === 'prepared_model_sentences'">
                      <b-row style="margin: 0px;">
                        <b-col cols="12">
                          <b-card no-body>
                            <b-card-header class="p-1">
                              <b-row>
                                <b-col cols="6">
                                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Hazır Model Cümleleri
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
                              <b-row v-for="(template_sentence, template_sentence_index) in d_sentenceAnalyze.template_sentence_list" style="margin: 5px; border-bottom: solid 1px royalblue;">
                                <b-col cols="12" :style="template_sentence_index === d_sentenceAnalyze.selected_template_sentence_list_index ? 'background-color: #a9e2a9' : ''">
                                  {{ template_sentence.label }}
                                </b-col>
                              </b-row>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                    </template>
                  </b-tab>
                  <b-tab @click="d_selectedTabSentenceAnalyze = 'general_phrases'">
                    <template slot="title">
                      <img src="@/icon/665778.png" style="width: 1.5em;">
                      <span :style="'color: green'">Genel İfadeler</span>
                    </template>
                    <div v-if="d_selectedTabSentenceAnalyze === 'general_phrases'">
                      <b-row style="margin: 0px;">
                        <b-col cols="12">
                          <b-card no-body>
                            <b-card-header class="p-1">
                              <b-row>
                                <b-col cols="8">
                                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Genel İfadeler
                                </b-col>
                                <b-col cols="4">
                                  <b-form-select v-model="d_sentenceAnalyze.new_phrase_list_general">
                                    <option value="yes">akıllı arama listesine ekle</option>
                                    <option value="no">akıllı arama listesine ekleme</option>
                                  </b-form-select>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
                              <b-row v-for="(general, general_index) in d_sentenceAnalyze.not_param_text_list" style="margin: 5px; border-bottom: solid 1px royalblue;">
                                <b-col cols="12">
                                  {{ general.label }}
                                </b-col>
                              </b-row>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                    </div>
                  </b-tab>
                  <b-tab @click="d_selectedTabSentenceAnalyze = 'anatomy_list'">
                    <template slot="title">
                      <img src="@/icon/665778.png" style="width: 1.5em;">
                      <span :style="'color: green'">Anatomi Listesi</span>
                    </template>
                    <template v-if="d_selectedTabSentenceAnalyze === 'anatomy_list'">
                      <b-row style="margin: 0px;">
                        <b-col cols="12">
                          <b-card no-body>
                            <b-card-header class="p-1">
                              <b-row>
                                <b-col cols="8">
                                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Anatomi Listesi
                                </b-col>
                                <b-col cols="4">
                                  <b-form-select v-model="d_sentenceAnalyze.new_phrase_list_anatomy">
                                    <option value="yes">akıllı arama listesine ekle</option>
                                    <option value="no">akıllı arama listesine ekleme</option>
                                  </b-form-select>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
                              <b-row v-for="(ana, ana_index) in d_sentenceAnalyze.anatomy_list" style="margin: 5px; border-bottom: solid 1px royalblue;">
                                <b-col cols="12" :style="ana_index === d_sentenceAnalyze.selected_anatomy_index ? 'background-color: #a9e2a9' : ''">
                                  {{ ana.label }}
                                </b-col>
                              </b-row>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                    </template>
                  </b-tab>
                  <b-tab @click="d_selectedTabSentenceAnalyze = 'parameter_option_list'">
                    <template slot="title">
                      <img src="@/icon/665778.png" style="width: 1.5em;">
                      <span :style="'color: green'">Parametre Seçenekleri</span>
                    </template>
                    <template v-if="d_selectedTabSentenceAnalyze === 'parameter_option_list'">
                      <b-row style="margin: 0px;">
                        <b-col cols="12">
                          <b-card no-body>
                            <b-card-header class="p-1">
                              <b-row>
                                <b-col cols="8">
                                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Parametre Seçenekleri
                                </b-col>
                                <b-col cols="4">
                                  <b-form-select v-model="d_sentenceAnalyze.new_phrase_list_wdm_parameter">
                                    <option value="yes">akıllı arama listesine ekle</option>
                                    <option value="no">akıllı arama listesine ekleme</option>
                                  </b-form-select>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
                              <div v-for="(sentence_data, sentence_data_ind) in d_sentenceAnalyze.sentence_list">
                                <div v-if="sentence_data.wdm_param_phrase_list" v-for="(wdm_param_phrase, wdm_param_phrase_ind) in sentence_data.wdm_param_phrase_list">
                                  <b-row v-if="wdm_param_phrase.param_data && ['radiogroup', 'select', 'object_list', 'object_list_options'].indexOf(wdm_param_phrase.param_data.type) !== -1" style="margin: 5px; border-bottom: solid 1px royalblue;">
                                    <b-col cols="1">
                                      <strong>{{ sentence_data_ind + 1 }} - </strong>
                                    </b-col>
                                    <b-col cols="7">
                                      <strong>{{ wdm_param_phrase.label }}</strong>
                                    </b-col>
                                    <b-col cols="4">
                                      <span v-if="wdm_param_phrase.param_data"> {{ wdm_param_phrase.param_data.name.label }} </span>
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                    </template>
                  </b-tab>
                  <b-tab @click="d_selectedTabSentenceAnalyze = 'sentence_template_list'">
                    <template slot="title">
                      <img src="@/icon/665778.png" style="width: 1.5em;">
                      <span :style="'color: green'">Cümle Şablonları</span>
                    </template>
                    <template v-if="d_selectedTabSentenceAnalyze === 'sentence_template_list'">
                      <b-row style="margin: 0px;">
                        <b-col cols="12">
                          <b-card no-body>
                            <b-card-header class="p-1">
                              <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Cümle Şablonları
                            </b-card-header>
                            <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
                              <div v-for="(sen_temp_data, sen_temp_data_ind) in d_sentenceAnalyze.template_list">
                                <b-row>
                                  <b-col cols="12">
                                    <div v-for="(sen_temp, sen_temp_ind) in sen_temp_data">
                                      <b-card no-body :style="f_calculateSentenceTemplatePhraseStyle(sen_temp)">
                                        <b-row>
                                          <b-col cols="12">
                                            <!-- <img src="@/icon/1421585.png" style="width: 2.5em; float: left;" /> -->
                                            <!-- <b-button size="md" variant="white" :title="'Change Mode. Active: ' + sen_temp.type + (sen_temp.phrase === 'anatomy' ? ', anatomy' : '')" style="margin-right: 5px; padding: 0px; float: left;" @click="f_changeSentenceTemplatePhraseMode(sen_temp_data_ind, sen_temp_ind)">
                                              <img src="@/icon/1421585.png" style="width: 2.5em;" />
                                            </b-button> -->
                                            <div v-if="sen_temp.type === 'param'">
                                              <div v-if="sen_temp.phrase === 'anatomy'" style="float: left;">
                                                <img src="@/icon/W3D_humanbody.png" style="width: 1.5em;" />
                                              </div>
                                              <div style="float: left;">
                                                <!-- {{ sen_temp.phrase }} -->
                                                <div v-if="sen_temp.phrase">
                                                  <div v-if="sen_temp.param_data && sen_temp.param_data.label">
                                                    {{ sen_temp.param_data.label }}
                                                  </div>
                                                  <div v-else>
                                                    {{ sen_temp.phrase }}
                                                  </div>
                                                </div>
                                                <div v-else>
                                                  Click for parameter selection
                                                </div>
                                                <!-- <b-form-input v-model="sen_temp.phrase"></b-form-input> -->
                                              </div>
                                            </div>
                                            <div v-else-if="sen_temp.type === 'not_param'">
                                              <div style="float: left;">
                                                <div v-if="sen_temp.phrase">
                                                  {{ sen_temp.phrase }}
                                                </div>
                                                <div v-else>
                                                  Click for parameter selection
                                                </div>
                                                <!-- <b-form-input v-model="sen_temp.phrase" placeholder="Please write your phrase."></b-form-input> -->
                                              </div>
                                            </div>
                                          </b-col>
                                        </b-row>
                                      </b-card>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                    </template>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <b-button @click="d_sentenceAnalyze.show = false" variant="danger" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}</b-button>
        <!-- <b-button @click="f_sentenceAnalyzeTest()" variant="success" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> cümle analizi </b-button> -->
        <b-button @click="f_sentenceAnalyzeComplete()" variant="success" size="sm" style="margin-right: 3px;">
          hazırlanan ifade ve kalıpları ekle
        </b-button>
      </div>
    </modal>
    <b-modal v-if="d_wdmParametersSentenceAnalyze.show" v-model="d_wdmParametersSentenceAnalyze.show" size="xl" title="Parametrik Veri Modeli - Parametreye Seçenek Ekleme" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-card no-body>
        <b-card-header class="p-1">
          Parametreye Seçenek Ekleme Ekranı
        </b-card-header>
        <b-row>
          <b-col cols="7"></b-col>
          <b-col cols="5">
            <b-form-input v-model="d_wdmParametersSentenceAnalyze.search_text"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="7">
            <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
              <wdm-parameters :data_type="data_type" :search_in_first_parameter_list="d_wdmParametersSentenceAnalyze.search_in_first_parameter_list" :option_data="option_data" :selected_index_data="d_wdmParametersSentenceAnalyze.selected_index_data" :show_parameter_features="d_wdmParametersSentenceAnalyze.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParametersSentenceAnalyze.param_change" :selection="d_wdmParametersSentenceAnalyze.selection"></wdm-parameters>
            </div>
          </b-col>
          <b-col cols="5">
            <div v-if="d_wdmParametersSentenceAnalyze.param_data && ['select', 'checkbox', 'object_list', 'radiogroup'].indexOf(d_wdmParametersSentenceAnalyze.param_data.type) !== -1">
              <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
                <b-row v-for="(op, op_index) in d_wdmParametersSentenceAnalyze.param_data.options" v-if="!d_wdmParametersSentenceAnalyze.search_text || (d_wdmParametersSentenceAnalyze.search_text && op.label.toLocaleLowerCase().indexOf(d_wdmParametersSentenceAnalyze.search_text.toLocaleLowerCase()) !== -1)">
                  <b-col cols="12">
                    {{ op.label }}
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="min-height: 50px; margin-top: 20px;">
          <b-col cols="12" style="text-align: right;">
            <b-button @click="d_wdmParametersSentenceAnalyze.show = false" variant="danger" size="sm" style="margin-right: 3px;">
              <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}
            </b-button>
            <b-button @click="f_selectWdmParameterForWdmPhrase()" variant="success" size="sm" style="margin-right: 3px;">
              <i class="fa fa-save"></i> Parametreyi seç
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal v-if="d_sentenceAnalyze.edit_show" v-model="d_sentenceAnalyze.edit_show" size="sm" title="İfade yazı işlemi" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-form-input v-model="d_sentenceAnalyze.edit_text"></b-form-input>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_updateSentenceAnalyzeWordText()" style="width: 100%;">
                  <img src="@/icon/2147852.png" style="width: 2em;" /> güncelle
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_operationsModal.show" v-model="d_operationsModal.show" size="md" title="işlemler" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_openSentenceAnalyzeModal('report');" style="width: 100%; text-align: left;">
                  <img src="@/icon/2964492.png" style="width: 2em;" /> cümle analiz & çalışma ekranı
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_addReportToSchema()" style="width: 100%; text-align: left;">
                  <img src="@/icon/3867503.png" style="width: 2em;" /> rapordan yeni şema oluştur
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_changeSentenceTemplateSchemaMode()" style="width: 100%; text-align: left;">
                  <img src="@/icon/1421585.png" style="width: 2em;" /> cümle şablon ayarları
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_addNewAnatomy()" style="width: 100%; text-align: left;">
                  <img src="@/icon/W3D_humanbody.png" style="width: 2em;" /> anatomi listesi
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_addNewWdmParameterOption()" style="width: 100%; text-align: left;">
                  <img src="@/icon/1886761.png" style="width: 2em;" /> veri modeli (wdm) parametre seçenek ayarları
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_openSchemaClientMatchScreen()" style="width: 100%; text-align: left;">
                  <img src="@/icon/286410.png" style="width: 2em;" /> Client Şablon ID eşleştirme ekranı
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12">
                <b-button variant="white" @click="f_nextSentenceWorkList('', true)" style="width: 100%; text-align: left;">
                  <img src="@/icon/286410.png" style="width: 2em;" /> Cümle Çalışma Listesini Aç
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_selectExcelData.show" v-model="d_selectExcelData.show" size="md" title="Client ID & Şablon eşleştirmesi için excel dokümanı ekle" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-row>
        <b-col cols="3"> Excel Dökümanı </b-col>
        <b-col cols="9">
          <input class="pull-right" id="new_excel_document" title="Excel dökümanı yükle" type="file" ref="fileinput" @change="f_selectExcelDocument()" accept=".xls,.xlsx">
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3"></b-col>
        <b-col cols="9">
          <button type="button" class="btn btn-success" @click="f_schemaClientIdMatchAddExcelFile()">eşleştirme dokümanını kaydet</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_sentenceWorkList.show" v-model="d_sentenceWorkList.show" size="xl" title="Cümle Çalışma Listeleri" centered scrollable hide-footer header-bg-variant="primary">
      <b-card no-body>
        <b-row>
          <b-col cols="2">
            Toplam {{ d_sentenceWorkList.total_count }} adet cümle
          </b-col>
          <b-col cols="3">
            <div v-if="d_sentenceWorkList.analyzing_status === 'active'">
              <img src="@/icon/sandwatch.gif" style="width: 2em;" />
            </div>
          </b-col>
          <b-col cols="1">
            <b-form-input v-model="d_sentenceWorkList.next_list_id"></b-form-input>
          </b-col>
          <b-col cols="1">
            / {{ d_sentenceWorkList.total_list_id }}
          </b-col>
          <b-col cols="3">
            <b-button variant="white" @click="f_nextSentenceWorkList('lower')">
              <img src="@/icon/1277677.png" class="img-rounded img-responsive" style="width: 2em;" />
            </b-button>
            <b-button variant="white" @click="f_nextSentenceWorkList('upper')">
              <img src="@/icon/1277676.png" class="img-rounded img-responsive" style="width: 2em;" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;" class="pull-right">
              <template v-slot:button-content>
                <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
              </template>
              <b-dropdown-item @click="f_analyzeWorkListSentence()">
                <img src="@/icon/2475229.png" class="img-rounded img-responsive" style="width: 2em;" /> tümünü analiz et
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
          <b-row v-for="(work_sentence, work_sentence_index) in d_sentenceWorkList.list" style="margin: 0px; border-bottom: solid 1px #dcd5d5;">
            <b-col cols="2" :style="d_sentenceWorkList.selected_index === work_sentence_index ? 'background-color: #00bcd4;' : ''">
              <b-dropdown variant="white" size="sm" style="margin-right: 5px; margin-left: 5px;">
                <template v-slot:button-content>
                  <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" /> <strong>{{ work_sentence_index + 1 }}</strong>
                </template>
                <b-dropdown-item @click="f_moveToSentenceWorkModal(work_sentence_index)">
                  <img src="@/icon/680848.png" style="width: 2.5em;" /> cümle çalışma alanına aktar
                </b-dropdown-item>
                <b-dropdown-item @click="f_analyzeWorkListSentence(work_sentence_index)">
                  <img src="@/icon/2475229.png" style="width: 2.5em;" /> cümleyi analiz et
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="10" @click="d_sentenceWorkList.selected_index = work_sentence_index; $forceUpdate();">
              <div v-if="work_sentence.analyze_result" :style="work_sentence.analyze_result.full_match_list && work_sentence.analyze_result.full_match_list.length > 0 ? 'background-color: #c8eac8;' : 'background-color: #ffe6e6;'">
                {{ work_sentence.label }}
              </div>
              <div v-else>
                {{ work_sentence.label }}
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import vSelect from "vue-select";
import { ClsWdmrForever, ClsWdm } from "@/services/public/wdm";
import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import {
  default as WebEditor
} from "@/components/widgets/WebEditor";
import {
  default as Anatomy
} from "@/components/widgets/Anatomy";
import {
  default as Modal
} from "@/components/widgets/Modal";
import WmanagerService from '@/services/wmanager';
import { mapGetters } from 'vuex';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import moment from 'moment';

export default {
  name: 'SentenceTemplateEditor',
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  components: {
    WisdomDataShow,
    WebEditor,
    WdmParameters,
    Modal,
    vSelect,
    Anatomy
  },
  props: {
    p_gender: {
      type: String,
      required: false
    },
    p_modelId: {
      type: String,
      required: false
    },
    p_schemaId: {
      type: String,
      required: false
    },
    p_clientSchemaId: {
      type: String,
      required: false
    },
    f_saveWdmrByChoosingFunction: {
      type: Function,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    p_sentenceTemplateSettings: {
      type: Object,
      required: true
    },
    p_layerId: {
      type: String,
      required: true
    },
    p_wdmrId: {
      type: String,
      required: true
    },
    p_wdmrToken: {
      type: String,
      required: false
    },
  },
  data () {
    return {
      d_sentenceWorkList: {
        'selected_index': '',
        'analyzing_status': 'passive',
        'analyzed_list': [],
        'total_count': '',
        'next_list_id': '',
        'total_list_id': 0,
        'list': [],
        'show': false,
      },
      d_lastSentenceSplitted: [],
      printSomething: false,
      d_selectedTabSentenceAnalyze: 'labeling_screen',
      d_schemaClientIdMatch: {
        'client_schema_id_search_type': 'all', // assigned, not_assigned_yet
        'pagination_client_schema': {
          'start': 0,
          'end': 25,
          'current': 1,
          'perpage': 25,
          'all_count': 0
        },
        'pagination': {
          'start': 0,
          'end': 25,
          'current': 1,
          'perpage': 25,
          'all_count': 0
        },
        'timeout_client_id': 0,
        'search_index_client_id_list': [],
        'list': [],
        'show': false,
        'selected_index': '',
        'doc': '',
        'search_client_schema_id': '',
        'search_schema': '',
      },
      d_selectExcelData: { 'file': '', 'show': false },
      d_labelList: {
        'label_order': ['@a', '@s', '@p', '@g'],
        'labels': [{
          'name': 'Anatomi Etiketleme'
        }, {
          'name': 'Ek etiketleme (suffix)'
        }, {
          'name': 'Parametre etiketleme'
        }, {
          'name': 'Genel ifade etiketleme'
        }, ]
      },
      d_operationsModal: {
        'show': false
      },
      d_notParamList: [
        { 'label': 'anatomi', 'value': 'anatomy' },
        { 'label': 'kelime eki', 'value': 'word_suffix' },
        { 'label': 'wdm parametresi', 'value': 'wdm_param' },
        { 'label': 'genel ifade', 'value': 'not_param' },
        { 'label': 'seçilmedi', 'value': '' },
        { 'label': 'sil', 'value': 'delete' },
        { 'label': 'düzenle', 'value': 'edit' },
      ],
      d_fillWhileWriting: {
        'show': false
      },
      d_sentenceAnalyze: {
        'show_mode': 'labeling',
        'not_param_text_list': [],
        'template_sentence_list': [],
        'selected_template_sentence_list_index': '',
        'edit_text': '',
        'new_phrase_list_general': 'no',
        'new_phrase_list_wdm_parameter': 'no',
        'new_phrase_list_anatomy': 'no',
        'edit_sentence_index': '',
        'edit_word_index': '',
        'edit_show': false,
        'word_type': '',
        'selected_anatomy_index': '',
        'anatomy_list': [],
        'selected_words': [],
        'sentence_list': [],
        'analyze_list': [],
        'show': false
      },
      d_textOptions: {
        'formatblock': '',
        'fontname': 'Tahoma',
        'fontsize': '',
        'forecolor': '',
        'backcolor': ''
      },
      d_editorTextPhraseOperation: {
        'show': false,
        'splitted_text_index': '',
        'analyze_sen_ind': '',
        'all_text': '',
        'txt': ''
      },
      d_updateEditorHtml: {
        'before': ''
      },
      d_editorTextRow: {
        'show': false,
        'analyze_sen_ind': ''
      },
      d_htmlEditorSettings: {
        'height': 750,
        'backgroundColor': 'white',
        'fontSize': 14,
        'fontFamily': 'Tahoma'
      },
      d_wdmParameterOption: {
        'selection': {
          'times': 'one', // one, multi
          'type': 'all', // all, this_index, this_types
          'this_index': []
        },
        'brief_list': {},
        'search_text': '',
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'dont_show_caption_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 },
        'template': { 'list': [] },
        'selected': '',
        'list': [],
        'text': '',
        'selected_index': '',
        'show': false
      },
      d_wdmParametersSentenceAnalyze: {
        'selection': {
          'times': 'one', // one, multi
          'type': 'all', // all, this_index, this_types
          'this_index': []
        },
        'brief_list': {},
        'search_text': '',
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'dont_show_caption_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 },
        'template': { 'list': [] },
        'selected': '',
        'list': [],
        'text': '',
        'selected_index': '',
        'show': false
      },
      d_anatomyModal: {
        'list': [],
        'text': '',
        'selected_index': '',
        'show': false
      },
      d_activeKeyDownText: '',
      d_lastKey: '',
      d_copySmartSearchModelDetails: '',
      d_smartSearchModel: {
        'search_text_phrase': '',
        'search_text': '',
        'edit_mode': false,
        'arrow_index': '',
        'new_model_name': '',
        'new_phrase_list': [],
        'found_list': [],
        'details': [],
        'list': [],
        'show': false,
        'text': '',
        'phrase_type': 'g',
        'selected_index': ''
      },
      d_schemaParam: {
        'new_option_list': [],
        'index_list': [],
        'selected_index': '',
        'new_option_text': '',
        'param_value': '',
        'param_data': '',
        'show': false
      },
      d_schemaAnatomy: {
        'selected_anatomy': {},
        'selected_parent': {},
        'show': false
      },
      d_schemaListModal: {
        'mode': 'report', // settings
        'search': '',
        'selected_index': '',
        'selected_schema': '',
        'show': false,
        'list': [],
        'tag_id': '',
        'search_text': ''
      },
      d_schemaAddEditModal: {
        'show': false,
        'tag_list': [],
        'tag_selection_type': 'select_tag',
        'schema_name': '',
        'schema_tag': '',
        'schema_text': '',
        'schema_id': '',
        'tag_id': ''
      },
      wisdom_data_show_view_mode: 'table',
      d_showWisdomDataShowTrueFalse: true,
      d_sentenceTemplate: {
        'timeout_client_id': 0,
        'search_index_list': [],
        'search_text': '',
        'filter_group': 'all',
        'show': false,
        'list': [],
        'all_list': [],
        'pagination': {
          'start': 0,
          'end': 15,
          'current': 1,
          'perpage': 15,
          'all_count': 0
        },
      },
      change: "0",
      view_mode: "table",
      d_addEditSentenceTemplate: {
        'selection': {
          'times': 'one', // one, multi
          'type': 'all', // all, this_index, this_types
          'this_index': []
        },
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'dont_show_caption_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 },
        'template': { 'list': [] },
        'selected': '',
        'show': false
      },
      d_understandSentenceTemplate: {
        'timeout': '',
        'text': '',
        'show': true
      },
      d_understandST_result: {
        'search_param_type': 'only_not_param', // param_and_not_param, only_not_param
        'recommended_text_list': [],
        'list': [],
        'show_type': 'overflow', // overflow or fulldiv
        'search_text': '',
        'recommended_show': true,
        'show': true
      },
      d_analyzeSentenceList: { 'list': [], 'sentence_accuracy': 0, 'analyzing': false },
      d_startFromDragType: '',
      d_timerControlWdmrSentenceTemplateUpdate: { 'timer': 0, 'status': 'passive', 'last_analyzed': '' },
      d_developerMode: { 'code': 0, 'status': 'passive' },
      d_user: { 'permissions': [] }
    };
  },
  created () {
    this.f_getSentenceSmartSearchModelList();
    if (localStorage.getItem('access_token')) {
      this.d_user.permissions.push('wdmr_settings_update');
    }
  },
  mounted () {
    this.d_developerMode.code = localStorage.getItem('sentence_template_editor_developer_code');
    if (this.d_developerMode.code === '942e5fd0-15e0-41fc-9f6a-a8d57507eaef') {
      this.d_developerMode.status = 'active';
    }
    if (this.p_clientSchemaId) {
      this.f_layerSchemaClientIdList2();
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.data.sentence_template_info && this.data.sentence_template_info.accuracy !== undefined) {
      this.d_analyzeSentenceList.sentence_accuracy = this.data.sentence_template_info.accuracy;
    }
    if (this.data.sentence_template) {
      this.f_prepareAnalyzeSentenceList(this.data.sentence_template);
      // this.f_sentenceTemplateAnalyze(this.data.sentence_template);
    } else {
      if (this.p_schemaId) {
        this.f_selectThisSchemaById(this.p_schemaId);
      }
    }
    setTimeout(function () {
      // this.setDocMode();
      let div_el = document.getElementById('wdmr_sentence_template');
      // console.log("div_el : ", div_el);
      // console.log("this.data.sentence_template ", this.data.sentence_template);
      let div_html_text = '';
      if (this.data.sentence_template) {
        div_html_text = this.data.sentence_template;
      }
      if (div_el) {
        this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', div_html_text, 'equal', true);
      }
    }.bind(this), 500)

    this.d_timerControlWdmrSentenceTemplateUpdate.timer = setInterval(function () {
      // console.log("this.d_project_id ", this.d_project_id);
      if (this.d_timerControlWdmrSentenceTemplateUpdate.status === 'passive') {
        this.f_prepareAnalyzeSentenceList();
      }
    }.bind(this), 10000);
  },
  destroyed () {
    window.clearInterval(this.d_timerControlWdmrSentenceTemplateUpdate.timer);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_htmlToOnlyDivMode: function (div_element_id) {
      let div_el = document.getElementById(div_element_id);
      let div_el_text_data = div_el.innerText;
      // console.log('div_el_text_data :', div_el_text_data);
      let sentence_splitted = div_el_text_data.split('\n');
      // console.log('sentence_splitted : ', sentence_splitted);
      let new_html_text = '';
      let free_rows_count = 0;
      for (let i in sentence_splitted) {
        if (sentence_splitted[i]) {
          free_rows_count = 0;
          new_html_text += '<div>' + sentence_splitted[i] + '</div>';
        } else {
          free_rows_count += 1;
          if (free_rows_count === 1) {
            new_html_text += '<div></br></div>';
          }
        }
      }
      div_el.innerHTML = new_html_text;
      this.$forceUpdate();
    },
    f_analyzeWorkListSentence: function (work_sentence_index = '') {
      this.d_sentenceWorkList.analyzing_status = 'active';
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId;
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken;
      }
      let text_analyze_data = {
        'text_list': [],
        'analyzed_list': this.d_sentenceWorkList.analyzed_list
      }
      if (work_sentence_index === '') {
        for (let t in this.d_sentenceWorkList.list) {
          text_analyze_data.text_list.push(this.d_sentenceWorkList.list[t].label);
        }
      } else {
        text_analyze_data.text_list.push(this.d_sentenceWorkList.list[work_sentence_index].label);
      }
      WmanagerService.wdm_sentence_template_analyze(query, text_analyze_data)
        .then(resp => {
          this.d_analyzeSentenceList.analyzing = false;
          if (resp.data.status_code === '3000') {
            if (resp.data.sentence_list && resp.data.sentence_list.length > 0) {
              if (work_sentence_index === '') {
                for (let t in this.d_sentenceWorkList.list) {
                  text_analyze_data.text_list.push(this.d_sentenceWorkList.list[t].label);
                  this.d_sentenceWorkList.list[t].analyze_result = resp.data.sentence_list[t];
                }
              } else {
                this.d_sentenceWorkList.list[work_sentence_index].analyze_result = resp.data.sentence_list[0];
              }
            }
            this.$forceUpdate();
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
          this.d_sentenceWorkList.analyzing_status = 'passive';
        });
    },
    f_changeLabelingScreenShowMode: function (show_mode) {
      this.d_sentenceAnalyze.show_mode = show_mode;
      this.$forceUpdate();
      if (show_mode === 'work_list_and_label') {
        if (this.d_sentenceWorkList.list.length === 0) {
          this.f_nextSentenceWorkList('', false);
        }
      }
    },
    f_sentenceTemplateEditorDeveloperMode: function () {
      if (this.d_developerMode.code && this.d_developerMode.code === '942e5fd0-15e0-41fc-9f6a-a8d57507eaef') {
        this.d_developerMode.status = 'active';
        localStorage.setItem('sentence_template_editor_developer_code', this.d_developerMode.code);
      }
    },
    f_moveToSentenceWorkModal: function (work_sentence_index) {
      this.f_openSentenceAnalyzeModal('', this.d_sentenceWorkList.list[work_sentence_index].label);
      // this.d_sentenceWorkList.show = false;
    },
    f_filterSentenceTemplate: function (sentence_template_index, sentence_template_index_order) {
      let st = this.d_sentenceTemplate.all_list[sentence_template_index];
      let res = false;
      if (this.d_sentenceTemplate.search_index_list.length > 0) {
        if (sentence_template_index_order >= this.d_sentenceTemplate.pagination.start && sentence_template_index_order <= this.d_sentenceTemplate.pagination.end) {
          res = true;
        }
      } else {
        if (sentence_template_index_order >= this.d_sentenceTemplate.pagination.start && sentence_template_index_order <= this.d_sentenceTemplate.pagination.end) {
          res = true;
        }
      }
      return res;
    },
    f_deleteFromSmartSearchModel: function (trash_smart_search_type) {
      if (trash_smart_search_type === 'all') {
        this.d_smartSearchModel.details = [];
      } else {
        let new_details = [];
        for (let i in this.d_smartSearchModel.details) {
          if (this.d_smartSearchModel.details[i].t !== trash_smart_search_type) {
            new_details.push(this.d_smartSearchModel.details[i]);
          }
        }
        this.d_smartSearchModel.details = new_details;
      }
      this.$forceUpdate();
    },
    f_assignSchemaIdToClientSchemaId: function () {
      let wdmr_schema_id = this.d_schemaListModal.selected_schema_item.tag_id + '_' + this.d_schemaListModal.selected_schema_item.schema_id;
      this.d_schemaClientIdMatch.doc.data.general.client_id.list[this.d_schemaClientIdMatch.selected_index].wdmr_schema_id = { 'val': wdmr_schema_id }
      this.f_layerSchemaClientIdListSave();
    },
    f_filterClientSchema: function (schema_index, client_schema_index_order) {
      let schema_data = this.d_schemaClientIdMatch.doc.data.general.client_id.list[schema_index];
      // console.log(schema_data);
      let res = false;
      if (this.d_schemaClientIdMatch.search_client_schema_id) {
        if (client_schema_index_order >= this.d_schemaClientIdMatch.pagination_client_schema.start && client_schema_index_order < this.d_schemaClientIdMatch.pagination_client_schema.end) {
          res = true;
        }
      } else {
        if (client_schema_index_order >= this.d_schemaClientIdMatch.pagination.start && client_schema_index_order < this.d_schemaClientIdMatch.pagination.end) {
          res = true;
        }
      }
      return res;
    },
    f_layerSchemaClientIdList2: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Client şema id listesi getiriliyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_schema_client_id_list_get(query)
        .then(resp => {
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '3000') {
            this.d_schemaClientIdMatch.doc = resp.data.doc;
            if (this.p_clientSchemaId) {
              if (this.d_schemaClientIdMatch.doc && this.d_schemaClientIdMatch.doc.data && this.d_schemaClientIdMatch.doc.data.general.client_id.list) {
                let unisex_found_schema = '';
                let is_found_schema = false;
                for (let schema_index in this.d_schemaClientIdMatch.doc.data.general.client_id.list) {
                  let client_schema_data = this.d_schemaClientIdMatch.doc.data.general.client_id.list[schema_index];
                  let client_schema_gender_male = '';
                  let client_schema_gender_female = '';
                  let client_schema_gender_unisex = '';
                  if (client_schema_data && client_schema_data.gender_male && client_schema_data.gender_male.val && client_schema_data.gender_male.val.value) {
                    client_schema_gender_male = client_schema_data.gender_male.val.value;
                  }
                  if (client_schema_data && client_schema_data.gender_female && client_schema_data.gender_female.val && client_schema_data.gender_female.val.value) {
                    client_schema_gender_female = client_schema_data.gender_female.val.value;
                  }
                  if (client_schema_data && client_schema_data.gender_unisex && client_schema_data.gender_unisex.val && client_schema_data.gender_unisex.val.value) {
                    client_schema_gender_unisex = client_schema_data.gender_unisex.val.value;
                  }
                  if (client_schema_data.schema_id && client_schema_data.schema_id.val === this.p_clientSchemaId && client_schema_data.wdmr_schema_id && client_schema_data.wdmr_schema_id.val) {
                    if (client_schema_gender_unisex === 'yes' || (client_schema_gender_male !== 'yes' && client_schema_gender_female !== 'yes')) {
                      unisex_found_schema = JSON.parse(JSON.stringify(client_schema_data));
                    }
                    /*
                      console.log('client_schema_gender_unisex           : ', client_schema_gender_unisex);
                      console.log('client_schema_gender_male             : ', client_schema_gender_male);
                      console.log('client_schema_gender_female           : ', client_schema_gender_female);
                      console.log('client_schema_data                    : ', client_schema_data);
                      console.log('client_schema_data.wdmr_schema_id.val : ', client_schema_data.wdmr_schema_id.val);
                      console.log('this.p_gender                         : ', this.p_gender);
                    */
                    if (this.p_gender) {
                      if (this.p_gender == 'male' && client_schema_gender_male === 'yes') {
                        this.f_selectThisSchemaById(client_schema_data.wdmr_schema_id.val);
                        is_found_schema = true;
                        break;
                      } else if (this.p_gender == 'female' && client_schema_gender_female === 'yes') {
                        this.f_selectThisSchemaById(client_schema_data.wdmr_schema_id.val);
                        is_found_schema = true;
                        break;
                      } else if (this.p_gender == 'unisex' && client_schema_gender_unisex === 'yes') {
                        this.f_selectThisSchemaById(client_schema_data.wdmr_schema_id.val);
                        is_found_schema = true;
                        break;
                      }
                    } else {
                      this.f_selectThisSchemaById(client_schema_data.wdmr_schema_id.val);
                      is_found_schema = true;
                      break;
                    }
                  }
                }
                /*
                  console.log('is_found_schema : ', is_found_schema);
                */
                if (!is_found_schema) {
                  if (unisex_found_schema !== '') {
                    this.f_selectThisSchemaById(unisex_found_schema.wdmr_schema_id.val);
                  }
                }
              }
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            console.log('f_layerSchemaClientIdList2 msg : ', msg)
          }
        });

    },
    f_layerSchemaClientIdListSave: function () {
      if (this.d_schemaClientIdMatch.doc) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'doc': this.d_schemaClientIdMatch.doc
        };
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Client şema id listesi getiriliyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_schema_client_id_list_save(query, data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000') {
              this.d_schemaClientIdMatch.doc = resp.data.doc;
              this.$forceUpdate();
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('f_layerSchemaClientIdList2 msg : ', msg)
            }
          });
      }
    },
    f_selectThisClientSchema: function (schema_index) {
      this.d_schemaClientIdMatch.selected_index = schema_index;
      let client_schema_data = this.d_schemaClientIdMatch.doc.data.general.client_id.list[schema_index];
      if (client_schema_data.wdmr_schema_id && client_schema_data.wdmr_schema_id.val) {
        if (this.d_schemaListModal.list.length > 0) {
          for (let schema_index in this.d_schemaListModal.list) {
            if (this.d_schemaListModal.list[schema_index].tag_id + '_' + this.d_schemaListModal.list[schema_index].schema_id === client_schema_data.wdmr_schema_id.val) {
              this.f_selectThisSchema(parseInt(schema_index));
              break;
            }
          }
        }
      }
    },
    f_selectExcelDocument: function () {
      if (document.getElementById('new_excel_document').value) {
        let arr = document.getElementById('new_excel_document').value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['xlsx', 'xls'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('new_excel_document').value = '';
          alert('Lütfen xlsx, xls formatını kullanınız');
          return;
        }
        var files = document.getElementById('new_excel_document').files;
        if (!files.length) {
          return;
        }
        var reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
          vm.d_selectExcelData.file = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    f_nextSentenceWorkList: function (type = '', model_show = true) {
      let next_list_id = 1;
      if (type === 'upper') {
        if (this.d_sentenceWorkList.next_list_id < this.d_sentenceWorkList.total_list_id) {
          next_list_id = parseInt(this.d_sentenceWorkList.next_list_id) + 1;
        }
      } else if (type === 'lower') {
        if (this.d_sentenceWorkList.next_list_id !== 1) {
          next_list_id = parseInt(this.d_sentenceWorkList.next_list_id) - 1;
          if (next_list_id === 0) {
            next_list_id = 1;
          }
        }
      }
      if (next_list_id) {
        this.d_sentenceWorkList.next_list_id = parseInt(next_list_id);
        if (model_show) {
          this.d_sentenceWorkList.show = true;
          this.d_operationsModal.show = false;
        }
      }

    },
    f_nextSentenceWorkListService: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      query += '&list_number=' + this.d_sentenceWorkList.next_list_id.toString();
      let data = {};
      WmanagerService.layer_sentence_list_work_get(query, data)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            this.d_sentenceWorkList.list = resp.data.list;
            this.d_sentenceWorkList.total_count = resp.data.total_count;
            this.d_sentenceWorkList.total_list_id = resp.data.total_list_id;
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });

    },
    f_openSchemaClientMatchScreen: function () {
      this.f_layerSchemaClientIdListGet()
    },
    f_schemaClientIdMatchAddExcelFile: function () {
      if (this.d_selectExcelData.file) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'file': this.d_selectExcelData.file
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanı analizi başlatıldı. Lütfen bekleyiniz.' } });
        WmanagerService.schema_client_id_match_add_excel_file(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000') {
              this.d_selectExcelData.show = false;
              this.f_layerSchemaClientIdListGet();
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      } else {
        alert('Lütfen öncelikle excel dökümanı seçimi yapınnız');
      }
    },
    f_layerSchemaClientIdListGet: function () {
      this.d_schemaClientIdMatch.doc = '';
      this.d_schemaClientIdMatch.selected_index = '';
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Client şema id listesi getiriliyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_schema_client_id_list_get(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '3000') {
            this.d_schemaClientIdMatch.doc = resp.data.doc;
            this.f_schemaList(false);
            this.d_schemaClientIdMatch.show = true;
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });

    },
    f_addDataFromExcel: function () {
      this.d_selectExcelData = {
        'show': true,
        'file': ''
      };
    },
    f_deleteSmartSearchModelIdFromSchema: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      let data = {
        'schema_name': this.d_schemaListModal.selected_schema.data.general.schema_name.val,
        'schema_tag': this.d_schemaListModal.selected_schema.data.general.schema_tag.val,
        'schema_text': this.d_schemaListModal.selected_schema.data.general.schema_text.val,
        'schema_id': this.d_schemaListModal.selected_schema_item.schema_id,
        'tag_id': this.d_schemaListModal.selected_schema_item.tag_id,
        'smart_search_model_id': ''
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema güncelleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
      WmanagerService.save_sentence_template_schema(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '3000') {
            if (resp.data.schema_id) {
              if (this.d_schemaListModal.selected_schema.data.general.smart_search_model_id !== undefined) {
                console.log('this.d_schemaListModal.selected_schema.data.general.smart_search_model_id : ', this.d_schemaListModal.selected_schema.data.general.smart_search_model_id);
                this.$delete(this.d_schemaListModal.selected_schema.data.general, 'smart_search_model_id');
                console.log('this.d_schemaListModal.selected_schema.data.general.smart_search_model_id : ', this.d_schemaListModal.selected_schema.data.general.smart_search_model_id);
                this.$forceUpdate();
              }
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_assignSmartSearchModelToThisSchema: function () {
      if (this.d_smartSearchModel.selected_index !== '') {
        let smart_search_model_id = this.d_smartSearchModel.list[this.d_smartSearchModel.selected_index].id;
        if (smart_search_model_id) {
          let query = 'layer_id=' + this.p_layerId;
          if (this.p_wdmrId) {
            query += '&wdmr_id=' + this.p_wdmrId
          }
          if (this.p_wdmrToken) {
            query += '&wdmr_token=' + this.p_wdmrToken
          }
          let data = {
            'schema_name': this.d_schemaListModal.selected_schema.data.general.schema_name.val,
            'schema_tag': this.d_schemaListModal.selected_schema.data.general.schema_tag.val,
            'schema_text': this.d_schemaListModal.selected_schema.data.general.schema_text.val,
            'schema_id': this.d_schemaListModal.selected_schema_item.schema_id,
            'tag_id': this.d_schemaListModal.selected_schema_item.tag_id,
            'smart_search_model_id': smart_search_model_id
          }
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema güncelleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
          WmanagerService.save_sentence_template_schema(query, data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status_code === '3000') {
                if (resp.data.schema_id) {
                  this.d_schemaListModal.selected_schema.data.general.smart_search_model_id = {
                    'val': smart_search_model_id
                  };
                  this.$forceUpdate();
                }
              } else {
                let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
                alert(msg);
              }
            });
        } else {
          alert('Akıllı model id tanımlı değil.');
        }
      } else {
        alert('Lütfen akıllı modellerden birini seçiniz.');
      }
    },
    f_showSmartSearchModelNameOfSchema: function () {
      let smart_search_model_id = ''
      let model_name = '';
      try {
        smart_search_model_id = this.d_schemaListModal.selected_schema.data.general.smart_search_model_id.val;
        for (let i in this.d_smartSearchModel.list) {
          if (this.d_smartSearchModel.list[i].id === smart_search_model_id) {
            model_name = this.d_smartSearchModel.list[i].name;
          }
        }
      } catch (err) {}
      return model_name;
    },
    f_schemaListMode: function (mode_type) {
      if (mode_type === 'report') {
        this.d_schemaListModal.mode = mode_type;
        if (this.d_schemaListModal.selected_schema && this.d_schemaListModal.selected_schema.data && this.d_schemaListModal.selected_schema.data.general.schema_text.val) {
          setTimeout(function () {
            this.f_initHtmlSentenceWdmrSentenceTemplate('selected_schema_sentence_template', this.d_schemaListModal.selected_schema.data.general.schema_text.val, 'equal', false);
          }.bind(this), 100)
        }
      } else {
        this.f_initHtmlSentenceWdmrSentenceTemplate('selected_schema_sentence_template', '', 'equal', false, false);
        this.d_schemaListModal.mode = mode_type;
      }
      this.$forceUpdate();
    },
    f_wdmSentenceSmartSearchModelGetAsLabeled: function (model_list_type, model_id = '') {
      let data = {};
      let query = 'layer_id=' + this.p_layerId;
      query += '&model_list_type=' + model_list_type;
      if (model_id) {
        query += '&model_id=' + model_id;
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Model cümle kalıpları etiketlenerek hazırlanıyor. Lütfen bekleyiniz.' } });
      WmanagerService.wdm_sentence_smart_search_model_get_as_labeled(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '3000') {
            FileSaver.saveAs(resp.data.file, "SentenceTemplateLabeled.xlsx");
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_addSchemaToSentenceAnalyzeModal: function () {
      this.f_openSentenceAnalyzeModal('schema');
      this.d_schemaListModal.show = false;
    },
    f_operationsModal: function () {
      this.d_operationsModal.show = true;
    },
    f_updateSentenceAnalyzeWordText: function () {
      if (this.d_sentenceAnalyze.edit_text) {
        let txt = this.d_sentenceAnalyze.edit_text.replace(/\./g, ' . ').replace(/,/g, ' , ').replace(/;/g, ' ; ').replace(/:/g, ' : ').replace(/[\s]+/g, " ");
        if (txt.replace(/[\s]+/g, "")) {
          let splitted_text = txt.split(' ');
          if (splitted_text.length === 1) {
            this.d_sentenceAnalyze.sentence_list[this.d_sentenceAnalyze.edit_sentence_index].word_list[this.d_sentenceAnalyze.edit_word_index].word = splitted_text[0];
          } else {
            for (let i in splitted_text) {
              if (parseInt(i) === 0) {
                this.d_sentenceAnalyze.sentence_list[this.d_sentenceAnalyze.edit_sentence_index].word_list[this.d_sentenceAnalyze.edit_word_index].word = splitted_text[0];
              } else {
                if (splitted_text[i]) {
                  let next_index = this.d_sentenceAnalyze.edit_word_index + parseInt(i);
                  let new_word_data = { 'word': splitted_text[i], 'type': '' };
                  this.d_sentenceAnalyze.sentence_list[this.d_sentenceAnalyze.edit_sentence_index].word_list.splice(next_index, 0, new_word_data);
                }
              }
            }
          }
        }
      } else {
        this.d_sentenceAnalyze.sentence_list[this.d_sentenceAnalyze.edit_sentence_index].word_list.splice(this.d_sentenceAnalyze.edit_word_index, 1);
      }
      this.d_sentenceAnalyze.edit_show = false;
      this.$forceUpdate();
    },
    f_deleteSentenceFromAnalyzeList: function (sentence_index) {
      this.d_sentenceAnalyze.sentence_list.splice(sentence_index, 1);
      this.$forceUpdate();
    },
    f_selectWdmParameterForWdmPhrase: function () {
      this.d_sentenceAnalyze.sentence_list[this.d_wdmParametersSentenceAnalyze.sentence_index].wdm_param_phrase_list[this.d_wdmParametersSentenceAnalyze.wdm_param_phrase_ind].index_list = JSON.parse(JSON.stringify(this.d_wdmParametersSentenceAnalyze.selected_index_data.index_list));
      let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], this.d_wdmParametersSentenceAnalyze.selected_index_data.index_list);
      this.d_sentenceAnalyze.sentence_list[this.d_wdmParametersSentenceAnalyze.sentence_index].wdm_param_phrase_list[this.d_wdmParametersSentenceAnalyze.wdm_param_phrase_ind].param_data = JSON.parse(JSON.stringify(param_data));
      this.d_wdmParametersSentenceAnalyze.show = false;
      this.$forceUpdate();
    },
    f_openWdmParameterSelectionModal: function (sentence_index, wdm_param_phrase_ind) {
      this.d_wdmParametersSentenceAnalyze.sentence_index = sentence_index;
      this.d_wdmParametersSentenceAnalyze.wdm_param_phrase_ind = wdm_param_phrase_ind;
      this.d_wdmParametersSentenceAnalyze.show = true;
    },
    f_onDropSentenceWork: function (evt) {
      let drag_type = evt.dataTransfer.getData('drag_type');
      let new_word_data = {};
      if (drag_type === 'word') {
        let from_word_data_ind = evt.dataTransfer.getData('from_word_data_ind');
        let from_sentence_ind = evt.dataTransfer.getData('from_sentence_ind');
        this.d_sentenceAnalyze.sentence_list[from_sentence_ind].word_list.splice(from_word_data_ind, 1);
        this.f_updateWdmParamPhraseList(from_sentence_ind);
      }
      this.$forceUpdate();
    },
    f_onDropSentenceAdd: function (evt, type, to_sentence_ind, to_word_data_ind) {
      let drag_type = evt.dataTransfer.getData('drag_type');
      let new_word_data = {};
      if (drag_type === 'bracket') {
        new_word_data.type = 'bracket';
        new_word_data.word = ' | ';
        this.d_sentenceAnalyze.sentence_list[to_sentence_ind].word_list.splice(to_word_data_ind, 0, new_word_data);
      } else if (drag_type === 'word') {
        let from_word_data_ind = evt.dataTransfer.getData('from_word_data_ind');
        let from_sentence_ind = evt.dataTransfer.getData('from_sentence_ind');
        let from_word_data = this.d_sentenceAnalyze.sentence_list[from_sentence_ind].word_list[from_word_data_ind];
        this.d_sentenceAnalyze.sentence_list[from_sentence_ind].word_list.splice(from_word_data_ind, 1);
        this.d_sentenceAnalyze.sentence_list[to_sentence_ind].word_list.splice(to_word_data_ind, 0, from_word_data);
      }
      this.f_updateWdmParamPhraseList(to_sentence_ind);
      this.$forceUpdate();
    },
    f_startDragForSentenceWork: function (evt, drag_type, type) {
      // console.log('evt ', evt);
      this.d_startFromDragType = type;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('drag_type', drag_type);
    },
    f_startDragForSentenceWord: function (evt, drag_type, from_sentence_ind, from_word_data_ind) {
      // console.log('evt ', evt);
      // this.d_startFromDragType = type;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('drag_type', drag_type);
      evt.dataTransfer.setData('from_sentence_ind', from_sentence_ind);
      evt.dataTransfer.setData('from_word_data_ind', from_word_data_ind);
    },
    f_showBracket: function (word_data) {
      let res = false;
      if (word_data.type === 'bracket') {
        res = true;
      }
      return res;
    },
    f_controlSentenceAnatomyCount: function (sentence_index) {
      let anatomy_word = '';
      let sentence_anatomy_count = 0;
      for (let word_index in this.d_sentenceAnalyze.sentence_list[sentence_index].word_list) {
        // console.log('word_index ', word_index);
        let word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index];
        // console.log('word_data ', word_data);
        let next_word_data = '';
        if (parseInt(word_index) !== this.d_sentenceAnalyze.sentence_list[sentence_index].word_list.length - 1) {
          next_word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[parseInt(word_index) + 1];
        }
        if (word_data.type === 'anatomy') {
          if (anatomy_word) {
            anatomy_word += ' ';
          }
          anatomy_word += word_data.word;
        } else if (word_data.type === 'word_suffix') {
          //
        } else {
          if (anatomy_word) {
            anatomy_word = '';
            sentence_anatomy_count += 1;
          }
        }
      }
      if (anatomy_word) {
        sentence_anatomy_count += 1;
      }
      return sentence_anatomy_count;
    },
    f_controlSentenceAnalyzeEligibility: function () {
      let is_eligible_all_words = true;
      if (this.d_smartSearchModel.selected_index === '') {
        is_eligible_all_words = false;
      }
      if (is_eligible_all_words) {
        for (let sentence_index in this.d_sentenceAnalyze.sentence_list) {
          let sentence_data = this.d_sentenceAnalyze.sentence_list[sentence_index];
          if (sentence_data.wdm_param_phrase_list && sentence_data.wdm_param_phrase_list.length > 0) {
            for (let wdm_param_phrase_index in sentence_data.wdm_param_phrase_list) {
              let wdm_param_phrase_data = sentence_data.wdm_param_phrase_list[wdm_param_phrase_index];
              if (!wdm_param_phrase_data.param_data) {
                is_eligible_all_words = false;
                break;
              }
            }
          }
          if (is_eligible_all_words) {
            for (let word_index in this.d_sentenceAnalyze.sentence_list[sentence_index].word_list) {
              let word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index];
              if (!word_data.type) {
                is_eligible_all_words = false;
                break;
              }
            }
          }
          if (!is_eligible_all_words) {
            break;
          }
        }
      }
      return is_eligible_all_words;
    },
    f_sentenceAnalyzeComplete: function () {
      if (this.f_controlSentenceAnalyzeEligibility()) {
        this.f_sentenceAnalyzeCompleteAnatomyList();
        this.f_sentenceAnalyzeCompleteSmartSearchPhraseList();
        this.f_sentenceAnalyzeCompleteParamOptionList();
        this.f_sentenceAnalyzeCompleteSentenceTemplate();
      } else {
        alert('Tüm kelime ya da kelime eklerine ait seçimleri, akıllı model seçimini, wdm parametresi olan parametre tipleri için parametre seçimini lütfen tamamlayınız.');
      }
    },
    f_sentenceAnalyzeCompleteSmartSearchPhraseList: function () {
      if (this.d_smartSearchModel.selected_index !== '') {
        /*
          this.d_sentenceAnalyze.not_param_text_list = [{"label":"gözlendi."}]
        */
        this.d_smartSearchModel.new_phrase_list = [];
        if (this.d_sentenceAnalyze.template_sentence_list && this.d_sentenceAnalyze.template_sentence_list.length > 0) {
          for (let temp_sent_index in this.d_sentenceAnalyze.template_sentence_list) {
            let already_included = 'no';
            for (let i in this.d_smartSearchModel.details) {
              if (this.d_smartSearchModel.details[i].p.toLocaleLowerCase() === this.d_sentenceAnalyze.template_sentence_list[temp_sent_index].label.toLocaleLowerCase()) {
                already_included = 'yes';
                break;
              }
            }
            if (already_included === 'no') {
              let x = {
                'p': this.d_sentenceAnalyze.template_sentence_list[temp_sent_index].label,
                't': 'st'
              };
              this.d_smartSearchModel.new_phrase_list.push(x);
              //
            }
          }
        }
        /*
            <option value="g">genel</option>
            <option value="a">anatomi</option>
            <option value="st">kalıplı cümle</option>
            <option value="wp">veri modeli seçeneği</option>
        */
        if (this.d_smartSearchModel.new_phrase_list_general && this.d_smartSearchModel.new_phrase_list_general === 'yes') {
          if (this.d_sentenceAnalyze.not_param_text_list && this.d_sentenceAnalyze.not_param_text_list.length > 0) {
            for (let not_param_index in this.d_sentenceAnalyze.not_param_text_list) {
              let already_included = 'no';
              for (let i in this.d_smartSearchModel.details) {
                if (this.d_smartSearchModel.details[i].p.toLocaleLowerCase() === this.d_sentenceAnalyze.not_param_text_list[not_param_index].label.toLocaleLowerCase()) {
                  already_included = 'yes';
                  break;
                }
              }
              if (already_included === 'no') {
                let x = {
                  'p': this.d_sentenceAnalyze.not_param_text_list[not_param_index].label,
                  't': 'g'
                };
                this.d_smartSearchModel.new_phrase_list.push(x);
              }
            }
          }
        }
        if (this.d_smartSearchModel.new_phrase_list_anatomy && this.d_smartSearchModel.new_phrase_list_anatomy === 'yes') {
          if (this.d_sentenceAnalyze.anatomy_list && this.d_sentenceAnalyze.anatomy_list.length > 0) {
            for (let anatomy_index in this.d_sentenceAnalyze.anatomy_list) {
              let already_included = 'no';
              for (let i in this.d_smartSearchModel.details) {
                if (this.d_smartSearchModel.details[i].p.toLocaleLowerCase() === this.d_sentenceAnalyze.anatomy_list[anatomy_index].label.toLocaleLowerCase()) {
                  already_included = 'yes';
                  break;
                }
              }
              if (already_included === 'no') {
                let x = {
                  'p': this.d_sentenceAnalyze.anatomy_list[anatomy_index].label,
                  't': 'a'
                };
                this.d_smartSearchModel.new_phrase_list.push(x);
              }
            }
          }
        }
        if (this.d_smartSearchModel.new_phrase_list_wdm_parameter && this.d_smartSearchModel.new_phrase_list_wdm_parameter === 'yes') {
          for (let sentence_index in this.d_sentenceAnalyze.sentence_list) {
            if (this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list && this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list.length > 0) {
              for (let wdm_param_phrase_index in this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list) {
                let wdm_param_data = this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list[wdm_param_phrase_index];
                if (wdm_param_data.label) {
                  let already_included = 'no';
                  for (let i in this.d_smartSearchModel.details) {
                    if (this.d_smartSearchModel.details[i].p.toLocaleLowerCase() === wdm_param_data.label.toLocaleLowerCase()) {
                      already_included = 'yes';
                      break;
                    }
                  }
                  if (already_included === 'no') {
                    let x = {
                      'p': wdm_param_data.label,
                      't': 'wp'
                    };
                    this.d_smartSearchModel.new_phrase_list.push(x);
                  }
                }
              }
            }
          }
        }
        // console.log("this.d_smartSearchModel.new_phrase_list ", this.d_smartSearchModel.new_phrase_list);
        if (this.d_smartSearchModel.new_phrase_list.length > 0) {
          this.f_saveSmartSearchModelDetails();
        }
      }
    },
    f_sentenceAnalyzeCompleteSentenceTemplate: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      this.p_sentenceTemplateSettings.report_view_mode = 'template';
      // console.log('this.p_sentenceTemplateSettings.report_view_mode ', this.p_sentenceTemplateSettings.report_view_mode);
      WmanagerService.wdm_sentence_template_list(query)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            this.d_sentenceTemplate.show = false;
            this.d_sentenceTemplate.list = [];
            this.d_sentenceTemplate.all_list = resp.data.list;
            let add_det = false;
            for (let i in this.d_sentenceAnalyze.template_list) {
              if ((this.d_sentenceAnalyze.template_list[i].length === 1 && this.d_sentenceAnalyze.template_list[i][0].phrase !== 'anatomy') || this.d_sentenceAnalyze.template_list[i].length > 1) {
                let new_sentence_template = { 'list': this.d_sentenceAnalyze.template_list[i] };
                let det = false;
                for (let li_index in this.d_sentenceTemplate.all_list) {
                  if (this.d_sentenceTemplate.all_list[li_index].list.length === new_sentence_template.list.length) {
                    let k = 0;
                    for (let x in this.d_sentenceTemplate.all_list[li_index].list) {
                      let phrase_data_1 = this.d_sentenceTemplate.all_list[li_index].list[x];
                      let phrase_data_2 = new_sentence_template.list[x];
                      if (phrase_data_1.type === phrase_data_2.type && phrase_data_1.phrase === phrase_data_2.phrase) {
                        k += 1;
                      }
                    }
                    if (k === new_sentence_template.list.length) {
                      det = true;
                      break;
                    }
                  }
                }
                if (!det) {
                  this.d_sentenceTemplate.all_list.push(new_sentence_template);
                  add_det = true;
                }
              }
            }
            if (add_det) {
              this.f_saveSentenceTemplate();
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_sentenceAnalyzeCompleteAnatomyList: function () {
      if (this.d_sentenceAnalyze.anatomy_list.length > 0) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'list': this.d_sentenceAnalyze.anatomy_list
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Anatomi ekleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_anatomy_add_anatomy_list(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      }
    },
    f_sentenceAnalyzeCompleteParamOptionList: function () {
      /*
        this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list =
        [{
            "label": "kistik lezyon",
            "index_list": ["anatomy", "observed_finding", "sign_name"],
            "param_data":
            {
                "show": "yes",
                "object_type": "object",
                "type": "select",
                "options": [{
                    "value": "1",
                    "label": "adduktor magnus straini ?"
                }],
                "name": {
                    "translation": {"en": "Bulgu Adı", "tr": "Bulgu Adı"},
                    "value": "sign_name",
                    "label": "Bulgu Adı"
                }
            }
        }]
      */
      let param_option_list = {};
      for (let sentence_index in this.d_sentenceAnalyze.sentence_list) {
        if (this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list) {
          for (let wdm_param_phrase_ind in this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list) {
            let wdm_param_phrase_data = this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list[wdm_param_phrase_ind];
            let param_data = wdm_param_phrase_data.param_data;
            if (param_data && ['radiogroup', 'select', 'object_list', 'object_list_options'].indexOf(param_data.type) !== -1) {
              let option_index = wdm_param_phrase_data.index_list.join('.');
              // option_index = "anatomy.observed_finding.sign_name"
              if (!param_option_list[option_index]) {
                param_option_list[option_index] = {
                  'list': [],
                  'index_list': wdm_param_phrase_data.index_list,
                  'param_data': param_data
                };
              }
              let opt = { 'label': wdm_param_phrase_data.label };
              param_option_list[option_index].list.push(opt);
            }
          }
        }
      }
      let data = {
        'list': [],
        'index_list': [],
        'multi_param_list': []
      };
      for (let param_index in param_option_list) {
        if (param_option_list[param_index].list.length > 0) {
          let new_param_options = {
            'list': param_option_list[param_index].list,
            'index_list': param_option_list[param_index].index_list,
          };
          data.multi_param_list.push(new_param_options);
        }
      }
      if (data.multi_param_list.length > 0) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Parametreye seçenek ekleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_wdm_parameter_option_add(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              for (let i in resp.data.updated_wdm_data) {
                this.option_data[this.data_type][i] = resp.data.updated_wdm_data[i];
              }
              this.$forceUpdate();
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      }
    },
    f_sentenceAnalyzePrepare: function (process_list) {
      let is_el = this.f_controlSentenceAnalyzeEligibility();
      // console.log('f_sentenceAnalyzePrepare: is_el : ', is_el)
      if (is_el) {
        // console.log('process_list ', process_list);
        let uniq_template_sentence = [];
        let uniq_anatomy_list = [];
        let uniq_template_list = [];
        let uniq_not_param_list = [];
        if (process_list.indexOf('template_sentence') !== -1) {
          this.d_sentenceAnalyze.template_sentence_list = [];
          for (let sentence_index in this.d_sentenceAnalyze.sentence_list) {
            let template_sentence_text = '';
            for (let word_index in this.d_sentenceAnalyze.sentence_list[sentence_index].word_list) {
              let word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index];
              if (word_data.type !== '' && word_data.type !== 'bracket') {
                if (template_sentence_text && word_data.type !== 'word_suffix') {
                  template_sentence_text += ' ';
                }
                template_sentence_text += word_data.word;

              }
            }
            if (uniq_template_sentence.indexOf(template_sentence_text) === -1) {
              uniq_template_sentence.push(template_sentence_text);
              let new_temp_sent = { 'label': template_sentence_text };
              this.d_sentenceAnalyze.template_sentence_list.push(new_temp_sent);
            }
          }
        }
        if (process_list.indexOf('anatomy') !== -1) {
          this.d_sentenceAnalyze.anatomy_list = [];
          for (let sentence_index in this.d_sentenceAnalyze.sentence_list) {
            let anatomy_word = '';
            // console.log('sentence_index ', sentence_index);
            for (let word_index in this.d_sentenceAnalyze.sentence_list[sentence_index].word_list) {
              // console.log('word_index ', word_index);
              let word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index];
              // console.log('word_data ', word_data);
              if (word_data.type === 'anatomy') {
                if (anatomy_word) {
                  anatomy_word += ' ';
                }
                anatomy_word += word_data.word;
                // console.log('anatomy_word : ', anatomy_word);
              } else if (word_data.type === 'word_suffix') {
                //
              } else {
                if (anatomy_word) {
                  // console.log('anatomy_word push : ', anatomy_word);
                  if (uniq_anatomy_list.indexOf(anatomy_word) === -1) {
                    uniq_anatomy_list.push(anatomy_word);
                    let new_anatomy = { 'label': anatomy_word.toLocaleLowerCase() };
                    this.d_sentenceAnalyze.anatomy_list.push(new_anatomy);
                    anatomy_word = '';
                  }
                }
              }
            }

            if (anatomy_word) {
              if (uniq_anatomy_list.indexOf(anatomy_word) === -1) {
                uniq_anatomy_list.push(anatomy_word);
                let new_anatomy = { 'label': anatomy_word.toLocaleLowerCase() };
                this.d_sentenceAnalyze.anatomy_list.push(new_anatomy);
                anatomy_word = '';
              }
            }
          }
        }
        if (process_list.indexOf('template') !== -1) {
          this.d_sentenceAnalyze.template_list = [];
          for (let sentence_index in this.d_sentenceAnalyze.sentence_list) {
            let temp = [];
            let wdm_param_text = '';
            let not_param_text = '';
            for (let word_index in this.d_sentenceAnalyze.sentence_list[sentence_index].word_list) {
              let word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index];
              if (this.printSomething) {
                console.log('word_index       : ', word_index);
                console.log('word_data.word   : ', word_data.word);
                console.log('word_data.type   : ', word_data.type);
              }
              if (word_data.type === 'anatomy') {
                not_param_text = '';
                wdm_param_text = '';
                let temp_data = { 'phrase': 'anatomy', 'type': 'param' };
                if (temp.length === 0) {
                  temp.push(temp_data)
                } else {
                  if (temp[temp.length - 1].phrase !== 'anatomy') {
                    temp.push(temp_data);
                  }
                }
              } else if (word_data.type === 'not_param') {
                wdm_param_text = '';
                if (not_param_text) {
                  not_param_text += ' ';
                }
                not_param_text += word_data.word;
              } else if (word_data.type === 'wdm_param') {
                not_param_text = '';
                if (wdm_param_text) {
                  wdm_param_text += ' ';
                }
                wdm_param_text += word_data.word;
                if (this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list && this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list.length > 0) {
                  for (let wdm_param_phrase_index in this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list) {
                    let wdm_param_data = this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list[wdm_param_phrase_index];
                    // if (wdm_param_data.label === wdm_param_text && wdm_param_data.param_data) {
                    if (wdm_param_data.word_index !== undefined && wdm_param_data.word_index !== '') {
                      if (parseInt(wdm_param_data.word_index) === parseInt(word_index) && wdm_param_data.param_data) {
                        if (this.printSomething) {
                          console.log('wdm_param_data.label : ', wdm_param_data.label);
                          console.log('wdm_param_text       : ', wdm_param_text);
                        }
                        let temp_data = {
                          'phrase': wdm_param_data.index_list.join('.'),
                          'type': 'param',
                          'param_data': { 'label': wdm_param_data.param_data.name.label, 'type': wdm_param_data.param_data.type }
                        };
                        temp.push(temp_data)
                      }
                    }
                  }
                }
              } else if (word_data.type === 'word_suffix') {
                //
              } else {
                not_param_text = '';
                wdm_param_text = '';
              }
              // console.log('not_param_text ', not_param_text);
              // console.log("JSON.stringify(temp) ", JSON.stringify(temp));
              if (not_param_text) {
                if (uniq_not_param_list.indexOf(not_param_text) === -1) {
                  uniq_not_param_list.push(not_param_text);
                  let not_param_text_data = { 'label': not_param_text };
                  this.d_sentenceAnalyze.not_param_text_list.push(not_param_text_data);
                }
                /*
                  not_param_text = not_param_text.replace(/\./g, '');
                  not_param_text = not_param_text.replace(/,/g, '');
                  not_param_text = not_param_text.replace(/;/g, '');
                  not_param_text = not_param_text.replace(/:/g, '');
                  not_param_text = not_param_text.replace(/\'/g, '');
                  not_param_text = not_param_text.replace(/"/g, '');
                  not_param_text = not_param_text.replace(/[\s]+/g, " ");
                */

                let temp_data = { 'phrase': not_param_text, 'type': 'not_param' };
                if (temp.length === 0) {
                  // console.log('if 111 ', not_param_text);
                  // console.log('temp.length === 0 : ', not_param_text);
                  temp.push(temp_data)
                } else {
                  if (temp[temp.length - 1].type === 'not_param') {
                    // console.log('if 222 ', not_param_text);
                    // console.log('temp[temp.length - 1].phrase === "not_param" ', not_param_text);
                    temp[temp.length - 1].phrase = not_param_text;
                  } else {
                    // console.log('else 333 ', not_param_text);
                    temp.push(temp_data);
                  }
                }
                /*
                  if (parseInt(word_index) === this.d_sentenceAnalyze.sentence_list[sentence_index].word_list.length - 1) {
                    temp.push(temp_data)
                  } else {
                    if (this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[parseInt(word_index) + 1].type !== 'not_param') {
                      let temp_data = { 'phrase': not_param_text, 'type': 'not_param' };
                      temp.push(temp_data)
                    }
                  }
                */
              }
            }
            // console.log("temp : ", temp);
            if (temp.length > 1) {
              if (uniq_template_list.indexOf(JSON.stringify(temp)) === -1) {
                uniq_template_list.push(JSON.stringify(temp));
                let eligible_for_temp = false;
                let wdm_param = 0;
                let ana = 0;
                let not_param = 0;
                for (let phr_index in temp) {
                  if (temp[phr_index].type === 'param') {
                    if (temp[phr_index].phrase === 'anatomy') {
                      ana += 1;
                    } else {
                      wdm_param += 1;
                    }
                  } else {
                    not_param += 1;
                  }
                }
                if (wdm_param > 0) {
                  this.d_sentenceAnalyze.template_list.push(temp);
                }
              }
            }
          }
        }
      } else {
        alert('Tüm kelime ya da kelime eklerine ait seçimleri, akıllı model seçimini, wdm parametresi olan parametre tipleri için parametre seçimini lütfen tamamlayınız.');
      }
    },
    f_prepareWords: function (type = '') {
      //
      let prepare_words = false;
      if (this.d_sentenceAnalyze.sentence_list.length === 0) {
        prepare_words = true;
      } else {
        if (confirm('Cümleler temizlenerek yeniden oluşturulacaktır. Emin misiniz ?')) {
          prepare_words = true;
        }
      }
      if (prepare_words) {
        this.d_sentenceAnalyze.sentence_list = [];
        let div_el = document.getElementById('sentence_analyze_test');
        if (div_el) {
          let inner_text = div_el.innerText;
          if (this.printSomething) {
            console.log('inner_text : ', inner_text);
          }
          let sentence_splitted = inner_text.split('\n');
          if (this.printSomething) {
            console.log('sentence_splitted : ', sentence_splitted);
          }
          if (type === 'labeled') {
            let s_list = [];
            // this.d_labelList;
            for (let sentence_index in sentence_splitted) {
              let is_success = true;
              let real_sent = sentence_splitted[sentence_index];
              // sent = "@a Her iki aksilla @s da @p.sign_name lezyon @g gözlenmiştir. Hastanın @p.recommendation sonografik tetkiki @g gereklidir.";
              s_list.push({ 'real': real_sent });
              let label_first_splitted = real_sent.split('@');
              let sent = real_sent.replace(/\./g, ' . ');
              sent = sent.replace(/,/g, ' , ');
              sent = sent.replace(/;/g, ' ; ');
              sent = sent.replace(/:/g, ' : ');
              sent = sent.replace(/\'/g, ' \' ');
              sent = sent.replace(/"/g, ' " ');
              sent = sent.replace(/[\s]+/g, " ");
              let new_row_data = { 'word_list': [], 'sentence': sent, 'wdm_param_phrase_list': [] };
              if (this.printSomething) {
                console.log('label_first_splitted : ', label_first_splitted);
              }
              for (let fsi in label_first_splitted) {
                let f_s_phrase = label_first_splitted[fsi];
                // f_s_phrase = "a Her iki aksilla";
                // f_s_phrase = "s da ";
                // f_s_phrase = "p.sign_name lezyon ";
                // f_s_phrase = "g gözlenmiştir. Hastanın ";
                // f_s_phrase = "p.recommendation sonografik tetkiki ";
                // f_s_phrase = "g gereklidir.";
                if (this.printSomething) {
                  console.log('f_s_phrase : ', f_s_phrase);
                }

                let regx_ANATOMY_label = /^a\s/i;
                let f_s_phrase_regx_ANATOMY_test = false;

                let regx_GENERAL_label = /^g\s/i;
                let f_s_phrase_regx_GENERAL_test = false;

                let regx_PARAMETER_label = /^p\.[a-z0-9A-Z_]+\s/i;
                let f_s_phrase_regx_PARAMETER_test = false;

                let regx_SUFFIX_label = /^s\s/i;
                let f_s_phrase_regx_SUFFIX_test = false;

                if (f_s_phrase) {
                  f_s_phrase_regx_ANATOMY_test = regx_ANATOMY_label.test(f_s_phrase);
                  if (this.printSomething) {
                    console.log('f_s_phrase_regx_ANATOMY_test : ', f_s_phrase_regx_ANATOMY_test);
                  }

                  f_s_phrase_regx_GENERAL_test = regx_GENERAL_label.test(f_s_phrase);
                  if (this.printSomething) {
                    console.log('f_s_phrase_regx_GENERAL_test : ', f_s_phrase_regx_GENERAL_test);
                  }

                  f_s_phrase_regx_PARAMETER_test = regx_PARAMETER_label.test(f_s_phrase);
                  if (this.printSomething) {
                    console.log('f_s_phrase_regx_PARAMETER_test : ', f_s_phrase_regx_PARAMETER_test);
                  }

                  f_s_phrase_regx_SUFFIX_test = regx_SUFFIX_label.test(f_s_phrase);
                  if (this.printSomething) {
                    console.log('f_s_phrase_regx_SUFFIX_test : ', f_s_phrase_regx_SUFFIX_test);
                  }
                  if (f_s_phrase_regx_ANATOMY_test || f_s_phrase_regx_GENERAL_test || f_s_phrase_regx_PARAMETER_test || f_s_phrase_regx_SUFFIX_test) {
                    if (f_s_phrase_regx_ANATOMY_test) {
                      // f_s_phrase = "a Her iki aksilla";
                      let label_result = f_s_phrase.replace(regx_ANATOMY_label, '');
                      if (this.printSomething) {
                        console.log('ANATOMY label_result : ', label_result);
                      }
                      label_result = label_result.trimLeft();
                      label_result = label_result.trimRight();
                      label_result = label_result.replace(/\./g, ' . ');
                      label_result = label_result.replace(/,/g, ' , ');
                      label_result = label_result.replace(/;/g, ' ; ');
                      label_result = label_result.replace(/:/g, ' : ');
                      label_result = label_result.replace(/\'/g, ' \' ');
                      label_result = label_result.replace(/"/g, ' " ');
                      label_result = label_result.replace(/[\s]+/g, " ");
                      // let x = sentence_splitted[sentence_index].replace(/[\s]+/g, " ");
                      if (label_result) {
                        let phrase_list = label_result.split(' ');
                        for (let phr_ind in phrase_list) {
                          let phr = phrase_list[phr_ind].replace(/[\s]+/g, "");
                          if (phr) {
                            let word_data = { 'word': phrase_list[phr_ind], 'type': 'anatomy' };
                            new_row_data.word_list.push(word_data);
                          }
                        }
                      }
                    } else if (f_s_phrase_regx_GENERAL_test) {
                      let label_result = f_s_phrase.replace(regx_GENERAL_label, '');
                      if (this.printSomething) {
                        console.log('GENERAL label_result : ', label_result);
                      }
                      label_result = label_result.trimLeft();
                      label_result = label_result.trimRight();
                      label_result = label_result.replace(/\./g, ' . ');
                      label_result = label_result.replace(/,/g, ' , ');
                      label_result = label_result.replace(/;/g, ' ; ');
                      label_result = label_result.replace(/:/g, ' : ');
                      label_result = label_result.replace(/\'/g, ' \' ');
                      label_result = label_result.replace(/"/g, ' " ');
                      label_result = label_result.replace(/[\s]+/g, " ");
                      // let x = sentence_splitted[sentence_index].replace(/[\s]+/g, " ");
                      if (label_result) {
                        let phrase_list = label_result.split(' ');
                        for (let phr_ind in phrase_list) {
                          let phr = phrase_list[phr_ind].replace(/[\s]+/g, "");
                          if (phr) {
                            let word_data = { 'word': phrase_list[phr_ind], 'type': 'not_param' };
                            new_row_data.word_list.push(word_data);
                          }
                        }
                      }
                    } else if (f_s_phrase_regx_PARAMETER_test) {
                      let match_result_PARAMETER = f_s_phrase.match(regx_PARAMETER_label);
                      if (this.printSomething) {
                        console.log('match_result_PARAMETER : ', match_result_PARAMETER);
                      }
                      let parameter_value = match_result_PARAMETER[0].replace(/^p\./i, '').replace(/\s/g, "");
                      // console.log('parameter_value : ', parameter_value);
                      let res = ClsWdm.getParamDataByParameterValue(this.option_data[this.data_type], parameter_value);
                      // console.log(res);
                      let param_data = res.param_data;
                      let index_list = res.index_list;
                      if (param_data) {
                        // console.log('param_data ', param_data);
                        let label_result = f_s_phrase.replace(regx_PARAMETER_label, '');
                        // console.log('PARAMETER label_result : ', label_result);
                        label_result = label_result.trimLeft();
                        label_result = label_result.trimRight();
                        label_result = label_result.replace(/\./g, ' . ');
                        label_result = label_result.replace(/,/g, ' , ');
                        label_result = label_result.replace(/;/g, ' ; ');
                        label_result = label_result.replace(/:/g, ' : ');
                        label_result = label_result.replace(/\'/g, ' \' ');
                        label_result = label_result.replace(/"/g, ' " ');
                        label_result = label_result.replace(/[\s]+/g, " ");
                        // let x = sentence_splitted[sentence_index].replace(/[\s]+/g, " ");
                        if (label_result) {
                          new_row_data.wdm_param_phrase_list.push({ 'index_list': index_list, 'label': label_result, 'param_data': param_data, 'word_index': '' });
                          let phrase_list = label_result.split(' ');
                          for (let phr_ind in phrase_list) {
                            let phr = phrase_list[phr_ind].replace(/[\s]+/g, "");
                            if (phr) {
                              let word_data = { 'word': phrase_list[phr_ind], 'type': 'wdm_param' };
                              new_row_data.word_list.push(word_data);
                              new_row_data.wdm_param_phrase_list[new_row_data.wdm_param_phrase_list.length - 1].word_index = new_row_data.word_list.length - 1;
                            }
                          }
                          if (this.printSomething) {
                            console.log('Last Word Index For This Param Data : ', new_row_data.wdm_param_phrase_list[new_row_data.wdm_param_phrase_list.length - 1].word_index);
                          }
                        }
                      } else {
                        is_success = false;
                      }
                    } else if (f_s_phrase_regx_SUFFIX_test) {
                      let label_result = f_s_phrase.replace(regx_SUFFIX_label, '');
                      if (this.printSomething) {
                        console.log('SUFFIX label_result : ', label_result);
                      }
                      label_result = label_result.trimLeft();
                      label_result = label_result.trimRight();
                      label_result = label_result.replace(/\./g, ' . ');
                      label_result = label_result.replace(/,/g, ' , ');
                      label_result = label_result.replace(/;/g, ' ; ');
                      label_result = label_result.replace(/:/g, ' : ');
                      label_result = label_result.replace(/\'/g, ' \' ');
                      label_result = label_result.replace(/"/g, ' " ');
                      label_result = label_result.replace(/[\s]+/g, " ");
                      // let x = sentence_splitted[sentence_index].replace(/[\s]+/g, " ");
                      if (label_result) {
                        let phrase_list = label_result.split(' ');
                        for (let phr_ind in phrase_list) {
                          let phr = phrase_list[phr_ind].replace(/[\s]+/g, "");
                          if (phr) {
                            let word_data = { 'word': phrase_list[phr_ind], 'type': 'word_suffix' };
                            new_row_data.word_list.push(word_data);
                          }
                        }
                      }
                    }
                  } else {
                    is_success = false;
                    break;
                  }
                }
              }
              if (this.printSomething) {
                console.log("is_success   : ", is_success);
                console.log("JSON.stringify(new_row_data) : ", JSON.stringify(new_row_data));
              }
              if (is_success) {
                this.d_sentenceAnalyze.sentence_list.push(new_row_data);
              }
            }
            if (this.d_sentenceAnalyze.sentence_list.length > 0) {
              this.f_sentenceAnalyzePrepare(['anatomy', 'wdm_param', 'template_sentence', 'template']);
            }
          } else {
            for (let sentence_index in sentence_splitted) {
              let sent = sentence_splitted[sentence_index];
              sent = sent.replace(/\./g, ' . ');
              sent = sent.replace(/,/g, ' , ');
              sent = sent.replace(/;/g, ' ; ');
              sent = sent.replace(/:/g, ' : ');
              sent = sent.replace(/\'/g, ' \' ');
              sent = sent.replace(/"/g, ' " ');
              sent = sent.replace(/[\s]+/g, " ");
              // let x = sentence_splitted[sentence_index].replace(/[\s]+/g, " ");
              if (sent) {
                this.d_sentenceAnalyze.sentence_list.push({ 'word_list': [], 'sentence': sent });
                let phrase_list = sent.split(' ');
                for (let phr_ind in phrase_list) {
                  let phr = phrase_list[phr_ind].replace(/[\s]+/g, "");
                  if (phr) {
                    let word_type = '';
                    if ([',', ';', ':', '.', '\'', '"'].indexOf(phr) !== -1) {
                      word_type = 'word_suffix';
                    }
                    let word_data = { 'word': phrase_list[phr_ind], 'type': word_type };
                    let last_sentence_index = this.d_sentenceAnalyze.sentence_list.length - 1;
                    this.d_sentenceAnalyze.sentence_list[last_sentence_index].word_list.push(word_data);
                  }
                }
              }
            }
          }
        }
        this.$forceUpdate();
      }
    },
    f_selectWord: function (sentence_index, word_index) {
      if (this.d_sentenceAnalyze.word_type === 'delete') {
        this.d_sentenceAnalyze.sentence_list[sentence_index].word_list.splice(word_index, 1);
        if (this.d_sentenceAnalyze.sentence_list[sentence_index].word_list.length === 0) {
          this.d_sentenceAnalyze.sentence_list.splice(sentence_index, 1);
        }
      } else if (this.d_sentenceAnalyze.word_type === 'edit') {
        this.d_sentenceAnalyze.edit_text = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index].word;
        this.d_sentenceAnalyze.edit_sentence_index = sentence_index;
        this.d_sentenceAnalyze.edit_word_index = word_index;
        this.d_sentenceAnalyze.edit_show = true;
      } else {
        if (this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index].type === 'bracket') {
          //
        } else {
          if (this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index].type === this.d_sentenceAnalyze.word_type) {
            this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index].type = '';
          } else {
            this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index].type = this.d_sentenceAnalyze.word_type;
          }
        }
        if (this.d_sentenceAnalyze.word_type === 'anatomy') {
          let sentence_anatomy_count = this.f_controlSentenceAnatomyCount(sentence_index);
          // console.log("sentence_anatomy_count : ", sentence_anatomy_count);
          if (sentence_anatomy_count > 1) {
            this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index].type = '';
            alert('Bir cümlede maksimum bir adet anatomik bölge seçimi yapınız.');
          }
        }
      }
      this.f_updateWdmParamPhraseList(sentence_index);
    },
    f_updateWdmParamPhraseList: function (sentence_index) {
      this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list = [];
      // let uniq_wdm_param_phrase_list = [];
      let wdm_param_phrase_text = '';
      for (let word_index in this.d_sentenceAnalyze.sentence_list[sentence_index].word_list) {
        let use_wdm_param_phrase_text = false;
        let word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[word_index];
        if (word_data.type === 'wdm_param') {
          if (wdm_param_phrase_text) {
            wdm_param_phrase_text += ' ';
          }
          wdm_param_phrase_text += word_data.word;
          if (parseInt(word_index) === this.d_sentenceAnalyze.sentence_list[sentence_index].word_list.length - 1) {
            use_wdm_param_phrase_text = true;
          } else {
            let next_word_data = this.d_sentenceAnalyze.sentence_list[sentence_index].word_list[parseInt(word_index) + 1];
            if (next_word_data.type !== 'wdm_param') {
              use_wdm_param_phrase_text = true;
            }
          }
        } else if (word_data.type === 'word_suffix') {
          //
        } else {
          //
        }
        if (use_wdm_param_phrase_text) {
          if (this.printSomething) {
            console.log('use_wdm_param_phrase_text => word_index : ', word_index);
          }
          let new_wdm_param_phrase = { 'label': wdm_param_phrase_text, 'word_index': word_index };
          this.d_sentenceAnalyze.sentence_list[sentence_index].wdm_param_phrase_list.push(new_wdm_param_phrase);
          wdm_param_phrase_text = '';
        }
      }
      this.$forceUpdate();
    },
    f_selectWordType: function (word_type) {
      this.d_sentenceAnalyze.word_type = word_type;
      this.$forceUpdate();
    },
    f_calculateWordTypeStyle: function (word_type) {
      let style = 'text-align: center; padding: 1px; margin: 5px; cursor: pointer; border: solid 1px black; float: left; min-width: 50px; font-size: 10px;';
      if (word_type === this.d_sentenceAnalyze.word_type) {
        if (word_type === 'anatomy') {
          style += 'background-color: #e4e084;';
        } else if (word_type === 'wdm_param') {
          style += 'background-color: #c0f3d1;';
        } else if (word_type === 'not_param') {
          style += 'background-color: #acf5fb;';
        } else if (word_type === 'word_suffix') {
          style += 'background-color: #C400FF;';
        } else if (word_type === 'bracket') {
          style += 'background-color: #ff00b6;';
        } else if (word_type === 'delete') {
          style += 'background-color: red;';
        } else if (word_type === 'edit') {
          style += 'background-color: #aef4fd;';
        } else {
          style += 'background-color: #FBD6D5;';
        }
      }
      return style;
    },
    f_calculateWordStyle: function (word_data) {
      let style = 'text-align: center; padding: 1px; margin: 1px; min-height: 20px; float: left; cursor: pointer; border: solid 1px #dcd5d5;';
      if (word_data.type === 'anatomy') {
        style += 'background-color: #e4e084;min-width: 40px;';
      } else if (word_data.type === 'wdm_param') {
        style += 'background-color: #c0f3d1;min-width: 40px;';
      } else if (word_data.type === 'not_param') {
        style += 'background-color: #acf5fb;min-width: 40px;';
      } else if (word_data.type === 'word_suffix') {
        style += 'background-color: #C400FF;min-width: 40px;';
      } else if (word_data.type === 'bracket') {
        style += 'background-color: #ff00b6;min-width: 30px;';
      } else {
        style += 'background-color: #FBD6D5;min-width: 40px;';
      }
      return style;
    },
    f_sentenceAnalyzeTest: function () {
      let div_el = document.getElementById('sentence_analyze_test');
      let html_text = div_el.innerHTML;
      if (html_text !== '' && ['<br>'].indexOf(html_text) === -1) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
            'text': html_text
          }
          // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema kalıp analizi ve kayıt işlemi yapılıyor. Lütfen bekleyiniz.' } });
        this.d_analyzeSentenceList.list = [];
        WmanagerService.wdm_sentence_template_analyze(query, data)
          .then(resp => {
            this.d_analyzeSentenceList.analyzing = false;
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000') {
              if (resp.data.sentence_list && resp.data.sentence_list.length > 0) {
                this.d_sentenceAnalyze.analyze_list = resp.data.sentence_list;
              }
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      }
    },
    f_prepareAnalyzeSentenceList: function (html_text = '') {
      if (!html_text) {
        let div_el = document.getElementById('wdmr_sentence_template');
        if (div_el && div_el.innerHTML) {
          html_text = div_el.innerHTML;
          html_text = html_text.replace(/<p(.*?)>/g, "<div>");
          html_text = html_text.replace(/<\/p>/g, "<\/div>");
          // div_el.innerHTML = html_text;
        }
      }
      /*
        if (!html_text) {
          html_text = this.f_prepareHtmlSentenceText();
        }
      */
      if (html_text !== '' && ['<br>'].indexOf(html_text) === -1 && this.d_timerControlWdmrSentenceTemplateUpdate.last_analyzed !== html_text) {
        this.d_timerControlWdmrSentenceTemplateUpdate.last_analyzed = html_text;
        this.d_timerControlWdmrSentenceTemplateUpdate.status = 'active';
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
            'text': html_text,
            'analyzed_list': this.d_analyzeSentenceList.list
          }
          // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema kalıp analizi ve kayıt işlemi yapılıyor. Lütfen bekleyiniz.' } });
        this.d_analyzeSentenceList.list = [];
        this.d_analyzeSentenceList.analyzing = true;
        let vm = this;
        WmanagerService.wdm_sentence_template_analyze(query, data)
          .then(resp => {
            this.d_analyzeSentenceList.analyzing = false;
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000') {
              if (resp.data.sentence_list && resp.data.sentence_list.length > 0) {
                this.d_analyzeSentenceList.list = resp.data.sentence_list;
                let full_match_list_count = 0;
                let total_eligible_len = 0;
                for (let i in this.d_analyzeSentenceList.list) {
                  if (this.d_analyzeSentenceList.list[i].text.replace(/[\s]+/g, "")) {
                    total_eligible_len += 1;
                  }
                  if (this.d_analyzeSentenceList.list[i].full_match_list && this.d_analyzeSentenceList.list[i].full_match_list.length > 0) {
                    full_match_list_count += 1;
                  }
                }
                this.d_analyzeSentenceList.sentence_accuracy = ((full_match_list_count / total_eligible_len) * 100).toFixed(0);
                this.d_analyzeSentenceList.sentence_accuracy = parseInt(this.d_analyzeSentenceList.sentence_accuracy);
              }
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
            this.d_timerControlWdmrSentenceTemplateUpdate.status = 'passive';
          }, resp => {
            let msg = 'Hata gelişti. Lütfen yeniden deneyiniz.';
            alert(msg);
            this.d_analyzeSentenceList.analyzing = false;
          });
      }
    },
    f_openSentenceAnalyzeModal: function (type = '', this_text = '') {
      this.d_operationsModal.show = false;
      this.d_sentenceAnalyze.new_phrase_list = 'no';
      this.d_sentenceAnalyze.new_phrase_list_general = 'no';
      this.d_sentenceAnalyze.new_phrase_list_wdm_parameter = 'no';
      this.d_sentenceAnalyze.new_phrase_list_anatomy = 'no';
      this.d_sentenceAnalyze.not_param_text_list = [];
      this.d_sentenceAnalyze.template_sentence_list = [];
      this.d_sentenceAnalyze.selected_template_sentence_list_index = '';
      this.d_sentenceAnalyze.edit_text = '';
      this.d_sentenceAnalyze.edit_sentence_index = '';
      this.d_sentenceAnalyze.edit_word_index = '';
      this.d_sentenceAnalyze.edit_show = false;
      this.d_sentenceAnalyze.word_type = '';
      this.d_sentenceAnalyze.selected_anatomy_index = '';
      this.d_sentenceAnalyze.anatomy_list = [];
      this.d_sentenceAnalyze.selected_words = [];
      this.d_sentenceAnalyze.sentence_list = [];
      this.d_sentenceAnalyze.analyze_list = [];
      this.d_sentenceAnalyze.show = true;
      setTimeout(function () {
        if (type === 'report') {
          let div_el = document.getElementById('sentence_analyze_test');
          if (div_el && this.d_analyzeSentenceList.list.length > 0) {
            for (let i in this.d_analyzeSentenceList.list) {
              if (!this.d_analyzeSentenceList.list[i].full_match_list && this.d_analyzeSentenceList.list[i].text) {
                if (div_el) {
                  div_el.innerHTML += '<div>' + this.d_analyzeSentenceList.list[i].text + '</div>';
                }
              }
            }
          }
        } else if (type === 'schema') {
          let div_el = document.getElementById('sentence_analyze_test');
          if (div_el) {
            div_el.innerHTML = this.d_schemaListModal.selected_schema.data.general.schema_text.val;
          }
        } else {
          if (this_text) {
            let div_el = document.getElementById('sentence_analyze_test');
            if (div_el) {
              div_el.innerHTML = this_text;
            }
          }
        }
        this.f_prepareWords();
      }.bind(this), 500)

    },
    f_initHtmlSentenceWdmrSentenceTemplate: function (div_element_id, html_txt = '', type = 'equal', create_paste_event = false, wait = true) {
      if (wait) {
        setTimeout(function () {
          let div_el = document.getElementById(div_element_id);
          if (div_el) {
            document.execCommand("defaultParagraphSeparator", false, "div");
            if (create_paste_event) {
              this.f_createPasteEvent(div_el);
            }
            if (type === 'equal') {
              div_el.innerHTML = html_txt;
            } else if (type === 'plus_add') {
              div_el.innerHTML += html_txt;
            }
            div_el.focus();
            this.$forceUpdate();
          }
        }.bind(this), 50)
      } else {
        let div_el = document.getElementById(div_element_id);
        if (div_el) {
          document.execCommand("defaultParagraphSeparator", false, "div");
          if (create_paste_event) {
            this.f_createPasteEvent(div_el);
          }
          if (type === 'equal') {
            div_el.innerHTML = html_txt;
          } else if (type === 'plus_add') {
            div_el.innerHTML += html_txt;
          }
          div_el.focus();
          this.$forceUpdate();
        }
      }
    },
    f_createPasteEvent: function (div_el) {
      div_el.addEventListener('paste', (event) => {
        let before_paste = div_el.innerHTML;
        // console.log('before_paste : ', before_paste);
        let paste = (event.clipboardData || window.clipboardData).getData('text');
        // console.log('paste :', paste);
        let splitted_paste = paste.split('\r\n');
        // console.log('splitted_paste :', splitted_paste);
        let added_text = '';
        const selection = window.getSelection();
        // console.log(selection);
        selection.deleteFromDocument();
        if (!selection.rangeCount) return false;
        if (splitted_paste.length > 1) {
          let i = 0;
          for (let i = splitted_paste.length - 1; i >= 0; i--) {
            if (splitted_paste[i]) {
              // added_text += '<div>' + splitted_paste[i] + '</div>';
              // this.f_insertTextAtCursor(paste);
              let div_sentence = document.createElement("div");
              let text_node = document.createTextNode(splitted_paste[i]);
              div_sentence.appendChild(text_node);
              selection.getRangeAt(0).insertNode(div_sentence);
              // added_text += splitted_paste[i]
            }
          }
        } else if (splitted_paste.length === 1) {
          let text_node = document.createTextNode(splitted_paste[0]);
          selection.getRangeAt(0).insertNode(text_node);
        }
        event.preventDefault();
        // paste = added_text;
        // this.f_insertTextAtCursor(paste);
        /*
          paste = paste.replace(/<span[^>]*>/g, " ");
          paste = paste.replace(/<\/span>/g, " ");
          paste = paste.replace(/<u[^>]*>/g, " ");
          paste = paste.replace(/<\/u>/g, " ");
          paste = paste.replace(/<p(.*?)>/g, "<div>");
          paste = paste.replace(/<\/p>/g, "<\/div>");
          paste = paste.replace(/<o:p>/g, " ");
          paste = paste.replace(/<\/o:p>/g, " ");
          paste = paste.replace(/class=\"(.*?)\"/g, " ");
          paste = paste.replace(/style=\"(.*?)\"/g, " ");
          paste = paste.replace(/\r/g, " ");
          paste = paste.replace(/\n/g, " ");
          paste = paste.replace(/&nbsp;/g, " ");
          paste = paste.replace(/[\s]+/g, " ");
          paste = paste.replace(/\s\./g, ".");
        */
        // console.log('paste : ', paste);
        /*
        const selection = window.getSelection();
        if (!selection.rangeCount) return false;
        selection.deleteFromDocument();
        // this.f_insertTextAtCursor(paste);
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        event.preventDefault();
        */
        /*
          setTimeout(function () {
            div_el.innerHTML = before_paste + added_text;
          }.bind(this), 100)
        */
        // div_el.focus();
      });
    },
    formatDocSelect: function (div_element_id, sCmd) {
      let sValue = this.d_textOptions[sCmd];
      document.execCommand(sCmd, false, sValue);
      let oDoc = document.getElementById(div_element_id);
      oDoc.focus();
    },
    formatDoc: function (div_element_id, sCmd, sValue) {
      document.execCommand(sCmd, false, sValue);
      let oDoc = document.getElementById(div_element_id);
      oDoc.focus();
    },
    setDocMode: function (div_element_id) {
      let oDoc = document.getElementById(div_element_id);
      let bToSource = true;
      var oContent;
      if (bToSource) {
        oContent = document.createTextNode(oDoc.innerHTML);
        oDoc.innerHTML = "";
        var oPre = document.createElement("pre");
        oDoc.contentEditable = false;
        oPre.id = "sourceText";
        oPre.contentEditable = true;
        oPre.appendChild(oContent);
        oDoc.appendChild(oPre);
        document.execCommand("defaultParagraphSeparator", false, "div");
      } else {
        if (document.all) {
          oDoc.innerHTML = oDoc.innerText;
        } else {
          oContent = document.createRange();
          oContent.selectNodeContents(oDoc.firstChild);
          oDoc.innerHTML = oContent.toString();
        }
        oDoc.contentEditable = true;
      }
      oDoc.focus();
    },
    f_calculateHtmlEditorStyle: function () {
      let style = "overflow-x: hidden; overflow-y: auto; color: black; cursor:text; border: solid 1px #20a2ce; padding: 3px; width: 100%;";
      style += 'min-height:' + this.d_htmlEditorSettings.height.toString() + 'px;';
      style += 'background-color:' + this.d_htmlEditorSettings.backgroundColor + ';';
      style += 'font-size:' + this.d_htmlEditorSettings.fontSize + 'px;';
      style += 'font-family:' + this.d_htmlEditorSettings.fontFamily + ';';
      return style;
    },
    printDoc: function (div_element_id) {
      let oDoc = document.getElementById(div_element_id);
      var oPrntWin = window.open("", "_blank", "width=450,height=470,left=400,top=100,menubar=yes,toolbar=no,location=no,scrollbars=yes");
      oPrntWin.document.open();
      oPrntWin.document.write("<!doctype html><html><head><title>Print<\/title><\/head><body onload=\"print();\">" + oDoc.innerHTML + "<\/body><\/html>");
      oPrntWin.document.close();
    },
    f_filterSmartModel: function (smart_search) {
      if (this.d_smartSearchModel.search_text) {
        if (smart_search.name.toLocaleLowerCase().indexOf(this.d_smartSearchModel.search_text.toLocaleLowerCase()) !== -1) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    },
    f_updatePhraseText: function () {
      let splitted_text = this.d_editorTextPhraseOperation.all_text.split(' ');
      if (this.d_editorTextPhraseOperation.txt) {
        splitted_text.splice(this.d_editorTextPhraseOperation.splitted_text_index, 1, this.d_editorTextPhraseOperation.txt);
      } else {
        splitted_text.splice(this.d_editorTextPhraseOperation.splitted_text_index, 1);
      }
      this.d_analyzeSentenceList.list[this.d_editorTextPhraseOperation.analyze_sen_ind].text = splitted_text.join(' ');
      // this.f_makeSentenceUpdate();
      this.$forceUpdate();
    },
    f_openPhraseTextEditModal: function (analyze_sen_ind, all_text, txt, splitted_text_index) {
      this.d_editorTextPhraseOperation.all_text = all_text;
      this.d_editorTextPhraseOperation.txt = txt;
      this.d_editorTextPhraseOperation.splitted_text_index = splitted_text_index;
      this.d_editorTextPhraseOperation.analyze_sen_ind = analyze_sen_ind;
      this.d_editorTextPhraseOperation.show = true;
    },
    f_calculateUnderstandRowStyle: function (found_index) {
      if (this.d_smartSearchModel.arrow_index === found_index) {
        return 'background-color: gray!important; width: 100%;';
      } else {
        return 'width: 100%;';
      }
    },
    f_openRowOperationsModal: function (analyze_sen_ind) {
      this.d_editorTextRow.analyze_sen_ind = analyze_sen_ind;
      this.d_editorTextRow.show = true;
    },
    f_calculateSentenceTemplatePhraseStyle: function (sen_temp) {
      let style = 'padding: 1px; margin: 1px; min-width: 10px; float: left; cursor: pointer;';
      // style += 'font-size:' + this.d_htmlEditorSettings.fontSize.toString() + 'px;';
      style += 'font-size: 10px;';
      if (sen_temp.type === 'param') {
        if (sen_temp.phrase === 'anatomy') {
          style += 'background-color: #e4e084;';
        } else {
          style += 'background-color: #c0f3d1;';
        }
      } else {
        style += 'background-color: #acf5fb;';
      }
      return style;
    },
    f_changeEditorFontSize: function (type) {
      if (type === 'up') {
        this.d_htmlEditorSettings.fontSize += 1;
      } else if (type === 'down') {
        this.d_htmlEditorSettings.fontSize -= 1;
      }
    },
    f_calculateRowNumberStyle: function (additional_style) {
      let style = "";
      style += 'font-size:' + this.d_htmlEditorSettings.fontSize + 'px;';
      style += additional_style
      return style;
    },
    f_addFoundTextToCursorPoint: function (found_index) {
      /*
        let div_el_wdmr_sentence_template = document.getElementById('wdmr_sentence_template');
        div_el_wdmr_sentence_template.focus();
      let div_el_wdmr_sentence_template = document.getElementById('wdmr_sentence_template');
      div_el_wdmr_sentence_template.focus();
      console.log('found_index ', found_index);
      let remaining_text = this.d_smartSearchModel.found_list[found_index].remaining_text;
      console.log('remaining_text ', remaining_text);
      if (remaining_text) {
        setTimeout(function () {
          this.f_insertTextAtCursor(remaining_text);
          this.d_smartSearchModel.found_list = [];
          // console.log("div_el_wdmr_sentence_template.innerHTML", div_el_wdmr_sentence_template.innerHTML);
        }.bind(this), 50)
      }
      */
      let textNode = this.d_smartSearchModel.found_list[found_index].textNode;
      let sel = this.d_smartSearchModel.found_list[found_index].sel;
      let range = this.d_smartSearchModel.found_list[found_index].range;
      range.insertNode(textNode);
      range.setStartAfter(textNode);
      sel.removeAllRanges();
      sel.addRange(range);
      this.d_smartSearchModel.found_list = [];
      this.d_smartSearchModel.arrow_index = '';
      setTimeout(function () {
        let div_el_wdmr_sentence_template = document.getElementById('wdmr_sentence_template');
        div_el_wdmr_sentence_template.focus();
      }.bind(this), 50)
      let context_menu_popup = document.getElementById('context-menu-popup');
      if (context_menu_popup) {
        context_menu_popup.style.left = '0px';
        context_menu_popup.style.top = '0px';
        context_menu_popup.style.display = 'none';
      }
    },
    f_insertTextAtCursor: function (text) {
      let sel, range;
      sel = window.getSelection();
      range = sel.getRangeAt(0);
      range.deleteContents();
      let textNode = document.createTextNode(text);
      range.insertNode(textNode);
      range.setStartAfter(textNode);
      sel.removeAllRanges();
      sel.addRange(range);
    },
    f_sentenceTemplateKeyDown: function (e) {
      let div_el_wdmr_sentence_template = document.getElementById('wdmr_sentence_template');
      // console.log('this.d_lastKey ', this.d_lastKey);
      // console.log('f_sentenceTemplateKeyDown e.key ', e.key);
      // let html_text = div_el_wdmr_sentence_template.innerHTML;
      // console.log('html_text ', html_text);
      // if (this.d_updateEditorHtml.before) {
      // if (this.d_updateEditorHtml.before.length < html_text.length) {
      // if (html_text.indexOf(this.d_updateEditorHtml.before) !== -1) {
      // let new_part = html_text.replace(this.d_updateEditorHtml.before, '');
      // console.log('New part')
      // console.log(new_part);
      // }
      // }
      // }
      /*
        let html_text = div_el_wdmr_sentence_template.innerHTML;
        let update_html_text = false;
        if (html_text.indexOf('<div><div>') !== -1) {
          html_text = html_text.replace('<div><div>', '<div>');
          update_html_text = true;
        }
        if (html_text.indexOf('</div></div>') !== -1) {
          html_text = html_text.replace('</div></div>', '</div>');
          update_html_text = true;
        }
        // console.log('update_html_text : ', update_html_text);
        if (update_html_text) {
          div_el_wdmr_sentence_template.innerHTML = html_text;
          div_el_wdmr_sentence_template.focus();
        }
      */
      if (e.key === 'Escape') {
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Enter') {
        // this.f_insertTextAtCaret(this.d_smartSearchModel.found_list[0]);
        if (this.d_smartSearchModel.found_list.length > 0) {
          let f_index = 0;
          if (this.d_smartSearchModel.arrow_index !== '') {
            f_index = this.d_smartSearchModel.arrow_index;
          }
          this.f_addFoundTextToCursorPoint(f_index);
        }
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Shift') {
        this.d_lastKey = 'Shift';
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Control') {
        this.d_lastKey = 'Control';
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Alt') {
        this.d_lastKey = 'Alt';
      } else if (e.key === 'Delete') {
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Home') {
        this.d_activeKeyDownText = '';
      } else if (e.key === 'End') {
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Tab') {
        // this.f_insertTextAtCaret(this.d_smartSearchModel.found_list[0]);
        if (this.d_smartSearchModel.found_list.length > 0) {
          let f_index = 0;
          if (this.d_smartSearchModel.arrow_index !== '') {
            f_index = this.d_smartSearchModel.arrow_index;
          }
          this.f_addFoundTextToCursorPoint(f_index);
        }
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Control') {
        this.d_activeKeyDownText = '';
      } else if (e.key === 'Backspace') {
        this.d_activeKeyDownText = this.d_activeKeyDownText.substring(0, this.d_activeKeyDownText.length - 1);
      } else if (['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'].indexOf(e.key) !== -1) {
        this.d_activeKeyDownText = '';
      } else if (['ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'].indexOf(e.key) !== -1) {
        this.d_activeKeyDownText = '';
        if (this.d_smartSearchModel.found_list.length > 0) {
          if (e.key === 'ArrowUp') {
            if (this.d_smartSearchModel.arrow_index === '') {
              this.d_smartSearchModel.arrow_index = this.d_smartSearchModel.found_list.length - 1;
            } else {
              if (this.d_smartSearchModel.arrow_index !== 0) {
                this.d_smartSearchModel.arrow_index -= 1;
              } else {
                this.d_smartSearchModel.arrow_index = this.d_smartSearchModel.found_list.length - 1;
              }
            }
          } else if (e.key === 'ArrowDown') {
            if (this.d_smartSearchModel.arrow_index === '') {
              this.d_smartSearchModel.arrow_index = 0;
            } else {
              if (this.d_smartSearchModel.arrow_index < this.d_smartSearchModel.found_list.length - 1) {
                this.d_smartSearchModel.arrow_index += 1;
              } else {
                this.d_smartSearchModel.arrow_index = 0;
              }
            }
          }
        }
      } else {
        // this.d_activeKeyDownText += e.key;
      }
      if (this.d_lastKey === 'Shift' && e.key === 'Tab') {
        console.log('Shift + Tab');
      }
      if (this.d_lastKey === 'Control' && e.key === 'v') {
        console.log('Control V');
        /*
          setTimeout(function () {
              let div_el_wdmr_sentence_template = document.getElementById('wdmr_sentence_template');
              let html_text = div_el_wdmr_sentence_template.innerHTML;
              html_text = html_text.replace(/<span[^>]*>/g, " ");
              html_text = html_text.replace(/<\/span>/g, " ");
              html_text = html_text.replace(/<u[^>]*>/g, " ");
              html_text = html_text.replace(/<\/u>/g, " ");
              html_text = html_text.replace(/<p(.*?)>/g, "<div>");
              html_text = html_text.replace(/<\/p>/g, "<\/div>");
              html_text = html_text.replace(/<o:p>/g, " ");
              html_text = html_text.replace(/<\/o:p>/g, " ");
              html_text = html_text.replace(/class=\"(.*?)\"/g, " ");
              html_text = html_text.replace(/style=\"(.*?)\"/g, " ");
              html_text = html_text.replace(/\r/g, " ");
              html_text = html_text.replace(/\n/g, " ");
              html_text = html_text.replace(/&nbsp;/g, " ");
              html_text = html_text.replace(/[\s]+/g, " ");
              html_text = html_text.replace(/\s\./g, ".");
              div_el_wdmr_sentence_template.innerHTML = html_text;
            }.bind(this), 500)
        */
        // this.formatDoc('wdmr_sentence_template', 'removeFormat');
      }
      /*
      // First fix some problematic situations !!!
      setTimeout(function () {
          let waited_div_el_wdmr_sentence_template = document.getElementById('wdmr_sentence_template');
          let waited_html_text = waited_div_el_wdmr_sentence_template.innerHTML;
          waited_div_el_wdmr_sentence_template.innerHTML = waited_html_text.replace('<p', '<div').replace('</p>', '</div>').replace('<br>', '').replace('</br>', '')
          console.log('waited_html_text ', waited_html_text);
        }.bind(this), 50)
      */
      /*
        We have turkisch character problem. Then we closed below code.
        let regx_alpha = /^[a-z0-9]+$/i;
        let regx_alpha_test = regx_alpha.test(e.key);
        console.log("regx_alpha_test ", regx_alpha_test);
      */
      /*
      ['ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp']
      */
      if (['Shift', 'Control', 'Escape', 'Shift'].indexOf(e.key) === -1) {
        this.f_searchWithSmartSearch();
      } else {
        this.d_smartSearchModel.found_list = [];
        let context_menu_popup = document.getElementById('context-menu-popup');
        this.d_activeKeyDownText = '';
        if (context_menu_popup) {
          context_menu_popup.style.left = '0px';
          context_menu_popup.style.top = '0px';
          context_menu_popup.style.display = 'none';
        }
        this.$forceUpdate();
      }
      // this.d_updateEditorHtml.before = html_text;
    },
    f_searchWithSmartSearch: function () {
      // We add timeout because when we try to do => sel = window.getSelection(); we can take the last char in the html editor.
      // Then we wait a time.
      setTimeout(function () {
        let before_finding = this.d_smartSearchModel.found_list.length;
        let search_text = '';
        //
        let div_el = document.getElementById('wdmr_sentence_template');
        if (div_el && div_el.innerHTML) {
          let inner_text = div_el.innerText;
          if (this.printSomething) {
            console.log('inner_text : ', inner_text);
          }
          let sentence_splitted = inner_text.split('\n');
          this.d_activeKeyDownText = sentence_splitted[sentence_splitted.length - 1];
          if (sentence_splitted.length > 0) {
            if (this.d_lastSentenceSplitted.length > 0) {
              for (let i in sentence_splitted) {
                if (parseInt(i) <= this.d_lastSentenceSplitted.length) {
                  if (this.d_lastSentenceSplitted[i] !== sentence_splitted[i]) {
                    this.d_activeKeyDownText = sentence_splitted[i];
                    break;
                  }
                }
              }
            } else {
              this.d_activeKeyDownText = sentence_splitted[sentence_splitted.length - 1];
            }
          } else {
            this.d_activeKeyDownText = '';
          }
          this.d_lastSentenceSplitted = sentence_splitted;
          if (this.printSomething) {
            console.log('sentence_splitted : ', sentence_splitted);
          }
        } else {
          this.d_activeKeyDownText = '';
        }
        //
        if (this.d_activeKeyDownText.replace(' ', '')) {
          /*
            if (this.d_smartSearchModel.found_list.length === 0 && this.d_activeKeyDownText.length > 1) {
              let x_list = this.d_activeKeyDownText.split(' ');
              search_text = x_list[x_list.length - 1];
            } else {
              search_text = this.d_activeKeyDownText;
            }
          */
          search_text = this.d_activeKeyDownText;
          this.d_smartSearchModel.found_list = [];
          if (this.d_smartSearchModel.details.length > 0) {
            for (let i in this.d_smartSearchModel.details) {
              // let starting_with_pattern = /^\s/i;
              // search_text = search_text.trimLeft();
              search_text = search_text.trimRight();
              // console.log("this.d_smartSearchModel.details[i].p ", this.d_smartSearchModel.details[i].p);
              // console.log("search_text ", search_text);
              // let regx_test = new RegExp('^' + search_text, 'i');
              // console.log('regx_test ', regx_test);
              // if (regx_test.test(this.d_smartSearchModel.details[i].p)) {
              /*
                We want search text to start from the beginning index of as 0.
              */
              // console.log(this.d_smartSearchModel.details[i].p.toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()));
              if (this.d_smartSearchModel.details[i].p.toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()) === 0) {
                let remaining_text = this.d_smartSearchModel.details[i].p.substring(search_text.length, this.d_smartSearchModel.details[i].p.length);
                // console.log('remaining_text ', remaining_text);
                let sel, range;
                sel = window.getSelection();
                range = sel.getRangeAt(0);
                range.deleteContents();
                let textNode = document.createTextNode(remaining_text);
                /*
                  range.insertNode(textNode);
                  range.setStartAfter(textNode);
                  sel.removeAllRanges();
                  sel.addRange(range);
                */
                this.d_smartSearchModel.found_list.push({
                  'all_text': this.d_smartSearchModel.details[i].p,
                  'remaining_text': remaining_text,
                  'textNode': textNode,
                  'range': range,
                  'sel': sel
                });
              }
            }
          } else {
            this.d_smartSearchModel.found_list = [];
          }
        } else {
          this.d_smartSearchModel.found_list = [];
        }
        let after_finding = this.d_smartSearchModel.found_list.length;
        // this.f_getCursorPosition();
        let caret_coordinates = this.getCaretCoordinates();
        // console.log('caret_coordinates ', caret_coordinates);
        let context_menu_popup = document.getElementById('context-menu-popup');
        // let position = this.F_getCaretIndex(div_el_wdmr_sentence_template);
        // console.log('position ', position);
        // let charCount = this.f_getCurrentCursorPosition('wdmr_sentence_template');
        // console.log('charCount ::::: ', charCount);
        /*
          console.log("caret_coordinates ", caret_coordinates);
          console.log("before_finding ", before_finding);
          console.log("after_finding ", after_finding);
        */
        if (after_finding === 0) {
          this.d_activeKeyDownText = '';
          context_menu_popup.style.left = '0px';
          context_menu_popup.style.top = '0px';
          context_menu_popup.style.display = 'none';
        } else {
          if (caret_coordinates.x !== 0 && caret_coordinates.y !== 0) {
            context_menu_popup.style.left = (caret_coordinates.x).toString() + 'px';
            context_menu_popup.style.top = (caret_coordinates.y + 10).toString() + 'px';
            context_menu_popup.style.display = '';
            context_menu_popup.focus();
          }
        }
        // console.log(context_menu_popup);
        this.$forceUpdate();
      }.bind(this), 50)
    },
    f_insertTextAtCaret: function (text) {
      let sel, range;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();
          range.insertNode(document.createTextNode(text));
        }
      } else if (document.selection && document.selection.createRange) {
        document.selection.createRange().text = text;
      }
    },
    f_getCursorPosition: function () {
      let sel = document.getSelection('wdmr_sentence_template');
      // console.log('document.getSelection ', sel);
      // console.log('sel.toString() ', sel.toString());
      // console.log('pos ', sel.toString().length);
      console.log('sel.focusOffset : ', sel.focusOffset);
      // let pos = sel.toString().length;
      return sel;
    },
    getCaretCoordinates: function () {
      let x = 0;
      let y = 0;
      let isSupported = typeof window.getSelection !== "undefined";
      if (isSupported) {
        let selection = window.getSelection();
        if (selection.rangeCount !== 0) {
          let range = selection.getRangeAt(0).cloneRange();
          range.collapse(true);
          let rect = range.getClientRects()[0];
          if (rect) {
            x = rect.left;
            y = rect.top;
          }
        }
      }
      return { x, y };
    },
    f_isChildOf: function (node, parentId) {
      while (node !== null) {
        if (node.id === parentId) {
          return true;
        }
        node = node.parentNode;
      }

      return false;
    },
    f_getCurrentCursorPosition: function (parentId) {
      var selection = window.getSelection(),
        charCount = -1,
        node;

      if (selection.focusNode) {
        if (this.f_isChildOf(selection.focusNode, parentId)) {
          node = selection.focusNode;
          charCount = selection.focusOffset;

          while (node) {
            if (node.id === parentId) {
              break;
            }

            if (node.previousSibling) {
              node = node.previousSibling;
              charCount += node.textContent.length;
            } else {
              node = node.parentNode;
              if (node === null) {
                break
              }
            }
          }
        }
      }
      return charCount;
    },
    F_getCaretIndex: function (element) {
      let position = 0;
      const isSupported = typeof window.getSelection !== "undefined";
      if (isSupported) {
        const selection = window.getSelection();
        if (selection.rangeCount !== 0) {
          const range = window.getSelection().getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          // console.log('preCaretRange ', preCaretRange);
          position = preCaretRange.toString().length;
        }
      }
      return position;
    },
    f_sentenceTemplateKeyUp: function (e) {
      if (e.key === 'Shift') {
        this.d_lastKey = '';
      } else if (e.key === 'Control') {
        this.d_lastKey = '';
      }
    },
    f_prepareAnatomyList: function (splitter = ',') {
      let prepared_list = [];
      this.d_anatomyModal.list = [];
      if (this.d_anatomyModal.text) {
        let anatomy_list = this.d_anatomyModal.text.split(splitter);
        for (let i in anatomy_list) {
          let ana_text = anatomy_list[i].replace(/\n/g, '');
          // console.log('ana_text ', ana_text);
          if (ana_text.replace(' ', '')) {
            let x = ana_text.split(' ');
            let y = '';
            for (let k in x) {
              // console.log('k : ', k);
              if (x[k]) {
                // console.log(x[k]);
                if (y) {
                  y += ' ';
                  // console.log(' space ');
                }
                y += x[k];
                // console.log('y+= ', y);
              }
            }
            if (y) {
              if (prepared_list.indexOf(y) === -1) {
                prepared_list.push(y);
                this.d_anatomyModal.list.push({ 'label': y });
              }

            }
          }
        }
        this.$forceUpdate();
      } else {
        alert('Anatomi yazı alanını aralarında virgül olarak ekleyiniz.');
      }
    },
    f_prepareNewParamOptionList: function (splitter = ',') {
      let prepared_list = [];
      this.d_schemaParam.new_option_list = [];
      if (this.d_schemaParam.new_option_text) {
        let parameter_op_list = this.d_schemaParam.new_option_text.split(splitter);
        for (let i in parameter_op_list) {
          let ana_text = parameter_op_list[i].replace(/\n/g, '');
          // console.log('ana_text ', ana_text);
          if (ana_text.replace(' ', '')) {
            let x = ana_text.split(' ');
            let y = '';
            for (let k in x) {
              // console.log('k : ', k);
              if (x[k]) {
                // console.log(x[k]);
                if (y) {
                  y += ' ';
                  // console.log(' space ');
                }
                y += x[k];
                // console.log('y+= ', y);
              }
            }
            if (y) {
              let already_included = 'no';
              for (let i in this.d_schemaParam.param_data.options) {
                if (this.d_schemaParam.param_data.options[i].label.toLocaleLowerCase() === y) {
                  already_included = 'yes';
                  break;
                }
              }
              if (prepared_list.indexOf(y) === -1) {
                prepared_list.push(y);
                this.d_schemaParam.new_option_list.push({ 'label': y, 'already_included': already_included });
              }
            }
          }
        }
        this.$forceUpdate();
      } else {
        alert('Parametre seçenek yazı alanını aralarında virgül olarak ekleyiniz.');
      }
    },
    f_prepareSmartSearchPhraseList: function (splitter = ',') {
      let prepared_list = [];
      let added_phrase_list = [];
      for (let i in this.d_smartSearchModel.new_phrase_list) {
        added_phrase_list.push(this.d_smartSearchModel.new_phrase_list[i].p);
      }
      if (this.d_smartSearchModel.text) {
        let parameter_op_list = this.d_smartSearchModel.text.split(splitter);
        for (let i in parameter_op_list) {
          let ana_text = parameter_op_list[i].replace(/\n/g, '');
          // console.log('ana_text ', ana_text);
          if (ana_text.replace(' ', '')) {
            let x = ana_text.split(' ');
            let y = '';
            for (let k in x) {
              // console.log('k : ', k);
              if (x[k]) {
                // console.log(x[k]);
                if (y) {
                  y += ' ';
                  // console.log(' space ');
                }
                y += x[k];
                // console.log('y+= ', y);
              }
            }
            if (y && added_phrase_list.indexOf(y) === -1) {
              let already_included = 'no';
              for (let i in this.d_smartSearchModel.details) {
                if (this.d_smartSearchModel.details[i].p.toLocaleLowerCase() === y) {
                  already_included = 'yes';
                  break;
                }
              }
              if (prepared_list.indexOf(y) === -1) {
                prepared_list.push(y);
                this.d_smartSearchModel.new_phrase_list.push({ 'p': y, 'already_included': already_included, 't': this.d_smartSearchModel.phrase_type });
              }
            }
          }
        }
        this.$forceUpdate();
      } else {
        alert('Parametre seçenek yazı alanını aralarında virgül olarak ekleyiniz.');
      }
    },
    f_prepareWdmParameterOptionList: function (splitter = ',') {
      let prepared_list = [];
      this.d_wdmParameterOption.list = [];
      if (this.d_wdmParameterOption.text) {
        let parameter_op_list = this.d_wdmParameterOption.text.split(splitter);
        for (let i in parameter_op_list) {
          let ana_text = parameter_op_list[i].replace(/\n/g, '');
          // console.log('ana_text ', ana_text);
          if (ana_text.replace(' ', '')) {
            let x = ana_text.split(' ');
            let y = '';
            for (let k in x) {
              // console.log('k : ', k);
              if (x[k]) {
                // console.log(x[k]);
                if (y) {
                  y += ' ';
                  // console.log(' space ');
                }
                y += x[k];
                // console.log('y+= ', y);
              }
            }
            if (y) {
              let already_included = 'no';
              for (let i in this.d_wdmParameterOption.param_data.options) {
                if (this.d_wdmParameterOption.param_data.options[i].label.toLocaleLowerCase() === y) {
                  already_included = 'yes';
                  break;
                }
              }
              if (prepared_list.indexOf(y) === -1) {
                prepared_list.push(y);
                this.d_wdmParameterOption.list.push({ 'label': y, 'already_included': already_included });
              }
            }
          }
        }
        this.$forceUpdate();
      } else {
        alert('Parametre seçenek yazı alanını aralarında virgül olarak ekleyiniz.');
      }
    },
    f_addNewAnatomy: function () {
      this.d_operationsModal.show = false;
      this.d_anatomyModal.show = true;
    },
    f_addNewWdmParameterOption: function () {
      this.d_operationsModal.show = false;
      this.d_wdmParameterOption.show = true;
    },
    f_selectSmartSearchModel: function (selected_index) {
      this.d_smartSearchModel.selected_index = selected_index;
      let model_id = this.d_smartSearchModel.list[this.d_smartSearchModel.selected_index].id;
      this.f_getSentenceSmartSearchModelDetails(model_id);
    },
    f_layerAnatomyAddAnatomyList: function () {
      if (this.d_anatomyModal.list.length > 0) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'list': this.d_anatomyModal.list
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Anatomi ekleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_anatomy_add_anatomy_list(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      } else {
        alert('Lütfen öncelikle anatomi ekleyiniz.');
      }
    },
    f_copySmartSearchModelDetails: function () {
      this.d_copySmartSearchModelDetails = JSON.parse(JSON.stringify(this.d_smartSearchModel));
    },
    f_addCopiedSmartSearchModelDetails: function (type) {
      if (type === 'reset') {
        this.d_smartSearchModel.new_phrase_list = [];
        this.d_smartSearchModel.details = JSON.parse(JSON.stringify(this.d_copySmartSearchModelDetails.details));
        this.f_saveSmartSearchModelDetails();
      } else if (type === 'only_new') {
        this.d_smartSearchModel.new_phrase_list = this.d_copySmartSearchModelDetails.details;
        this.f_saveSmartSearchModelDetails();
      }
    },
    f_saveSmartSearchModelDetails: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      let new_list = [];
      let uniq_list = [];
      for (let i in this.d_smartSearchModel.new_phrase_list) {
        if (uniq_list.indexOf(this.d_smartSearchModel.new_phrase_list[i].p) === -1) {
          uniq_list.push(this.d_smartSearchModel.new_phrase_list[i].p);
          new_list.push({ 'p': this.d_smartSearchModel.new_phrase_list[i].p, 't': this.d_smartSearchModel.new_phrase_list[i].t });
        }
      }
      for (let i in this.d_smartSearchModel.details) {
        if (uniq_list.indexOf(this.d_smartSearchModel.details[i].p) === -1) {
          uniq_list.push(this.d_smartSearchModel.details[i].p);
          new_list.push({ 'p': this.d_smartSearchModel.details[i].p, 't': this.d_smartSearchModel.details[i].t });
        }
      }
      query += '&model_id=' + this.d_smartSearchModel.list[this.d_smartSearchModel.selected_index].id;
      let data = {
        'list': new_list
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Model kaydediliyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_smart_search_model_details_save(query, data)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_smartSearchModel.details = resp.data.list;
            this.d_smartSearchModel.new_phrase_list = [];
            this.$forceUpdate();
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_addNewSmartSearchModel: function () {
      let det = false;
      for (let i in this.d_smartSearchModel.list) {
        if (this.d_smartSearchModel.list[i].name === this.d_smartSearchModel.new_model_name) {
          det = true;
          break;
        }
      }
      if (det) {
        alert('Bu isimde model bulunmaktadır.');
      } else {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'model_name': this.d_smartSearchModel.new_model_name
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Model kaydediliyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_smart_search_model_add_edit(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_smartSearchModel.list = resp.data.list;
              for (let i in this.d_smartSearchModel.list) {
                if (this.d_smartSearchModel.list[i].name === this.d_smartSearchModel.new_model_name) {
                  this.d_smartSearchModel.selected_index = parseInt(i);
                  break;
                }
              }
              this.$forceUpdate();
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      }
    },
    f_editSmartSearchModel: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      query += '&model_id=' + this.d_smartSearchModel.list[this.d_smartSearchModel.selected_index].id;
      let data = {
        'model_name': this.d_smartSearchModel.list[this.d_smartSearchModel.selected_index].name
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Model kaydediliyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_smart_search_model_add_edit(query, data)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_smartSearchModel.list = resp.data.list;
            this.$forceUpdate();
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_layerAddParameterOptionList: function () {
      if (this.d_schemaParam.new_option_list.length > 0) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'list': this.d_schemaParam.new_option_list,
          'index_list': this.d_schemaParam.index_list
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Parametreye seçenek ekleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_wdm_parameter_option_add(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              for (let i in resp.data.updated_wdm_data) {
                this.option_data[this.data_type][i] = resp.data.updated_wdm_data[i];
              }
              let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], this.d_schemaParam.index_list);
              this.d_schemaParam.param_data = param_data;

              this.$forceUpdate();
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      } else {
        alert('Lütfen öncelikle seçenekeri aralarına virgül koyarak ekleyiniz.');
      }
    },
    f_addWdmParameterOptionList: function () {
      if (this.d_wdmParameterOption.list.length > 0) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'list': this.d_wdmParameterOption.list,
          'index_list': this.d_wdmParameterOption.selected_index_data.index_list
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Parametreye seçenek ekleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_wdm_parameter_option_add(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              for (let i in resp.data.updated_wdm_data) {
                this.option_data[this.data_type][i] = resp.data.updated_wdm_data[i];
              }
              let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], this.d_wdmParameterOption.selected_index_data.index_list);
              this.d_wdmParameterOption.param_data = param_data;

              this.$forceUpdate();
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      } else {
        alert('Lütfen öncelikle anatomi ekleyiniz.');
      }
    },
    f_openSmartSearchModelListModal: function () {
      this.d_smartSearchModel.show = true;
      setTimeout(function () {
        let div_el = document.getElementById('smart_search_model_filter');
        if (div_el) {
          div_el.focus();
        }
      }.bind(this), 100)
    },
    f_getSentenceSmartSearchModelList: function (will_select_model_id = '') {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      if (!will_select_model_id) {
        if (this.p_modelId) {
          will_select_model_id = this.p_modelId;
        }
      }
      WmanagerService.wdm_sentence_smart_search_model_list(query)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            this.d_smartSearchModel.list = resp.data.list;
            if (will_select_model_id) {
              for (let i in this.d_smartSearchModel.list) {
                if (this.d_smartSearchModel.list[i].id === will_select_model_id) {
                  this.f_selectSmartSearchModel(parseInt(i));
                  break;
                }
              }
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_getSentenceSmartSearchModelDetails: function (model_id) {
      let query = 'layer_id=' + this.p_layerId + '&model_id=' + model_id;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Parametreye seçenek ekleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
      WmanagerService.wdm_sentence_smart_search_model_details(query)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_smartSearchModel.details = resp.data.list;
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_showWisdomDataShowTrueFalse: function () {
      this.d_showWisdomDataShowTrueFalse = false;
      setTimeout(function () {
        this.d_showWisdomDataShowTrueFalse = true;
      }.bind(this), 1000)
    },
    f_sentenceTemplateAnalyze: function (html_text = '', sending_api_button = '') {
      if (html_text === '') {
        let div_el = document.getElementById('wdmr_sentence_template');
        if (div_el && div_el.innerHTML) {
          html_text = div_el.innerHTML;
          html_text = html_text.replace(/<p(.*?)>/g, "<div>");
          html_text = html_text.replace(/<\/p>/g, "<\/div>");
          html_text = html_text.replace('<div><div>', '<div>');
          html_text = html_text.replace('</div></div>', '</div>');
          div_el.innerHTML = html_text;
        }
        if (!html_text) {
          html_text = this.f_prepareHtmlSentenceText();
          // console.log("html_text ", html_text);
        }
      }
      if (html_text) {
        let div_el = document.getElementById('wdmr_sentence_template');
        // console.log("div_el ", div_el);
        // console.log("this.data.sentence_template ", this.data.sentence_template);
        if (div_el) {
          div_el.contentEditable = false;
        }
        this.d_analyzeSentenceList.analyzing = true;
        if (html_text !== '' && ['<br>'].indexOf(html_text) === -1) {
          this.d_timerControlWdmrSentenceTemplateUpdate.status = 'active';
          let query = 'layer_id=' + this.p_layerId;
          if (this.p_wdmrId) {
            query += '&wdmr_id=' + this.p_wdmrId
          }
          if (this.p_wdmrToken) {
            query += '&wdmr_token=' + this.p_wdmrToken
          }
          let data = {
              'text': html_text,
              'analyzed_list': this.d_analyzeSentenceList.list
            }
            // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema kalıp analizi ve kayıt işlemi yapılıyor. Lütfen bekleyiniz.' } });
          this.d_analyzeSentenceList.list = [];
          WmanagerService.wdm_sentence_template_analyze(query, data)
            .then(resp => {
              this.d_analyzeSentenceList.analyzing = false;
              // this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status_code === '3000') {
                if (resp.data.sentence_list && resp.data.sentence_list.length > 0) {
                  this.d_analyzeSentenceList.list = resp.data.sentence_list;
                  let full_match_list_count = 0;
                  let total_eligible_len = 0;
                  for (let i in this.d_analyzeSentenceList.list) {
                    if (this.d_analyzeSentenceList.list[i].text.replace(/[\s]+/g, "")) {
                      total_eligible_len += 1;
                    }
                    if (this.d_analyzeSentenceList.list[i].full_match_list && this.d_analyzeSentenceList.list[i].full_match_list.length > 0) {
                      full_match_list_count += 1;
                    }
                  }
                  this.d_analyzeSentenceList.sentence_accuracy = ((full_match_list_count / total_eligible_len) * 100).toFixed(0);
                  this.d_analyzeSentenceList.sentence_accuracy = parseInt(this.d_analyzeSentenceList.sentence_accuracy);
                }
                if (resp.data.wdmr && resp.data.wdmr.data) {
                  this.data.data = resp.data.wdmr.data;
                }
                this.data.sentence_template = html_text;
                this.data.sentence_template_info = {
                  'accuracy': this.d_analyzeSentenceList.sentence_accuracy
                };
                let div_el = document.getElementById('wdmr_sentence_template');
                // console.log("div_el ", div_el);
                // console.log("this.data.sentence_template ", this.data.sentence_template);
                if (div_el) {
                  div_el.contentEditable = true;
                }
                this.f_saveWdmrByChoosingFunction(sending_api_button);
                // console.log(JSON.stringify(this.data));
                this.f_showWisdomDataShowTrueFalse();
                // this.f_saveWdmrToWmanager();
              } else {
                let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
                alert(msg);
              }
              this.d_timerControlWdmrSentenceTemplateUpdate.status = 'passive';
            });
        } else {
          this.data.data = {};
          this.data.sentence_template = '';
          this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', '', 'equal', true);
          this.f_saveWdmrByChoosingFunction(sending_api_button);
          // this.f_saveWdmrToWmanager();
        }
      } else {
        this.f_saveWdmrByChoosingFunction(sending_api_button);
      }
    },
    f_addNewSentenceTemplate: function () {
      let new_sentence_template = {
        'list': [{ 'phrase': '', 'type': 'not_param' }]
      }
      this.d_sentenceTemplate.all_list.push(new_sentence_template);
      this.f_openAddEditSentenceTemplateModal(this.d_sentenceTemplate.all_list.length - 1, 0);
      // this.f_saveSentenceTemplate();
    },
    f_addNewSentenceWithCopy: function (sentence_template_index) {
      let new_sentence_template = JSON.parse(JSON.stringify(this.d_sentenceTemplate.all_list[sentence_template_index]))
      this.d_sentenceTemplate.all_list.push(new_sentence_template);
      this.f_openAddEditSentenceTemplateModal(this.d_sentenceTemplate.all_list.length - 1, 0);
      // this.f_saveSentenceTemplate();
    },
    f_openAddEditSentenceTemplateModal: function (sen_temp_data_ind, sen_temp_ind = '') {
      this.d_addEditSentenceTemplate.sen_temp_data_ind = sen_temp_data_ind;
      this.d_addEditSentenceTemplate.template.list = JSON.parse(JSON.stringify(this.d_sentenceTemplate.all_list[sen_temp_data_ind].list));
      if (sen_temp_ind !== '') {
        this.d_addEditSentenceTemplate.selected = sen_temp_ind;
      }
      this.d_addEditSentenceTemplate.show = true;
    },
    f_changeSentenceTemplateSchemaMode: function () {
      this.d_operationsModal.show = false;
      if (!this.p_sentenceTemplateSettings.settings_mode) {
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        this.p_sentenceTemplateSettings.report_view_mode = 'template';
        // console.log('this.p_sentenceTemplateSettings.report_view_mode ', this.p_sentenceTemplateSettings.report_view_mode);
        WmanagerService.wdm_sentence_template_list(query)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.p_sentenceTemplateSettings.settings_mode = true;
              this.d_sentenceTemplate.show = false;
              this.d_sentenceTemplate.list = [];
              this.d_sentenceTemplate.all_list = resp.data.list;
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      } else {
        this.p_sentenceTemplateSettings.settings_mode = false;
      }
    },
    f_saveSentenceTemplate: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }

      let data = {
        'sentence_template_list': this.d_sentenceTemplate.all_list
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema güncelleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
      WmanagerService.wdm_sentence_template_record(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '3000') {
            this.d_sentenceTemplate.all_list = resp.data.list;
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_sentenceTemplateUniqControl: function () {
      let old_list_count = this.d_sentenceTemplate.all_list.length;
      let uniq_st_list = [];
      let new_list = [];
      for (let i in this.d_sentenceTemplate.all_list) {
        let j_stringify = JSON.stringify(this.d_sentenceTemplate.all_list[i]);
        if (this.printSomething) {
          if (parseInt(i) === 67 || parseInt(i) === 68) {
            console.log('j_stringify : ', j_stringify);
          }
        }
        if (uniq_st_list.indexOf(j_stringify) === -1) {
          uniq_st_list.push(j_stringify);
          new_list.push(this.d_sentenceTemplate.all_list[i]);
        }
      }
      if (this.printSomething) {
        console.log("old_list_count  : ", old_list_count);
        console.log("new_list.length : ", new_list.length);
      }
      if (new_list.length !== old_list_count) {
        this.d_sentenceTemplate.all_list = new_list;
        this.f_saveSentenceTemplate();
        alert('Cümle şablonlarında ' + (old_list_count - new_list.length).toString() + ' adet duplikasyon tespit edilip tekilleştirme işlemi gerçekleştirilmiştir.');
      } else {
        alert('Cümle şablonları tekil olarak tespit edilmiştir.');
      }
    },
    f_sentenceTemplateClearSameParameterIncluding: function () {
      let old_list_count = this.d_sentenceTemplate.all_list.length;
      let uniq_st_list = [];
      let new_list = [];
      let deleted_count = 0;
      for (let item_index in this.d_sentenceTemplate.all_list) {
        let item_data = this.d_sentenceTemplate.all_list[item_index];
        let param_type_total_count = 0;
        let uniq_param_index_list = [];
        for (let li_index in item_data.list) {
          if (item_data.list[li_index].type === 'param') {
            param_type_total_count += 1;
            if (uniq_param_index_list.indexOf(item_data.list[li_index].phrase) === -1) {
              uniq_param_index_list.push(item_data.list[li_index].phrase);
            }
          }
        }
        if (param_type_total_count !== uniq_param_index_list.length) {
          deleted_count += 1;
        } else {
          new_list.push(item_data);
        }
      }
      if (this.printSomething) {
        console.log("old_list_count  : ", old_list_count);
        console.log("new_list.length : ", new_list.length);
      }
      if (new_list.length !== old_list_count) {
        this.d_sentenceTemplate.all_list = new_list;
        this.f_saveSentenceTemplate();
        alert('Cümle şablonlarında ' + deleted_count.toString() + ' adet aynı parametrenin birden fazla olduğu kalıplı cümle tespit edilip silme işlemi gerçekleştirilmiştir.');
      } else {
        alert('Cümle şablonlarında aynı parametreyi birden fazla içeren kalıplı cümle tespit edilmemiştir.');
      }
    },
    f_sentenceTemplateClearThePointAtTheEndOfThePhrases: function () {
      let change_done = 0;
      for (let item_index in this.d_sentenceTemplate.all_list) {
        let item_data = this.d_sentenceTemplate.all_list[item_index];
        if (item_data.list.length > 0) {
          if (item_data.list[item_data.list.length - 1].type === 'not_param') {
            let problem_fixed = false;
            let regx_last_char_is_point = /[\s]+\./i;
            let regx_last_char_is_point_replace = /[\s]+\./g;
            let regx_last_char_is_point_test = regx_last_char_is_point.test(item_data.list[item_data.list.length - 1].phrase);
            if (regx_last_char_is_point_test) {
              if (this.printSomething) {
                console.log('before');
                console.log('item_data.list[item_data.list.length - 1].phrase : ', item_data.list[item_data.list.length - 1].phrase);
              }
              item_data.list[item_data.list.length - 1].phrase = item_data.list[item_data.list.length - 1].phrase.replace(regx_last_char_is_point_replace, "");
              problem_fixed = true;
              change_done += 1;
              if (this.printSomething) {
                console.log('after');
                console.log('item_data.list[item_data.list.length - 1].phrase : ', item_data.list[item_data.list.length - 1].phrase);
              }
            }
            if (!problem_fixed) {
              if (this.printSomething) {
                console.log('problem_fixed : ', problem_fixed);
              }
              let regx_last_char_is_point = /\./i;
              let regx_last_char_is_point_replace = /\./g;
              let regx_last_char_is_point_test = regx_last_char_is_point.test(item_data.list[item_data.list.length - 1].phrase);
              if (regx_last_char_is_point_test) {
                if (this.printSomething) {
                  console.log('before');
                  console.log('item_data.list[item_data.list.length - 1].phrase : ', item_data.list[item_data.list.length - 1].phrase);
                }
                item_data.list[item_data.list.length - 1].phrase = item_data.list[item_data.list.length - 1].phrase.replace(regx_last_char_is_point_replace, "");
                problem_fixed = true;
                change_done += 1;
                if (this.printSomething) {
                  console.log('after');
                  console.log('item_data.list[item_data.list.length - 1].phrase : ', item_data.list[item_data.list.length - 1].phrase);
                }
              }
            }
          }
        }
      }

      if (change_done > 0) {
        this.f_saveSentenceTemplate();
        alert('Cümle şablonlarında ' + change_done.toString() + ' adet sonunda nokta işareti tespit edilmiştir.');
      } else {
        alert('Cümle şablonlarında sonunda nokta işareti tespit edilmemiştir');
      }
    },
    f_deleteSentenceTemplateRow: function (sen_temp_data_ind) {
      this.d_sentenceTemplate.all_list.splice(sen_temp_data_ind, 1);
      this.f_saveSentenceTemplate();
    },
    f_acceptThisPhraseOption: function (option_text) {
      // console.log(option_text);
      for (let i in this.d_understandST_result.list) {
        let full_text = '';
        let next_phrase_index = '';
        for (let p in this.d_understandST_result.list[i].list) {
          if (full_text) {
            full_text += ' ';
          }
          if (this.d_understandST_result.list[i].list[p].accepted) {
            full_text += this.d_understandST_result.list[i].list[p].prepare_phrase;
          } else {
            full_text += option_text;
            next_phrase_index = parseInt(p);
            break
          }
        }
        if (next_phrase_index == '') {
          next_phrase_index = this.d_understandST_result.list[i].list.length - 1;
        }
        // console.log("next_phrase_index ", next_phrase_index);
        if (this.d_understandST_result.list[i].list[next_phrase_index].started_match && this.d_understandST_result.list[i].list[next_phrase_index].started_match.length > 0 && this.d_understandST_result.list[i].list[next_phrase_index].started_match.indexOf(option_text) !== -1) {
          this.d_understandST_result.list[i].list[next_phrase_index].prepare_phrase = option_text;
          this.d_understandST_result.list[i].list[next_phrase_index].accepted = 1;
          full_text += ' ';
          this.d_understandSentenceTemplate.text = full_text;
        } else {
          if (this.d_understandST_result.list[i].list[next_phrase_index].full_match === option_text) {
            this.d_understandST_result.list[i].list[next_phrase_index].prepare_phrase = option_text;
            this.d_understandST_result.list[i].list[next_phrase_index].accepted = 1;
            full_text += ' ';
            this.d_understandSentenceTemplate.text = full_text;
          }
        }

      }
      this.d_understandST_result.recommended_show = false;
      let div_el = document.getElementById('understand_search_text');
      if (div_el) {
        div_el.focus();
      }
      this.$forceUpdate();
    },
    f_changeSearchMode: function () {
      if (this.d_understandST_result.search_param_type === 'only_not_param') {
        this.d_understandST_result.search_param_type = 'param_and_not_param';
      } else {
        this.d_understandST_result.search_param_type = 'only_not_param';
      }
      this.$forceUpdate();
    },
    f_deleteSearchSTUnderstandingText: function () {
      this.d_understandSentenceTemplate.text = '';
    },
    f_changeUnderstandStyle: function () {
      if (this.d_understandST_result.show_type === 'overflow') {
        this.d_understandST_result.show_type = 'fulldiv';
      } else if (this.d_understandST_result.show_type === 'fulldiv') {
        this.d_understandST_result.show_type = 'overflow';
      }
    },
    f_calculateUnderstandListStyle: function (sentence_index) {
      if (this.d_understandST_result.show_type === 'overflow') {
        return 'max-height: 150px; overflow-x: hidden; overflow-y: auto; background-color: #f0fffb; border: solid 1px #83bb9e; margin-bottom: 5px;';
      } else if (this.d_understandST_result.show_type === 'fulldiv') {
        return 'background-color: #f0fffb; border: solid 1px #83bb9e; margin-bottom: 5px;';
      }
    },
    f_updateSentenceTemplateWithUnderstoodSentence: function (sentence_index) {
      let tag = 'div';
      let sentence_data = this.d_understandST_result.list[sentence_index];
      let additional_html_text = '';
      let everything_ok = true;
      let alert_text = '';
      for (let i in sentence_data.list) {
        if (additional_html_text) {
          additional_html_text += ' ';
        }
        if (sentence_data.list[i].type === 'not_param') {
          if (sentence_data.list[i].phrase) {
            additional_html_text += sentence_data.list[i].phrase;
          } else {
            alert_text = (parseInt(i) + 1).toString() + ' sıradaki ifadenin bilgisini ayarlardan lütfen düzenleyiniz.';
            everything_ok = false;
            break;
          }
        } else if (sentence_data.list[i].type === 'param') {
          if (sentence_data.list[i].prepare_phrase) {
            additional_html_text += sentence_data.list[i].prepare_phrase;
          } else {
            if (sentence_data.list[i].param_data && sentence_data.list[i].param_data.label) {
              alert_text = (parseInt(i) + 1).toString() + ' sıradaki ifadenin (' + sentence_data.list[i].param_data.label + ') bilgisini ekleyiniz.';
            } else {
              alert_text = (parseInt(i) + 1).toString() + ' sıradaki ifadenin bilgisini bilgisini ekleyiniz.';
            }
            everything_ok = false;
            break;
          }
        }
      }
      // console.log('everything_ok ', everything_ok);
      if (everything_ok) {
        if (additional_html_text) {
          // console.log('additional_html_text ', additional_html_text);
          additional_html_text = this.f_makeFirstLetterUpper(additional_html_text);
          additional_html_text = '<' + tag + '>' + additional_html_text + '</' + tag + '>';
          this.f_makeSentenceUpdate(true, tag, additional_html_text);
        }
      } else {
        alert(alert_text);
      }
    },
    f_openSchemaParameterModalForUnderstand: function (sentence_index, phrase_index) {
      let phrase_data = this.d_understandST_result.list[sentence_index].list[phrase_index];
      if (phrase_data.type === 'param') {
        if (phrase_data.phrase === 'anatomy') {
          this.d_schemaAnatomy.list_type = 'understand';
          this.d_schemaAnatomy.sentence_index = sentence_index;
          this.d_schemaAnatomy.phrase_index = phrase_index;
          this.d_schemaAnatomy.selected_parent = {};
          this.d_schemaAnatomy.selected_anatomy = {};
          this.d_schemaAnatomy.show = true;
        } else {
          this.d_schemaParam.list_type = 'understand';
          this.d_schemaParam.sentence_index = sentence_index;
          this.d_schemaParam.phrase_index = phrase_index;
          // console.log(phrase_data);
          let index_list = phrase_data.phrase.split('.');
          let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
          this.d_schemaParam.index_list = index_list;
          if (param_data.type === 'checkbox') {
            this.d_schemaParam.param_value = [];
          } else {
            this.d_schemaParam.param_value = '';
          }
          // console.log(param_data);
          this.d_schemaParam.param_data = param_data;
          this.d_schemaParam.show = true;
        }
      }
    },
    f_updateSchemaParameter: function () {
      if (this.d_schemaParam.list_type === 'understand') {
        if (['integer', 'float'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_understandST_result.list[this.d_schemaParam.sentence_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.toString();
        } else if (['text', 'textarea'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_understandST_result.list[this.d_schemaParam.sentence_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.toString();
        } else if (['select', 'object_list', 'radiogroup'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_understandST_result.list[this.d_schemaParam.sentence_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.label;
        } else if (['checkbox'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_understandST_result.list[this.d_schemaParam.sentence_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.join(',');
        } else if (['date', 'datetime'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_understandST_result.list[this.d_schemaParam.sentence_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value;
        }
        this.$forceUpdate();
      } else {
        if (['integer', 'float'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_analyzeSentenceList.list[this.d_schemaParam.analyze_sen_ind].full_match_list[this.d_schemaParam.full_match_list_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.toString();
        } else if (['text', 'textarea'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_analyzeSentenceList.list[this.d_schemaParam.analyze_sen_ind].full_match_list[this.d_schemaParam.full_match_list_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.toString();
        } else if (['select', 'object_list', 'radiogroup'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_analyzeSentenceList.list[this.d_schemaParam.analyze_sen_ind].full_match_list[this.d_schemaParam.full_match_list_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.label;
        } else if (['checkbox'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_analyzeSentenceList.list[this.d_schemaParam.analyze_sen_ind].full_match_list[this.d_schemaParam.full_match_list_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value.join(',');
        } else if (['date', 'datetime'].indexOf(this.d_schemaParam.param_data.type) !== -1) {
          this.d_analyzeSentenceList.list[this.d_schemaParam.analyze_sen_ind].full_match_list[this.d_schemaParam.full_match_list_index].list[this.d_schemaParam.phrase_index].prepare_phrase = this.d_schemaParam.param_value;
        }
        this.f_makeSentenceUpdate();
        // this.$forceUpdate();
      }
      this.d_schemaParam.show = false;
    },
    f_understandThisST: function (search_type = 'fast') {
      if (this.d_understandST_result.search_text) {
        let query = 'layer_id=' + this.p_layerId + '&search_text=' + this.d_understandST_result.search_text;
        query += '&search_type=' + search_type;
        query += '&search_param_type=' + this.d_understandST_result.search_param_type;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'search_text': this.d_understandST_result.search_text
        };
        // console.log("this.d_understandST_result.search_param_type ", this.d_understandST_result.search_param_type);
        if (this.d_understandST_result.search_param_type === 'param_and_not_param') {
          if (this.d_understandST_result.list.length > 0) {
            data.sentence_list = this.d_understandST_result.list;
          }
        }
        WmanagerService.wdm_sentence_template_search(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.d_understandST_result.list = resp.data.list;
              this.d_understandST_result.recommended_text_list = resp.data.recommended_text_list;
              this.d_understandST_result.recommended_show = true;
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      }
    },
    f_deleteSentenceRow: function (analyze_sen_ind) {
      this.d_analyzeSentenceList.list.splice(analyze_sen_ind, 1);
      this.f_makeSentenceUpdate(true, 'div', '', false);
      this.d_editorTextRow.show;
      this.$forceUpdate();
    },

    f_updateSentenceTemplateList: function () {
      let sen_temp_data_ind = this.d_addEditSentenceTemplate.sen_temp_data_ind;
      this.d_sentenceTemplate.all_list[sen_temp_data_ind].list = JSON.parse(JSON.stringify(this.d_addEditSentenceTemplate.template.list));
      this.f_saveSentenceTemplate();
      this.$forceUpdate();
    },
    f_selectPhraseOfSentenceTemplate: function (sen_temp_ind) {
      this.d_addEditSentenceTemplate.selected = sen_temp_ind;
      this.$forceUpdate();
    },
    f_changeSentenceTemplatePhraseMode: function (sen_temp_data_ind, sen_temp_ind) {
      if (!this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type) {
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type = 'param';
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].phrase = '';
      } else if (this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type === 'param' && this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].phrase === 'anatomy') {
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type = 'param';
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].phrase = '';
      } else if (this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type === 'param' && this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].phrase !== 'anatomy') {
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type = 'not_param';
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].phrase = '';
      } else if (this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type === 'not_param') {
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].type = 'param';
        this.d_sentenceTemplate.all_list[sen_temp_data_ind].list[sen_temp_ind].phrase = 'anatomy';
      }
      this.$forceUpdate();
    },
    f_changePhraseOfSentenceTemplate: function (sen_temp_ind) {
      if (!this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type) {
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type = 'param';
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].phrase = '';
      } else if (this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type === 'param' && this.d_addEditSentenceTemplate.template.list[sen_temp_ind].phrase === 'anatomy') {
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type = 'param';
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].phrase = '';
      } else if (this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type === 'param' && this.d_addEditSentenceTemplate.template.list[sen_temp_ind].phrase !== 'anatomy') {
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type = 'not_param';
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].phrase = '';
      } else if (this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type === 'not_param') {
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].type = 'param';
        this.d_addEditSentenceTemplate.template.list[sen_temp_ind].phrase = 'anatomy';
      }
      this.$forceUpdate();
    },
    f_phraseSchemaOperation: function (phrase, analyze_sen_ind, full_match_list_index, phrase_ind) {
      if (phrase.phrase === 'anatomy') {
        this.f_openAnatomySelectionModal(analyze_sen_ind, full_match_list_index, phrase_ind);
      } else if (phrase.type === 'param') {
        this.f_openSchemaParameterModal(analyze_sen_ind, full_match_list_index, phrase_ind);
      }
    },
    f_makeFirstLetterUpper: function (text) {
      if (text) {
        return text[0].toUpperCase() + text.slice(1);
      } else {
        return '';
      }
    },
    f_prepareHtmlSentenceText: function (add_not_matching_sentences = true, sentence_tag_type = 'div', additional_html_text = '') {
      let new_html_sentence_template = '';
      for (let i in this.d_analyzeSentenceList.list) {
        if (this.d_analyzeSentenceList.list[i].full_match_list && this.d_analyzeSentenceList.list[i].full_match_list.length > 0) {
          // Today we accept the first index of possible list as true sentence. It will be given to user choice in next development.
          if (this.d_analyzeSentenceList.list[i].full_match_list[0].list) {
            let prepare_phrase_text = '';
            for (let phrase_index in this.d_analyzeSentenceList.list[i].full_match_list[0].list) {
              prepare_phrase_text += this.d_analyzeSentenceList.list[i].full_match_list[0].list[phrase_index].prepare_phrase + ' ';
            }
            if (prepare_phrase_text) {
              let splitted_text = prepare_phrase_text.split();
              if (splitted_text.length > 0) {
                let end_sentence_text = splitted_text.join(' ');
                end_sentence_text = this.f_makeFirstLetterUpper(end_sentence_text);
                new_html_sentence_template += '<' + sentence_tag_type + '>' + end_sentence_text + '</' + sentence_tag_type + '>';
              }
            }
          }
        } else {
          if (add_not_matching_sentences) {
            let not_matching_text = this.d_analyzeSentenceList.list[i].text;
            not_matching_text = this.f_makeFirstLetterUpper(not_matching_text);
            if (not_matching_text) {
              new_html_sentence_template += '<' + sentence_tag_type + '>' + not_matching_text + '</' + sentence_tag_type + '>';
            } else {
              new_html_sentence_template += '<' + sentence_tag_type + '>' + '<br>' + '</' + sentence_tag_type + '>';
            }
          }
        }
      }
      if (additional_html_text) {
        new_html_sentence_template += additional_html_text;
      }
      return new_html_sentence_template;
    },
    f_makeSentenceUpdate: function (add_not_matching_sentences = true, sentence_tag_type = 'div', additional_html_text = '', sentence_template_analyze = true) {
      let new_html_sentence_template = this.f_prepareHtmlSentenceText(add_not_matching_sentences, sentence_tag_type, additional_html_text);
      // console.log('new_html_sentence_template ', new_html_sentence_template);
      this.data.sentence_template = new_html_sentence_template;
      this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', new_html_sentence_template, 'equal', true);
      if (sentence_template_analyze) {
        this.f_sentenceTemplateAnalyze(new_html_sentence_template);
      }
      this.$forceUpdate();
    },
    f_selectAnatomyForSchema: function () {
      // console.log(JSON.stringify(this.d_analyzeSentenceList.list[this.d_schemaAnatomy.analyze_sen_ind].possible_list[this.d_schemaAnatomy.poss_ind].analyze_list[this.d_schemaAnatomy.analyze_ind].phrase[this.d_schemaAnatomy.phrase_ind]));
      if (this.d_schemaAnatomy.list_type === 'text_in_report') {
        this.d_analyzeSentenceList.list[this.d_schemaAnatomy.analyze_sen_ind].full_match_list[this.d_schemaAnatomy.full_match_list_index].list[this.d_schemaAnatomy.phrase_index].prepare_phrase = this.d_schemaAnatomy.selected_anatomy.label;
        this.d_schemaAnatomy.show = false;
        this.f_makeSentenceUpdate();
      } else if (this.d_schemaAnatomy.list_type === 'understand') {
        this.d_understandST_result.list[this.d_schemaAnatomy.sentence_index].list[this.d_schemaAnatomy.phrase_index].prepare_phrase = this.d_schemaAnatomy.selected_anatomy.label;
        this.d_schemaAnatomy.show = false;
      }
      // this.$forceUpdate();
    },
    f_openAnatomySelectionModal: function (analyze_sen_ind, full_match_list_index, phrase_ind) {
      this.d_schemaAnatomy.list_type = 'text_in_report';
      this.d_schemaAnatomy.analyze_sen_ind = analyze_sen_ind;
      this.d_schemaAnatomy.full_match_list_index = full_match_list_index;
      this.d_schemaAnatomy.phrase_index = phrase_ind;
      this.d_schemaAnatomy.selected_parent = {};
      this.d_schemaAnatomy.selected_anatomy = {};
      this.d_schemaAnatomy.show = true;
    },
    f_openSchemaParameterModal: function (analyze_sen_ind, full_match_list_index, phrase_ind) {
      this.d_schemaParam.list_type = 'text_in_report';
      this.d_schemaParam.analyze_sen_ind = analyze_sen_ind;
      this.d_schemaParam.full_match_list_index = full_match_list_index;
      this.d_schemaParam.phrase_index = phrase_ind;
      let phrase_data = this.d_analyzeSentenceList.list[analyze_sen_ind].full_match_list[full_match_list_index].list[phrase_ind];
      // console.log(phrase_data);
      let index_list = phrase_data.phrase.split('.');
      let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
      this.d_schemaParam.index_list = index_list;
      // console.log(param_data);
      if (param_data.type === 'checkbox') {
        this.d_schemaParam.param_value = [];
      } else {
        this.d_schemaParam.param_value = '';
      }
      this.d_schemaParam.param_data = param_data;
      this.d_schemaParam.show = true;
    },
    f_changeSchemaMode: function () {
      let new_mode = this.p_sentenceTemplateSettings.report_view_mode;
      this.p_sentenceTemplateSettings.report_view_mode = '';
      if (new_mode === 'text') {
        new_mode = 'template';
      } else if (new_mode === 'template') {
        new_mode = 'text';
      }
      setTimeout(function () {
        if (new_mode === 'text') {
          this.p_sentenceTemplateSettings.report_view_mode = 'text';
          setTimeout(function () {
            if (this.data.sentence_template) {
              this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', this.data.sentence_template, 'equal', true);
            }
          }.bind(this), 500)
        } else {
          this.p_sentenceTemplateSettings.report_view_mode = new_mode;
          this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', '', 'equal', true);
        }
      }.bind(this), 500)
    },
    f_openSchemaListModal: function () {
      this.f_schemaList();
    },
    f_schemaList: function (open_shema_list_modal = true) {
      this.d_schemaListModal.selected_schema = '';
      this.d_schemaListModal.selected_index = '';
      this.d_schemaListModal.list = [];
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }

      let data = {
        'tag_id': this.d_schemaListModal.tag_id,
        'search_text': this.d_schemaListModal.search_text
      };
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema listesi getiriliyor. Lütfen bekleyiniz.' } });
      WmanagerService.sentence_template_schema_list(query, data)
        .then(resp => {
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '3000') {
            this.d_schemaListModal.list = resp.data.list;
            if (open_shema_list_modal) {
              this.d_schemaListModal.mode = 'report';
              this.d_schemaListModal.show = true;
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_saveSenTemplateSchema: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      let data = {
        'schema_name': this.d_schemaAddEditModal.schema_name,
        'schema_tag': this.d_schemaAddEditModal.schema_tag,
        'schema_text': this.d_schemaAddEditModal.schema_text,
        'schema_id': this.d_schemaAddEditModal.schema_id,
        'tag_id': this.d_schemaAddEditModal.tag_id
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema kaydediliyor. Lütfen bekleyiniz.' } });
      WmanagerService.save_sentence_template_schema(query, data)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.schema_id) {
              this.d_schemaAddEditModal.schema_id = resp.data.schema_id;
              this.d_schemaAddEditModal.tag_id = resp.data.tag_id;
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_selectThisSchemaById: function (tag_schema_id) {
      // console.log('tag_schema_id : ', tag_schema_id);
      let splitted = tag_schema_id.split('_');
      let tag_id = splitted[0];
      let schema_id = splitted[1];
      let query = 'layer_id=' + this.p_layerId;
      query += '&tag_id=' + tag_id;
      query += '&schema_id=' + schema_id;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      WmanagerService.get_sentence_template_schema(query)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            try {
              let smart_search_model_id = resp.data.schema_data.data.general.smart_search_model_id.val;
              if (this.d_smartSearchModel.list.length > 0) {
                for (let model_index in this.d_smartSearchModel.list) {
                  if (this.d_smartSearchModel.list[model_index].id === smart_search_model_id) {
                    this.f_selectSmartSearchModel(parseInt(model_index));
                    break;
                  }
                }
              } else {
                this.f_getSentenceSmartSearchModelList(smart_search_model_id)
              }
            } catch (err) {}
            let schema_text = '';
            try {
              schema_text = resp.data.schema_data.data.general.schema_text.val;
            } catch (err) {}
            if (schema_text) {
              if (!this.data.sentence_template) {
                this.f_addThisSchema(schema_text);
              } else {
                // console.log('Report is already has a text');
              }
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_selectThisSchema: function (schema_index) {
      let schema = this.d_schemaListModal.list[schema_index];
      this.f_initHtmlSentenceWdmrSentenceTemplate('selected_schema_sentence_template', '', 'equal', false);
      this.d_schemaListModal.selected_schema_item = schema;
      this.d_schemaListModal.selected_index = schema_index;
      this.d_schemaListModal.selected_schema = '';
      let query = 'layer_id=' + this.p_layerId;
      query += '&tag_id=' + schema.tag_id;
      query += '&schema_id=' + schema.schema_id;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema getiriliyor. Lütfen bekleyiniz.' } });
      WmanagerService.get_sentence_template_schema(query)
        .then(resp => {
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '3000') {
            this.d_schemaListModal.selected_index = schema_index;
            this.d_schemaListModal.selected_schema = resp.data.schema_data;
            let div_el = document.getElementById('selected_schema_sentence_template');
            if (div_el) {
              setTimeout(function () {
                this.f_initHtmlSentenceWdmrSentenceTemplate('selected_schema_sentence_template', this.d_schemaListModal.selected_schema.data.general.schema_text.val, 'equal', false);
              }.bind(this), 500)
            }
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },
    f_addThisSchema: function (schema_text = '') {
      if (!schema_text) {
        schema_text = this.d_schemaListModal.selected_schema.data.general.schema_text.val;
      }
      if (schema_text) {
        let wdmr_data_groups = {};
        for (let i in this.data.data) {
          wdmr_data_groups[i] = {};
        }
        this.data.data = wdmr_data_groups;
        this.f_prepareAnalyzeSentenceList(schema_text);
        this.data.sentence_template = schema_text;
        if (this.p_sentenceTemplateSettings.report_view_mode === 'template') {
          this.p_sentenceTemplateSettings.report_view_mode = 'text';
          setTimeout(function () {
            this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', schema_text, 'equal', true);
            this.d_schemaListModal.show = false;
          }.bind(this), 500)
        } else if (this.p_sentenceTemplateSettings.report_view_mode === 'text') {
          this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', schema_text, 'equal', true);
          this.d_schemaListModal.show = false;
        }

      }
    },
    f_updateSchemaFromReportTest: function () {
      let div_el = document.getElementById('wdmr_sentence_template');
      if (div_el && div_el.innerHTML) {
        this.f_initHtmlSentenceWdmrSentenceTemplate('selected_schema_sentence_template', div_el.innerHTML, 'equal');
        alert('Şablon rapor yazısı ile güncellendi.');
      } else {
        alert('Lütfen önce raporu hazırlayınız.');
      }
    },
    f_addReportToSchema: function () {
      this.d_operationsModal.show = false;
      let div_el = document.getElementById('wdmr_sentence_template');
      if (div_el && div_el.innerHTML) {
        this.d_schemaAddEditModal.tag_list = [];
        this.d_schemaListModal.selected_schema = '';
        this.d_schemaListModal.selected_index = '';
        this.d_schemaListModal.list = [];
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        // we get all schema list below.
        let data = {
          'tag_id': '',
          'search_text': ''
        };
        WmanagerService.sentence_template_schema_list(query, data)
          .then(resp => {
            if (resp.data.status_code === '3000') {
              this.d_schemaListModal.list = resp.data.list;
              let uniq_tag_list = [];
              for (let i in this.d_schemaListModal.list) {
                if (uniq_tag_list.indexOf(this.d_schemaListModal.list[i].tag_id) === -1) {
                  uniq_tag_list.push(this.d_schemaListModal.list[i].tag_id);
                  let new_tag = { 'tag': this.d_schemaListModal.list[i].tag, 'id': this.d_schemaListModal.list[i].tag_id };
                  this.d_schemaAddEditModal.tag_list.push(new_tag);
                }
              }
              this.d_schemaAddEditModal.schema_id = '';
              this.d_schemaAddEditModal.tag_id = '';
              this.d_schemaAddEditModal.schema_text = div_el.innerHTML;
              this.d_schemaAddEditModal.show = true;
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      } else {
        let msg = 'Lütfen rapor alanını öncelikle doldurarak testlerinizi gerçekleştiriniz. Uygun olarak bulduğunuz raporu katmana şema olarak kaydedebilirsiniz.';
        alert(msg);
      }
    },
    f_updateSchema: function () {
      let div_el = document.getElementById('selected_schema_sentence_template');
      if (div_el) {
        this.d_schemaListModal.selected_schema.data.general.schema_text.val = div_el.innerHTML;
        let query = 'layer_id=' + this.p_layerId;
        if (this.p_wdmrId) {
          query += '&wdmr_id=' + this.p_wdmrId
        }
        if (this.p_wdmrToken) {
          query += '&wdmr_token=' + this.p_wdmrToken
        }
        let data = {
          'schema_name': this.d_schemaListModal.selected_schema.data.general.schema_name.val,
          'schema_tag': this.d_schemaListModal.selected_schema.data.general.schema_tag.val,
          'schema_text': this.d_schemaListModal.selected_schema.data.general.schema_text.val,
          'schema_id': this.d_schemaListModal.selected_schema_item.schema_id,
          'tag_id': this.d_schemaListModal.selected_schema_item.tag_id
        };
        if (this.d_schemaListModal.selected_schema.data.general.smart_search_model_id && this.d_schemaListModal.selected_schema.data.general.smart_search_model_id.val) {
          data.smart_search_model_id = this.d_schemaListModal.selected_schema.data.general.smart_search_model_id.val;
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şema güncelleme işlemi yapılıyor. Lütfen bekleyiniz.' } });
        WmanagerService.save_sentence_template_schema(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000') {
              if (resp.data.schema_id) {}
            } else {
              let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
              alert(msg);
            }
          });
      }
    },
    f_clearHtmlTextDiv: function () {
      if (confirm('Rapor yazısını temizlemek istediğinize emin misiniz ?')) {
        this.d_analyzeSentenceList.list = [];
        this.data.data = {};
        this.data.sentence_template = '';
        this.f_initHtmlSentenceWdmrSentenceTemplate('wdmr_sentence_template', '', 'equal', true);
        this.f_saveWdmrByChoosingFunction();
        this.p_sentenceTemplateSettings.clear_html_text_div = false;
      }
    },
    f_clearHtmlTextDivSchema: function () {
      if (confirm('Rapor yazısını temizlemek istediğinize emin misiniz ?')) {
        this.f_initHtmlSentenceWdmrSentenceTemplate('selected_schema_sentence_template', '', 'equal', true);
      }
    },
    f_deletePhraseFromSentenceTemplate: function (sen_temp_ind) {
      this.d_sentenceTemplate.list.splice(sen_temp_ind, 1);
      this.$forceUpdate();
    },
    f_deletePhraseOfSentenceTemplate: function (sen_temp_ind) {
      this.d_addEditSentenceTemplate.selected = '';
      this.d_addEditSentenceTemplate.template.list.splice(sen_temp_ind, 1);
      this.$forceUpdate();
    },
    f_addNewPhraseToSentenceTemplate: function () {
      let new_sentence_template = { 'phrase': '', 'type': 'not_param' };
      this.d_sentenceTemplate.list.push(new_sentence_template);
      this.$forceUpdate();
    },
    f_addNewPhraseST: function (sen_temp_ind, type) {
      let new_sentence_template = { 'phrase': '', 'type': 'not_param' };
      if (type === 'down') {
        if (sen_temp_ind === '') {
          this.d_addEditSentenceTemplate.template.list.push(new_sentence_template);
        } else {
          if (sen_temp_ind === this.d_addEditSentenceTemplate.template.list.length - 1) {
            this.d_addEditSentenceTemplate.template.list.push(new_sentence_template);
          } else {
            this.d_addEditSentenceTemplate.template.list.splice(sen_temp_ind + 1, 0, new_sentence_template);
          }
        }
      } else if (type === 'up') {
        if (sen_temp_ind === 0) {
          this.d_addEditSentenceTemplate.template.list.splice(0, 0, new_sentence_template);
        } else {
          this.d_addEditSentenceTemplate.template.list.splice(sen_temp_ind, 0, new_sentence_template);
        }
      }
      this.$forceUpdate();
    },
    f_sentenceTemplateFilterFunction: function () {
      this.d_sentenceTemplate.selected_index = '';
      this.d_sentenceTemplate.search_index_list = [];
      this.d_sentenceTemplate.pagination = {
        'start': 0,
        'end': 15,
        'current': 1,
        'perpage': 15,
        'all_count': 0
      };
      if (this.d_sentenceTemplate.timeout_client_id) {
        clearTimeout(this.d_sentenceTemplate.timeout_client_id);
        this.d_sentenceTemplate.timeout_client_id = 0;
      }
      if (this.d_sentenceTemplate.search_text) {
        if (this.d_sentenceTemplate.all_list.length > 0) {
          let splitted_text_list = this.d_sentenceTemplate.search_text.split(',');
          if (splitted_text_list.length > 0) {
            this.d_sentenceTemplate.timeout_client_id = setTimeout(function () {
              for (let item_index in this.d_sentenceTemplate.all_list) {
                let item_data = this.d_sentenceTemplate.all_list[item_index];
                let will_continue = false;
                if (this.d_sentenceTemplate.filter_group === 'all') {
                  will_continue = true;
                } else if (this.d_sentenceTemplate.filter_group === 'include_same_parameter_multi') {
                  let param_type_total_count = 0;
                  let uniq_param_index_list = [];
                  for (let li_index in item_data.list) {
                    if (item_data.list[li_index].type === 'param') {
                      param_type_total_count += 1;
                      if (uniq_param_index_list.indexOf(item_data.list[li_index].phrase) === -1) {
                        uniq_param_index_list.push(item_data.list[li_index].phrase);
                      }
                    }
                  }
                  if (param_type_total_count !== uniq_param_index_list.length) {
                    will_continue = true;
                  }
                }
                if (will_continue) {
                  let found_count = 0;
                  for (let splitted_text_index in splitted_text_list) {
                    if (splitted_text_list[splitted_text_index]) {
                      for (let li_index in item_data.list) {
                        let det = false;
                        if (item_data.list[li_index].phrase && item_data.list[li_index].phrase.toLocaleLowerCase().indexOf(splitted_text_list[splitted_text_index].toLocaleLowerCase()) !== -1) {
                          found_count += 1;
                          det = true;
                          break;
                        }
                        if (!det) {
                          if (item_data.list[li_index].param_data && item_data.list[li_index].param_data.label && item_data.list[li_index].param_data.label.toLocaleLowerCase().indexOf(splitted_text_list[splitted_text_index].toLocaleLowerCase()) !== -1) {
                            found_count += 1;
                            det = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                  if (found_count >= splitted_text_list.length) {
                    this.d_sentenceTemplate.search_index_list.push(parseInt(item_index));
                  }
                }
              }
            }.bind(this), 500);
          }
        }
      } else {
        if (this.d_sentenceTemplate.filter_group === 'all') {
          //
        } else if (this.d_sentenceTemplate.filter_group === 'include_same_parameter_multi') {
          this.d_sentenceTemplate.timeout_client_id = setTimeout(function () {
            for (let item_index in this.d_sentenceTemplate.all_list) {
              let item_data = this.d_sentenceTemplate.all_list[item_index];
              let will_continue = false;
              let param_type_total_count = 0;
              let uniq_param_index_list = [];
              for (let li_index in item_data.list) {
                if (item_data.list[li_index].type === 'param') {
                  param_type_total_count += 1;
                  if (uniq_param_index_list.indexOf(item_data.list[li_index].phrase) === -1) {
                    uniq_param_index_list.push(item_data.list[li_index].phrase);
                  }
                }
              }
              if (param_type_total_count !== uniq_param_index_list.length) {
                will_continue = true;
              }
              if (will_continue) {
                this.d_sentenceTemplate.search_index_list.push(parseInt(item_index));
              }
            }
          }.bind(this), 500);
        }
      }
    },
    f_schemaClientIdFilterFunction: function () {
      this.d_schemaClientIdMatch.selected_index = '';
      this.d_schemaClientIdMatch.search_index_client_id_list = [];
      this.d_schemaClientIdMatch.pagination_client_schema = {
        'start': 0,
        'end': 25,
        'current': 1,
        'perpage': 25,
        'all_count': 0
      };
      this.d_schemaClientIdMatch.pagination = {
        'start': 0,
        'end': 25,
        'current': 1,
        'perpage': 25,
        'all_count': 0
      };
      if (this.d_schemaClientIdMatch.timeout_client_id) {
        clearTimeout(this.d_schemaClientIdMatch.timeout_client_id);
        this.d_schemaClientIdMatch.timeout_client_id = 0;
      }
      if (this.d_schemaClientIdMatch.search_client_schema_id) {
        if (this.d_schemaClientIdMatch.doc && this.d_schemaClientIdMatch.doc.data && this.d_schemaClientIdMatch.doc.data.general && this.d_schemaClientIdMatch.doc.data.general.client_id && this.d_schemaClientIdMatch.doc.data.general.client_id.list) {
          let splitted_text_list = this.d_schemaClientIdMatch.search_client_schema_id.split(',');
          if (splitted_text_list.length > 0) {
            this.d_schemaClientIdMatch.timeout_client_id = setTimeout(function () {
              for (let client_index in this.d_schemaClientIdMatch.doc.data.general.client_id.list) {
                let client_schema_data = this.d_schemaClientIdMatch.doc.data.general.client_id.list[client_index];
                let will_continue = false;
                if (this.d_schemaClientIdMatch.client_schema_id_search_type === 'all') {
                  will_continue = true;
                } else if (this.d_schemaClientIdMatch.client_schema_id_search_type === 'assigned') {
                  if (client_schema_data.wdmr_schema_id && client_schema_data.wdmr_schema_id.val) {
                    will_continue = true;
                  }
                } else if (this.d_schemaClientIdMatch.client_schema_id_search_type === 'not_assigned_yet') {
                  if (!client_schema_data.wdmr_schema_id) {
                    will_continue = true;
                  }
                }
                if (will_continue) {
                  let found_count = 0;
                  for (let splitted_text_index in splitted_text_list) {
                    if (splitted_text_list[splitted_text_index]) {
                      if (client_schema_data.schema_name && client_schema_data.schema_name.val.toLocaleLowerCase().indexOf(splitted_text_list[splitted_text_index].toLocaleLowerCase()) !== -1) {
                        found_count += 1;
                      }
                      if (client_schema_data.schema_id && client_schema_data.schema_id.val.toLocaleLowerCase().indexOf(splitted_text_list[splitted_text_index].toLocaleLowerCase()) !== -1) {
                        found_count += 1;
                      }
                      if (client_schema_data.wdmr_schema_id && client_schema_data.wdmr_schema_id.val.toLocaleLowerCase().indexOf(splitted_text_list[splitted_text_index].toLocaleLowerCase()) !== -1) {
                        found_count += 1;
                      }
                    }
                  }
                  if (found_count >= splitted_text_list.length) {
                    this.d_schemaClientIdMatch.search_index_client_id_list.push(parseInt(client_index));
                  }
                }
              }
            }.bind(this), 500);
          }
        }
      } else {
        if (this.d_schemaClientIdMatch.client_schema_id_search_type === 'all') {
          //
        } else if (this.d_schemaClientIdMatch.client_schema_id_search_type === 'assigned' || this.d_schemaClientIdMatch.client_schema_id_search_type === 'not_assigned_yet') {
          if (this.d_schemaClientIdMatch.doc && this.d_schemaClientIdMatch.doc.data && this.d_schemaClientIdMatch.doc.data.general && this.d_schemaClientIdMatch.doc.data.general.client_id && this.d_schemaClientIdMatch.doc.data.general.client_id.list) {
            this.d_schemaClientIdMatch.timeout_client_id = setTimeout(function () {
              for (let client_index in this.d_schemaClientIdMatch.doc.data.general.client_id.list) {
                let client_schema_data = this.d_schemaClientIdMatch.doc.data.general.client_id.list[client_index];
                let will_continue = false;
                if (this.d_schemaClientIdMatch.client_schema_id_search_type === 'assigned') {
                  if (client_schema_data.wdmr_schema_id && client_schema_data.wdmr_schema_id.val) {
                    will_continue = true;
                  }
                } else if (this.d_schemaClientIdMatch.client_schema_id_search_type === 'not_assigned_yet') {
                  if (!client_schema_data.wdmr_schema_id) {
                    will_continue = true;
                  }
                }
                if (will_continue) {
                  this.d_schemaClientIdMatch.search_index_client_id_list.push(parseInt(client_index));
                }
              }
            }.bind(this), 500);
          }
        }
      }
    },
    f_openSentenceTemplateModal: function () {
      let query = 'layer_id=' + this.p_layerId;
      if (this.p_wdmrId) {
        query += '&wdmr_id=' + this.p_wdmrId
      }
      if (this.p_wdmrToken) {
        query += '&wdmr_token=' + this.p_wdmrToken
      }
      WmanagerService.wdm_sentence_template_list(query)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            this.d_sentenceTemplate.show = true;
            this.d_sentenceTemplate.list = [];
            this.d_sentenceTemplate.all_list = resp.data.list;
          } else {
            let msg = resp.data.status_message + ' (' + resp.data.status_code + ')';
            alert(msg);
          }
        });
    },

  },
  watch: {
    'd_sentenceWorkList.next_list_id': function () {
      this.f_nextSentenceWorkListService();
    },
    'd_sentenceTemplate.pagination.current': function () {
      this.d_sentenceTemplate.pagination.start = (this.d_sentenceTemplate.pagination.current - 1) * this.d_sentenceTemplate.pagination.perpage;
      this.d_sentenceTemplate.pagination.end = (this.d_sentenceTemplate.pagination.perpage * this.d_sentenceTemplate.pagination.current);
    },
    'd_schemaClientIdMatch.pagination.current': function () {
      this.d_schemaClientIdMatch.pagination.start = (this.d_schemaClientIdMatch.pagination.current - 1) * this.d_schemaClientIdMatch.pagination.perpage;
      this.d_schemaClientIdMatch.pagination.end = (this.d_schemaClientIdMatch.pagination.perpage * this.d_schemaClientIdMatch.pagination.current);
    },
    'd_schemaClientIdMatch.pagination_client_schema.current': function () {
      this.d_schemaClientIdMatch.pagination_client_schema.start = (this.d_schemaClientIdMatch.pagination_client_schema.current - 1) * this.d_schemaClientIdMatch.pagination_client_schema.perpage;
      this.d_schemaClientIdMatch.pagination_client_schema.end = (this.d_schemaClientIdMatch.pagination_client_schema.perpage * this.d_schemaClientIdMatch.pagination_client_schema.current);
    },
    'd_schemaClientIdMatch.client_schema_id_search_type': function () {
      this.f_schemaClientIdFilterFunction();
    },
    'd_schemaClientIdMatch.search_client_schema_id': function () {
      this.f_schemaClientIdFilterFunction();
    },
    'd_sentenceTemplate.filter_group': function () {
      this.f_sentenceTemplateFilterFunction();
    },
    'd_sentenceTemplate.search_text': function () {
      this.f_sentenceTemplateFilterFunction();
    },
    'd_wdmParameterOption.selected_index_data.index_list': function () {
      let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], this.d_wdmParameterOption.selected_index_data.index_list);
      // console.log(param_data);
      this.d_wdmParameterOption.param_data = param_data;
      this.$forceUpdate();
    },
    'd_addEditSentenceTemplate.selected_index_data.index_list': function () {
      // console.log(this.d_addEditSentenceTemplate.selected_index_data.index_list);
      let index_list = this.d_addEditSentenceTemplate.selected_index_data.index_list;
      this.d_addEditSentenceTemplate.template.list[this.d_addEditSentenceTemplate.selected].phrase = index_list.join('.');
      let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
      this.d_addEditSentenceTemplate.template.list[this.d_addEditSentenceTemplate.selected].param_data = {};
      this.d_addEditSentenceTemplate.template.list[this.d_addEditSentenceTemplate.selected].param_data.label = param_data.name.label;
      this.d_addEditSentenceTemplate.template.list[this.d_addEditSentenceTemplate.selected].param_data.type = param_data.type;
      this.$forceUpdate();
    },
    'd_understandSentenceTemplate.text': function () {
      // console.log(this.d_valTextTimer.text);
      clearTimeout(this.d_understandSentenceTemplate.timeout);
      this.d_understandSentenceTemplate.timeout = 0;
      this.d_understandSentenceTemplate.timeout = setTimeout(function () {
        this.d_understandST_result.search_text = this.d_understandSentenceTemplate.text;
        if (!this.d_understandSentenceTemplate.text) {
          this.d_understandST_result.list = [];
          this.d_understandST_result.recommended_show = false;
          this.d_understandST_result.recommended_text_list = [];
        }
        this.f_understandThisST();
        thi
      }.bind(this), 250);
    },
  }
};

</script>

<style type="text/css">
.understand-search-param-row {
  /*border-bottom: solid 1px lightgreen;*/
  margin: 2px;
  cursor: pointer;
}


/*.understand-search-param-row:hover {
  border-bottom: solid 1px black;
  margin: 2px;
  cursor: pointer;
  background-color: #f6d3ab;
}*/

#understand-search-param-popup {
  height: 300px;
  min-width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 10;
  background-color: rgb(108 105 105);
  /*border: solid 1px #11c0be;*/
  margin: 0px;
}

.intLink {
  width: 25px;
}

#understand-search-param-popup::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#understand-search-param-popup::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

#class-context-menu-popup {
  color: white;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 1000;
  background-color: #454c4c;
  box-shadow: 1px -1px 3px 0px black;
  /*border: solid 1px #11c0be;*/
  margin: 0px;
}

#class-context-menu-popup::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#class-context-menu-popup::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

#understand-search-param-popup-look-margin::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#understand-search-param-popup-look-margin::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

#toolBar1 select {
  border: solid 1px #20a2ce;
  font-size: 12px;
  margin: 3px;
}

[contenteditable] {
  outline: 0px solid transparent;
}

</style>

