var Layers = {
  'users': {
    'w_id_list': [],
    'layer_id': '33',
    'wdm_type': 'wdm22'
  },
  'user_layers': {
    'w_id_list': [],
    'layer_id': '34',
    'wdm_type': 'wdm246'
  }
};

export { Layers };
